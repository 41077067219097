<template>
  <div>
    <PrevTruckLoadSnippet v-if="!edit && (index === 0 || (index > 0 && index !== locations.length - 1 && location.truck !== locations[index-1].truck))"
                          :current-load-id="loadId" :truck-id="location.truck"/>
    <v-card :style="cardStyle"><!-- :style="getStatusLightStyle()">-->
      <!--{{location}}-->
      <v-card-title class="title" style="background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(1,90,170,1));">
        <template v-if="!showEditLocationField">
          <v-tooltip bottom v-if="edit">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on"
                     :disabled="locationsReadOnly"
                     @click="locationUp()"
                     v-if="edit && index > 0 && !location.returnToHQ">
                <v-icon color="white">mdi-arrow-up-bold</v-icon>
              </v-btn>
            </template>
          <span>{{$t('moveUp')}}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="edit">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on"
                     :disabled="locationsReadOnly"
                     @click="locationDown()"
                     v-if="edit && (locations[locations.length - 1].returnToHQ && index < locations.length - 2) || (!locations[locations.length - 1].returnToHQ && index < locations.length - 1)">
                <v-icon color="white">mdi-arrow-down-bold</v-icon>
              </v-btn>
            </template>
            <span>{{$t('moveDown')}}</span>
          </v-tooltip>

          <v-layout wrap>
            <v-flex :xs11="$root.isMobile" :shrink="!$root.isMobile">
              <span class="white--text font-weight-light">
                <template v-if="index === 0">
                  {{$t('Loads.Start')}} - {{location.name}}
                </template>
                <template v-else>
                  {{location.name}}<br v-if="$root.isMobile"/>&nbsp;{{$t('At')}}
                </template>
                <template v-if="!isLocationTimeEdit && index > 0">
                  <b class="ml-1">{{$root.getDateTimeStringFromTimestamp(edit ? location.targetDatetimes[0] : location.targetStartTimestamp)}}</b>
                </template>
              </span>
            </v-flex>
            <v-flex :xs1="$root.isMobile" :shrink="!$root.isMobile">
              <v-btn v-if="!isLocationTimeEdit && index > 0 && !edit"
                    icon class="pb-1"
                    @click="onLocationTimeEdit($root.getDateTimeStringFromTimestamp(location.targetStartTimestamp))">
                <v-icon class="white--text">mdi-map-clock</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs12 v-if="isLocationTimeEdit && index > 0">
              <v-text-field
                class="ml-1"
                :rules="[rules.datetimeUSA]"
                style="min-width: 250px; max-width: 250px;"
                dense outlined dark hide-details
                v-model="locationTime"
              >
                <template v-slot:append-outer>
                  <v-btn icon @click="saveLocationTime()" small>
                    <v-icon color="white">mdi-content-save</v-icon>
                  </v-btn>
                  <v-btn icon @click="isLocationTimeEdit=false;" small>
                    <v-icon color="white">mdi-cancel</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-flex>
          </v-layout>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="showEditLocationField=true; showPlaceSaveBtn=false;" v-if="edit">
                <v-icon color="white">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>{{$t('Edit')}}</span>
          </v-tooltip>
        </template>
        <template v-else>
          <v-text-field :id="'newLocation' + index"
                        color="white--text"
                        v-model="currentSelectedPlaceName"
                        hide-details="true"
                        :label="$t('Loads.LocationAddress')"
                        @click="initInsertedPlaceAutocomplete(index)"
                        dense outlined dark>
            <template v-slot:append-outer>
              <v-btn icon @click="saveNewLocations(index);" v-if="showPlaceSaveBtn" small>
                <v-icon color="white">mdi-content-save</v-icon>
              </v-btn>
              <v-btn icon @click="showEditLocationField=false;" small>
                <v-icon color="white">mdi-cancel</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </template>
        <v-spacer></v-spacer>
        <v-chip small v-if="edit && location.canceled === 1" color="error">{{$t('Loads.Canceled')}}</v-chip>
        <v-tooltip bottom v-if="edit && !locationDetailsHidden">
          <template v-slot:activator="{ on }">
            <v-btn icon
                   :disabled="locationDetailsReadOnly || readonly"
                   v-on="on"
                   @click="populateLocationSettingsDown()">
              <v-icon color="white">mdi-arrow-down-bold-hexagon-outline</v-icon>
            </v-btn>
          </template>
          <span>{{$t('Loads.PopulateLocationSettingsDown')}}</span>
        </v-tooltip>
        <!--<v-tooltip bottom v-if="edit && location.details === null && !locationDetailsHidden">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="setAppoinment('')">
              <v-icon>mdi-comment-plus</v-icon>
            </v-btn>
          </template>
          <span>{{$t('Loads.setAppoinment')}}</span>
        </v-tooltip>-->
        <!--<v-tooltip bottom v-if="edit && location.details !== null && !locationDetailsHidden">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="setAppoinment(null)">
              <v-icon>mdi-comment-remove</v-icon>
            </v-btn>
          </template>
          <span>{{$t('Loads.unsetAppoinment')}}</span>
        </v-tooltip>-->
        <!-- DELETE BTN -->
        <v-tooltip bottom v-if="edit && !locationsHidden">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on"
                   :disabled="locationsReadOnly || location.returnToHQ === 1"
                   v-if="edit"
                   @click="deleteLocation(index)">
              <v-icon color="white">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{$t('Remove')}}</span>
        </v-tooltip>

        <div v-if="!edit" class="text-right">
          <br v-if="$root.isMobile"/>
          <v-spacer/>
          <!---->
          <template v-if="!isEasyMode || index === 0">
            <template v-if="isShowNextStatusButtons">
              <!-- ON STARTED -->
              <v-chip class="mb-1 mr-1"
                      :disabled="location.canceled === 1"
                      :color="location.driverOnTheWayCheck > 0 ? 'success' : null"
                      :close="!$root.isMobile && location.driverOnTheWayCheck > 0 && location.onSiteByDriverTS === 0"
                      @click="location.driverOnTheWayCheck > 0 ? onStartCancelClick() : onStartClick()"
                      @click:close="onStartCancelClick()">
                <v-icon :left="!$root.isMobile">mdi-ray-start-arrow</v-icon>
                <template v-if="!$root.isMobile">
                  {{ location.driverOnTheWayCheck > 0 ? $t('Loads.LocationStarted') : $t('Loads.LocationStart') }}
                </template>
              </v-chip>

              <!-- ON SITE -->
              <v-chip :disabled="location.driverOnTheWayCheck === 0 || location.canceled === 1"
                      class="mb-1 mr-1"
                      :color="location.onSiteByDriverTS > 0 ? 'success' : null"
                      :close="!$root.isMobile && location.onSiteByDriverTS > 0 && !location.dispatcherOnTheWayCheck && (!location.driverDoneCheck || isLocationDontHaveDoneBtn(location))"
                      @click="location.onSiteByDriverTS > 0 ? onOnSiteCancelClick() : onOnSiteClick()"
                      @click:close="onOnSiteCancelClick()">
                <v-icon :left="!$root.isMobile">mdi-map-marker-check</v-icon>
                <template v-if="!$root.isMobile">
                  {{$t('Loads.onSiteStatus')}}
                </template>
              </v-chip>

              <template v-if="!isLocationDontHaveDoneBtn(location)">
                <!-- ON DONE -->
                <v-chip v-if="location.notDoneByDriverTS === 0 || location.doneByDriverTS > 0"
                        class="mb-1 mr-1"
                        :color="isOnDoneChecked(locations, index) ? 'success' : null"
                        :disabled="location.onSiteByDriverTS === 0 || location.canceled === 1"
                        :close="!$root.isMobile && location.doneByDriverTS > 0 && !location.dispatcherOnTheWayCheck"
                        @click="location.doneByDriverTS > 0 ? onOnDoneCancelClick() : onOnDoneClick()"
                        @click:close="onOnDoneCancelClick()">
                  <v-icon v-if="location.pickup" :left="!$root.isMobile">fa-people-carry</v-icon>
                  <v-icon v-else-if="location.delivery" :left="!$root.isMobile">fa-truck-loading</v-icon>
                  <v-icon v-else :left="!$root.isMobile">mdi-check</v-icon>
                  <template v-if="!$root.isMobile">
                    <span v-if="location.pickup">{{$t('Loads.loadedStatus')}}</span>
                    <span v-if="location.delivery">{{$t('Loads.unloadedStatus')}}</span>
                    <span v-if="location.parking">{{$t('Loads.parkedStatus')}}</span>
                    <span v-if="location.exchange">{{$t('Loads.exchangedStatus')}}</span>
                    <span v-if="location.returnToHQ">{{$t('Loads.returnToHQStatus')}}</span>
                  </template>
                </v-chip>
                <!-- ON NOT DONE -->
                <v-chip v-if="location.doneByDriverTS === 0 || location.notDoneByDriverTS > 0"
                        class="mb-1 mr-1"
                        :color="isOnNotDoneChecked(locations, index) ? 'error' : null"
                        :disabled="location.onSiteByDriverTS === 0 || location.canceled === 1"
                        :close="!$root.isMobile && location.notDoneByDriverTS > 0 && !location.dispatcherOnTheWayCheck"
                        @click="location.notDoneByDriverTS > 0 ? onNotDoneCancelClick() : onNotDoneClick()"
                        @click:close="onNotDoneCancelClick()">
                  <v-icon :left="!$root.isMobile"
                          :color="location.notDoneByDriverTS > 0 ? 'white' : 'error'">mdi-close</v-icon>
                  <template v-if="!$root.isMobile">
                    <span v-if="location.pickup">{{$t('Loads.notLoadedStatus')}}</span>
                    <span v-if="location.delivery">{{$t('Loads.notUnloadedStatus')}}</span>
                  </template>
                </v-chip>
              </template>
            </template>

            <v-chip v-if="isApproveLocationBtn && !isEasyMode"
                    :disable="isApproveLocationDisabled || location.canceled === 1"
                    class="mb-1"
                    :color="location.approvedByDispatcherTS > 0 ? 'success' : null"
                    :close="!$root.isMobile && isCanCloseApprovedButton (index, locations)"
                    @click="location.approvedByDispatcherTS > 0 ? onDispatcherApprovedCancel() : onDispatcherApproved()"
                    @click:close="onDispatcherApprovedCancel()"
            >
              <v-icon>mdi-face-agent</v-icon>
              <template v-if="!$root.isMobile">
                {{$t('Loads.approveLocation')}}
              </template>
            </v-chip>
          </template>
          <v-chip
            v-if="!isEasyMode"
            class="ml-1 mb-1"
            :disabled="readonly"
            :color="location.canceled === 1 ? 'error' : null"
            :close="location.canceled === 1"
            @click="location.canceled === 0 ? onUpdateLocationCancel(1) : null"
            @click:close="location.canceled === 1 ? onUpdateLocationCancel(0) : null">
            <v-icon>mdi-cancel</v-icon>
            <template v-if="!$root.isMobile">
              {{location.canceled === 1 ? $t('Loads.LocationCanceled') : $t('Loads.CancelLocation')}}
            </template>
          </v-chip>
        </div>
      </v-card-title>
      <v-card-text :class="$vuetify.theme.dark ? 'text--primary' : 'white text--primary'"
                   :style="$vuetify.theme.dark ? { backgroundColor: location.canceled === 1 ? '#999999 !important' : '#162231' } : { backgroundColor: location.canceled === 1 ? '#999999 !important' : '' }">
        <!-- EDIT -->
        <template v-if="edit">
          <v-layout class="pt-2" wrap>
            <!--<v-flex xs12>
              <v-textarea
                v-if="location.details !== null && !locationDetailsHidden"
                :disabled="locationDetailsReadOnly"
                class="mt-1 mr-1 ml-1"
                :label="$t('Loads.setAppoinment')"
                v-model="location.details"
                @change="setAppoinment($event)"
                @click:clear="setAppoinment(null)"
                outlined></v-textarea>
            </v-flex>-->
            <v-flex xs12 class="text-right">
              <template v-if="!location.returnToHQ && index > 0 && !locationDetailsHidden">
                <!-- PICKUP BTN-->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" large :disabled="locationDetailsReadOnly" class="mt-1" :small="$root.isMobile">
                      <v-icon :color="location['pickup'] ? $root.pickupColor : null" @click="setPickupLocation(index)">fa-people-carry</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('Loads.setAsPickupLocation')}}</span>
                </v-tooltip>
                <!-- DELIVERY BTN-->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" large :disabled="locationDetailsReadOnly" class="mt-1" :small="$root.isMobile">
                      <v-icon :color="location['delivery'] ? $root.deliveryColor : null" @click="setDeliveryLocation(index)">fa-truck-loading</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('Loads.setAsDeliveryLocation')}}</span>
                </v-tooltip>
                <!-- PARKING BTN-->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" large :disabled="locationDetailsReadOnly" class="mt-1" :small="$root.isMobile">
                      <v-icon :color="location['parking'] ? $root.parkingColor : null" @click="setParkingLocation(index)">mdi-parking</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('Loads.setAsParkingLocation')}}</span>
                </v-tooltip>
                <!-- EXCHANGE -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" large :disabled="locationDetailsReadOnly" class="mt-1" :small="$root.isMobile">
                      <v-icon :color="location['exchange'] ? $root.exchangeColor : null" @click="setExchangeLocation(index)">mdi-swap-horizontal-bold</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('Loads.setAsExchangeLocation')}}</span>
                </v-tooltip>
              </template>
            </v-flex>
            <v-flex  xs12 md4>
              <v-text-field class="ma-1 mt-2"
                            :loading="location.targetDatetimes[0] === 0"
                            v-if="location.targetDatetimes && index > 0 && !locationDetailsHidden"
                            :disabled="locationDetailsReadOnly"
                            dense
                            outlined
                            :rules="[rules.datetimeUSA]"
                            :value="location.targetDatetimes[0] > 0 ? $root.getDateTimeStringFromTimestamp(location.targetDatetimes[0]) : null"
                            @change="setLocationDateTime($event, index)"
                            :label="$t('Loads.EnterLocationDateTime')"
                            prepend-icon="mdi-calendar-clock"
                            hint="MM/DD/YYYY HH:MM PM/AM"
                            :key="'locationsDT'+$store.getters['loads/getLocationsKey']">
                <template v-slot:prepend>
                  <v-icon large>mdi-calendar-clock</v-icon>
                </template>
              </v-text-field>
            </v-flex>
            <template v-if="index > 0 && !isDriverOwnerOperator">
              <v-flex xs12 md4 class="pr-1 pl-1 pt-2">
                <OdometerInput
                  :label="$root.t('Loads.odometerStartData')"
                  :locationOdometerHidden="locationOdometerHidden"
                  :locationOdometerReadOnly="locationOdometerReadOnly || !edit"
                  :odometerUPLOADHidden="odometerUPLOADHidden"
                  :odometerUPLOADReadOnly="odometerUPLOADReadOnly || !edit"
                  folderName="odometerStart"
                  :disabled="readonly"
                  :field="location.odometerStart"
                  :fieldUPLOAD="location.odometerStartUPLOAD"
                  @odometerValueChanged="(val) => { onOdometerStartChanged(index, val) }"
                  @odometerUploaded="(val) => { onOdometerStartUploadChanged(index, val) }"
                  @odometerRemove="() => { onOdometerStartUploadChanged(index, '') }"
                />
              </v-flex>
              <v-flex xs12 md4 class="pr-1 pt-2">
                <OdometerInput
                  :label="$root.t('Loads.odometerEndData')"
                  :locationOdometerHidden="locationOdometerHidden"
                  :locationOdometerReadOnly="locationOdometerReadOnly || !edit"
                  :odometerUPLOADHidden="odometerUPLOADHidden"
                  :odometerUPLOADReadOnly="odometerUPLOADReadOnly || !edit"
                  folderName="odometer"
                  :disabled="readonly"
                  :field="location.odometer"
                  :fieldUPLOAD="location.odometerUPLOAD"
                  @odometerValueChanged="(val) => { onOdometerEndChanged(index, val); }"
                  @odometerUploaded="(val) => { onOdometerEndUploadChanged(index, val); }"
                  @odometerRemove="() => { onOdometerEndUploadChanged(index, ''); }"
                />
              </v-flex>
            </template>
          </v-layout>
            <template v-if="!isDriverOwnerOperator && !locationDetailsHidden && !location['returnToHQ'] && index < locations.length-1">
              <v-subheader class="mt-2">{{$t('Truck')}}<v-divider class="ml-1"/></v-subheader>
              <small v-if="locations[index + 1].startedByDriverTS > 0">{{$t('Loads.TruckCannotBeChangedSinceAlreadyStartedMsg')}}</small>
              <v-layout wrap>
                <v-flex xs12 lg6 xl4>
                  <SelectTruck
                    ref="trucksSelect"
                    v-if="!isFieldHidden('truck')"
                    :disabled="locationDetailsReadOnly || isFieldReadOnly('truck') || locations[index + 1].startedByDriverTS > 0"
                    :label="$t('Loads.SelectTruck')"
                    :key="'truck' + location.truck"
                    :value="location.truck"
                    :showInfoButton="false"
                    :hideIcon="$root.isMobile"
                    @click="truckClick"
                    @change="truckChanged($event)">
                  </SelectTruck>
                </v-flex>
                <v-flex v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm" class="md4 lg6 xl8"></v-flex>
              </v-layout>


              <template v-if="!isFieldHidden('driver')">
                <v-subheader class="mt-2 mb-2">{{$t('User.Driver')}}<v-divider class="ml-1"/></v-subheader>
                <small v-if="locations[index + 1].startedByDriverTS > 0">{{$t('Loads.DriverCannotBeChangedSinceAlreadyStartedMsg')}}</small>
                <v-layout class="mb-1" wrap>
                  <v-flex xs12 md6>
                    <SelectUser
                      :disabled="!$store.getters.isUserRoleSuperAdmin && (locationDetailsReadOnly || isFieldReadOnly('driver') || locations[index + 1].startedByDriverTS > 0)"
                      :label="$t('Loads.SelectDriver')"
                      role="Driver"
                      :key="'driver' + location.driver"
                      :includeDriverOwnerOperator="true"
                      :value="location.driver"
                      :showDeactivated="showDeactivatedDrivers"
                      :hide-prepend-icon="$root.isMobile"
                      @change="driverChanged($event)"
                      @on-driver="isDriverOO = false"
                      @on-driver-oo="isDriverOO = true"
                    />
                  </v-flex>
                  <v-flex xs12 md6 v-if="!isFieldHidden('rateType') && !isDriverOO" class="text-no-wrap">
                    <v-select
                      :key="'driverRateType'+$store.getters['loads/getLocationsKey']"
                      :disabled="!location.driver || locationDetailsReadOnly || isFieldReadOnly('driver') || isFieldReadOnly('rateType')"
                      v-model="location.driverRateName"
                      item-text="name"
                      item-value="name"
                      :items="driversRates[location.driver]"
                      outlined dense hide-details
                      class="ml-2 mt-1"
                      :label="$t('Loads.DriverRate')"
                      :hide-prepend-icon="$root.isMobile"
                      @change="driverRateTypeChanged($event)">
                      <template v-slot:append-outer>
                        <v-tooltip bottom v-if="edit && !locationDetailsHidden">
                          <template v-slot:activator="{ on }">
                            <v-btn icon small v-on="on" @click="populateLocationSettingsDown(['driverRateType', 'driverRate'])">
                              <v-icon>mdi-arrow-down-bold-hexagon-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>{{$t('Loads.PopulateLocationSettingsDown')}}</span>
                        </v-tooltip>
                      </template>
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout v-if="!isFieldHidden('driverRate') || (isDriverOO && isSetDriverOORateAllowed)" class="pl-10">
                  <v-flex xs12 md4 lg3>
                    <!-- TODO this is temp solution, need to make it properly, for some reason field access rights resets after truck is changed-->
                    <v-text-field
                      v-if="$store.getters.isUserRoleSuperAdmin || (isDriverOO && isSetDriverOORateAllowed)"
                      :key="'driverRate'+$store.getters['loads/getLocationsKey']"
                      :disabled="(isFieldReadOnly('driverRate') || locationDetailsReadOnly || isFieldReadOnly('driver')) && (!isDriverOO || !isSetDriverOORateAllowed)"
                      class="ml-2 mt-1"
                      v-model="location.driverRate"
                      :label="$t('Loads.driverRate')"
                      prefix="$"
                      type="number" step="0.1" @mousewheel.prevent=""
                      :hide-prepend-icon="$root.isMobile"
                      @change="driverRateChanged($event)"
                      outlined dense hide-details>
                      <template v-slot:append-outer>
                        <v-tooltip bottom v-if="edit && !locationDetailsHidden">
                          <template v-slot:activator="{ on }">
                            <v-btn icon small v-on="on" @click="populateLocationSettingsDown(['driverRate'])">
                              <v-icon>mdi-arrow-down-bold-hexagon-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>{{$t('Loads.PopulateLocationSettingsDown')}}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </template>
              <template v-if="!isFieldHidden('codriver') && !isDriverOO">
                <v-subheader class="mt-2 mb-2">{{$t('User.CoDriver')}}<v-divider class="ml-1"/></v-subheader>
                <small v-if="locations[index + 1].startedByDriverTS > 0">{{$t('Loads.DriverCannotBeChangedSinceAlreadyStartedMsg')}}</small>
                <v-layout wrap class="mt-2">
                  <v-flex xs12 md6>
                    <SelectUser
                      :disabled="!$store.getters.isUserRoleSuperAdmin && (locationDetailsReadOnly || isFieldReadOnly('codriver') || locations[index + 1].startedByDriverTS > 0)"
                      :label="$t('Loads.SelectSecondDriver')"
                      role="Driver"
                      :key="'coDriver' + location.coDriver"
                      :includeDriverOwnerOperator="false"
                      :value="location.coDriver"
                      :showDeactivated="showDeactivatedDrivers"
                      :hide-prepend-icon="$root.isMobile"
                      @change="coDriverChanged($event)"
                    />
                  </v-flex>
                  <v-flex xs12 md6 v-if="!isFieldHidden('rateType')">
                    <v-select
                      :key="'coDriverRateType'+$store.getters['loads/getLocationsKey']"
                      :disabled="!parseInt(location.coDriver) || locationDetailsReadOnly || isFieldReadOnly('codriver') || isFieldReadOnly('rateType')"
                      v-model="location.coDriverRateName"
                      item-text="name"
                      item-value="name"
                      :items="driversRates[location.coDriver]"
                      outlined dense hide-details
                      class="ml-2 mt-1"
                      :label="$t('Loads.CoDriverRate')"
                      :hide-prepend-icon="$root.isMobile"
                      @change="driverRateTypeChanged($event, true)">
                      <template v-slot:append-outer>
                        <v-tooltip bottom v-if="edit && !locationDetailsHidden">
                          <template v-slot:activator="{ on }">
                            <v-btn icon small v-on="on" @click="populateLocationSettingsDown(['coDriverRateType', 'coDriverRate'])">
                              <v-icon>mdi-arrow-down-bold-hexagon-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>{{$t('Loads.PopulateLocationSettingsDown')}}</span>
                        </v-tooltip>
                      </template>
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout v-if="!isFieldHidden('codriverRate')" class="pl-10">
                  <v-flex xs12 md4 lg3>
                    <!-- TODO this is temp solution, need to make it properly, for some reason field access rights resets after truck is changed-->
                    <v-text-field
                      v-if="$store.getters.isUserRoleSuperAdmin || (isDriverOO && isSetDriverOORateAllowed)"
                      :key="'coDriverRate'+$store.getters['loads/getLocationsKey']"
                      :disabled="isFieldReadOnly('codriverRate') || locationDetailsReadOnly || isFieldReadOnly('codriver')"
                      class="ml-2 mt-1"
                      v-model="location.coDriverRate"
                      :label="$t('Loads.codriverRate')"
                      prefix="$"
                      type="number" step="0.1" @mousewheel.prevent=""
                      :hide-prepend-icon="$root.isMobile"
                      @change="driverRateChanged($event, true)"
                      outlined dense hide-details>
                      <template v-slot:append-outer>
                        <v-tooltip bottom v-if="edit && !locationDetailsHidden">
                          <template v-slot:activator="{ on }">
                            <v-btn icon small v-on="on" @click="populateLocationSettingsDown(['coDriverRate'])">
                              <v-icon>mdi-arrow-down-bold-hexagon-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>{{$t('Loads.PopulateLocationSettingsDown')}}</span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </template>
            </template>
          </template>
          <!-- PAGE -->
          <template v-else>
            <v-flex xs12 class="pt-2">
              <v-card flat v-if="index > 0">
                <v-overlay v-if="location.canceled === 1" :absolute="true"></v-overlay>
                <v-layout wrap>
                  <v-flex xs12 md6>
                    <template  v-if="!isDriverOwnerOperator">
                      <template v-if="(isDriverOrTruckChanged() && !locationDetailsHidden) || index >= locations.length-1">
                        <v-subheader>{{$t('Loads.DroveIn')}}</v-subheader>
                        <v-row>
                          <v-col class="flex-grow-0">
                            <TruckSnippet v-if="locations[index-1].truck && trucksInfo[locations[index-1].truck]" :truckData="trucksInfo[locations[index-1].truck]"/>
                            <v-chip color="warning" class="mt-3" v-else-if="!isDriverOwnerOperator">{{$t('Loads.TruckIsNotSelected')}}</v-chip>
                          </v-col>
                          <v-col class="flex-grow-0">
                            <div class="pt-4" v-if="locations[index-1].driver && driversInfo[locations[index-1].driver]">
                              <UserSnippet :userData="driversInfo[locations[index-1].driver]" :avatarOnly="true"/>
                            </div>
                            <v-chip color="warning" class="mt-3" v-else-if="!isDriverOwnerOperator">{{$t('Loads.DriverIsNotSelected')}}</v-chip>
                          </v-col>
                          <v-col class="flex-grow-0">
                            <div class="pt-4" v-if="locations[index-1].codriver && driversInfo[locations[index-1].codriver]">
                              <UserSnippet :userData="driversInfo[locations[index-1].codriver]" :avatarOnly="true"/>
                            </div>
                          </v-col>
                        </v-row>
                      </template>

                      <ChildLocationsProgress v-if="isTruckChanged() && !edit && location.parking === 0" :childLocations="childLocations" :driversInfo="driversInfo" :parentLocation="location"/>

                      <template v-if="!locationDetailsHidden && index < locations.length-1">
                        <v-subheader v-if="isDriverOrTruckChanged()">{{$t('Loads.DroveOut')}}</v-subheader>
                        <v-row>
                          <v-col class="flex-grow-0">
                            <TruckSnippet v-if="location.truck && trucksInfo[location.truck]" :truckData="trucksInfo[location.truck]"/>
                            <v-chip color="warning" class="mt-3" v-else-if="!isDriverOwnerOperator">{{$t('Loads.TruckIsNotSelected')}}</v-chip>
                          </v-col>
                          <v-col class="flex-grow-0">
                            <div class="pt-4" v-if="location.driver && driversInfo[location.driver]">
                              <UserSnippet :userData="driversInfo[location.driver]" :avatarOnly="true"/>
                            </div>
                            <v-chip color="warning" class="mt-3" v-else-if="!isDriverOwnerOperator">{{$t('Loads.DriverIsNotSelected')}}</v-chip>
                          </v-col>
                          <v-col class="flex-grow-0">
                            <div class="pt-4" v-if="location.codriver && driversInfo[location.codriver]" >
                              <UserSnippet :userData="driversInfo[location.codriver]" :avatarOnly="true"/>
                            </div>
                          </v-col>
                          <template v-if="!$root.isMobile">
                            <v-col xs12 md6
                                   class="mt-3 pr-1"
                                   style="max-width:200px;"
                                   v-if="!isDriverOwnerOperator && location.startedByDriverTS > 0">
                                <OdometerInput
                                  :label="$root.t('Loads.odometerStartData')"
                                  :locationOdometerHidden="locationOdometerHidden"
                                  :locationOdometerReadOnly="locationOdometerReadOnly || !edit"
                                  :odometerUPLOADHidden="odometerUPLOADHidden"
                                  :odometerUPLOADReadOnly="odometerUPLOADReadOnly || !edit"
                                  folderName="odometerStart"
                                  :disabled="readonly"
                                  :hideUploadBtn="true"
                                  :field="location.odometerStart"
                                  :fieldUPLOAD="location.odometerStartUPLOAD"
                                  @odometerValueChanged="(val) => { onOdometerStartChanged(index, val) }"
                                  @odometerUploaded="(val) => { onOdometerStartUploadChanged(index, val) }"
                                  @odometerRemove="() => { onOdometerStartUploadChanged(index, '') }"
                                />
                            </v-col>
                            <v-col xs12 md6
                                   class="mt-3 pr-1"
                                   style="max-width:200px;"
                                   v-if="!isDriverOwnerOperator && location.onSiteByDriverTS > 0">
                                <OdometerInput
                                  :label="$root.t('Loads.odometerEndData')"
                                  :locationOdometerHidden="locationOdometerHidden"
                                  :locationOdometerReadOnly="locationOdometerReadOnly || !edit"
                                  :odometerUPLOADHidden="odometerUPLOADHidden"
                                  :odometerUPLOADReadOnly="odometerUPLOADReadOnly || !edit"
                                  folderName="odometer"
                                  :disabled="readonly"
                                  :hideUploadBtn="true"
                                  :field="location.odometer"
                                  :fieldUPLOAD="location.odometerUPLOAD"
                                  @odometerValueChanged="(val) => { onOdometerEndChanged(index, val); }"
                                  @odometerUploaded="(val) => { onOdometerEndUploadChanged(index, val); }"
                                  @odometerRemove="() => { onOdometerEndUploadChanged(index, ''); }"
                                />
                            </v-col>
                          </template>
                        </v-row>
                      </template>
                    </template>
                  </v-flex>
                  <v-flex xs12 md6 v-if="!isEasyMode">
                    <template v-if="!$root.isMobile">
                      <v-layout wrap v-if="location.pickup || location.delivery" justify-end>
                        <v-flex xs12 md6 class="mt-3 pr-1"  style="max-width:250px;" v-if="!isDriverOwnerOperator && location.startedByDriverTS > 0">
                          <v-text-field
                            :label="$t('Loads.CheckIn')"
                            hide-details
                            @change="changeLocationCheckInCheckOut('checkIn', location, $event)"
                            :value="location.checkIn"
                            append-icon="mdi-check"
                            @click:append="setCheckIn(location)"
                            dense
                            outlined/>
                        </v-flex>
                        <v-flex xs12 md6 class="mt-3 pr-1"  style="max-width:250px;" v-if="!isDriverOwnerOperator && location.onSiteByDriverTS > 0">
                          <v-text-field
                            :label="$t('Loads.CheckOut')"
                            hide-details
                            @change="changeLocationCheckInCheckOut('checkOut', location, $event)"
                            :value="location.checkOut"
                            append-icon="mdi-check"
                            @click:append="setCheckOut(location)"
                            dense
                            outlined/>
                        </v-flex>
                      </v-layout>
                    </template>

                    <template v-if="1 === 0">
                      <template v-if="(!edit && index > 0 && location.driverOnTheWayCheck && !locationStatusHidden) || (index > 0 && forceShowButtons)">
                        <v-btn small
                                @click="onOnSiteClick()"
                                :disabled="location.onSiteByDriverTS > 0 ||
                                          locationStatusReadOnly ||
                                          readonly ||
                                          location.canceled === 1 ||
                                          ( index < locations.length-1 &&
                                            parseInt(locations[index-1].truck) === 0 &&
                                            parseInt(locations[index-1].driver) === 0 &&
                                            !isDriverOwnerOperator)">
                          <v-icon left v-if="location.onSiteByDriverTS > 0" color="success">mdi-check</v-icon>
                          {{$t('Loads.onSiteStatus')}}
                          <span v-if="location.onSiteByDriverTS > 0 && !$vuetify.breakpoint.xs">&nbsp;at&nbsp;{{$root.getDateTimeStringFromTimestamp(location.onSiteByDriverTS)}}</span>
                        </v-btn>
                        <!-- Cancel -->
                        <v-btn icon
                                v-if="location.onSiteByDriverTS > 0 && !location.dispatcherOnTheWayCheck && (!location.driverDoneCheck || isLocationDontHaveDoneBtn(location))"
                                :disabled="locationStatusReadOnly || readonly || location.canceled === 1"
                                @click="onOnSiteCancelClick()">
                          <v-icon color="error">mdi-cancel</v-icon>
                        </v-btn>
                        <br/>
                        <span class="caption" v-if="location.onSiteByDriverTS > 0 && location.onSiteByDriver && usersInfo[location.onSiteByDriver]">
                          {{$t('by')}}&nbsp;<a href="#" @click.stop="$root.route('/user/page/' + usersInfo[location.onSiteByDriver].id)">{{usersInfo[location.onSiteByDriver].name}}</a>
                        </span>
                        <br/>
                        <template v-if="!isLocationDontHaveDoneBtn(location)">
                          <v-btn small
                                  v-if="location.onSiteByDriverTS > 0 && location.notDoneByDriverTS === 0"
                                  :disabled="location.doneByDriverTS > 0 || locationStatusReadOnly || location.canceled === 1 || readonly || (index < locations.length-1 && parseInt(locations[index - 1].truck) === 0 && parseInt(locations[index - 1].driver) === 0 && !isDriverOwnerOperator)"
                                  @click="onOnDoneClick()" class="mt-1 mr-1">
                            <v-icon left v-if="location.doneByDriverTS" color="success">mdi-check</v-icon>
                            <span v-if="location.pickup">{{$t('Loads.loadedStatus')}}</span>
                            <span v-if="location.delivery">{{$t('Loads.unloadedStatus')}}</span>
                            <span v-if="location.parking">{{$t('Loads.parkedStatus')}}</span>
                            <span v-if="location.exchange">{{$t('Loads.exchangedStatus')}}</span>
                            <span v-if="location.returnToHQ">{{$t('Loads.returnToHQStatus')}}</span>
                            <span v-if="location.doneByDriverTS > 0 && !$vuetify.breakpoint.xs">&nbsp;at&nbsp;{{$root.getDateTimeStringFromTimestamp(location.doneByDriverTS)}}</span>
                          </v-btn>
                          <v-btn small class="mt-1"
                                  v-if="location.onSiteByDriverTS > 0 && location.doneByDriverTS === 0"
                                  :disabled="location.notDoneByDriverTS > 0 || locationStatusReadOnly || location.canceled === 1 || readonly || (index < locations.length-1 && parseInt(locations[index - 1].truck) === 0 && parseInt(locations[index - 1].driver) === 0 && !isDriverOwnerOperator)"
                                  @click="onNotDoneClick()">
                            <v-icon left v-if="location.notDoneByDriverTS" color="success">mdi-cancel</v-icon>
                            <span v-if="location.pickup">{{$t('Loads.notLoadedStatus')}}</span>
                            <span v-if="location.delivery">{{$t('Loads.notUnloadedStatus')}}</span>
                            <span v-if="location.notDoneByDriverTS > 0 && !$vuetify.breakpoint.xs">&nbsp;at&nbsp;{{$root.getDateTimeStringFromTimestamp(location.notDoneByDriverTS)}}</span>
                          </v-btn>
                          <!-- Cancel -->
                          <v-btn icon v-if="location.doneByDriverTS > 0 && !location.dispatcherOnTheWayCheck"
                                  :disabled="locationStatusReadOnly || readonly || location.canceled === 1"
                                  @click="onOnDoneCancelClick()">
                            <v-icon color="error">mdi-cancel</v-icon>
                          </v-btn>
                          <!-- Cancel -->
                          <v-btn icon v-if="location.notDoneByDriverTS > 0 && !location.dispatcherOnTheWayCheck"
                                  :disabled="locationStatusReadOnly || readonly || location.canceled === 1"
                                  @click="onNotDoneCancelClick()">
                            <v-icon color="error">mdi-cancel</v-icon>
                          </v-btn>
                          <br/>
                          <span class="caption" v-if="location.doneByDriverTS > 0 && location.doneByDriver && usersInfo[location.doneByDriver]">
                            {{$t('by')}}&nbsp;<a href="#" @click.stop="$root.route('/user/page/' + usersInfo[location.doneByDriver].id)">{{usersInfo[location.doneByDriver].name}}</a>
                          </span>

                          <span class="caption" v-if="location.notDoneByDriverTS > 0 && location.notDoneByDriver && usersInfo[location.notDoneByDriver]">
                            {{$t('by')}}&nbsp;<a href="#" @click.stop="$root.route('/user/page/' + usersInfo[location.notDoneByDriver].id)">{{usersInfo[location.notDoneByDriver].name}}</a>
                          </span>
                        </template>
                        <v-flex xs12 class="text-right">
                            <ActualDurationAndDistanceSnippet
                              v-if="index > 0 && locations[index-1].truck > 0 && locations[index].driverOnTheWayCheck > 0 && locations[index].driverOnSiteCheck > 0"
                              :prevLocation="locations[index-1]"
                              :location="locations[index]"/>
                        </v-flex>
                        <!-- DOCUMENTS -->
                        <!--<v-btn small width="100%"
                              v-if="location.driverDoneCheck > 0 && (location.pickup || location.delivery)"
                              @click="$emit('openDocuments')" class="mt-1">
                          <v-icon left v-if="location.driverDoneCheck">mdi-file-upload</v-icon>
                          <span>{{$t('Loads.uploadDocuments')}}</span>
                        </v-btn>-->
                    </template>
                  </template>
                   <br/>
                   <TimeOnLocationSnippet :location="location" :hoursUntilDetention="detentionHours"></TimeOnLocationSnippet>
                  </v-flex>
                </v-layout>
                <template v-if="$root.isMobile && showLocationDetails[location.id]">
                  <v-layout wrap class="mt-5" v-if="location.pickup || location.delivery">
                    <v-flex xs12 class="mt-3 pr-1 text-center" v-if="!isDriverOwnerOperator && location.startedByDriverTS > 0">
                      <v-text-field
                        :label="$t('Loads.CheckIn')"
                        hide-details
                        @change="changeLocationCheckInCheckOut('checkIn', location, $event)"
                        :value="location.checkIn"
                        append-icon="mdi-check"
                        @click:append="setCheckIn(location)"
                        dense
                        outlined/>
                    </v-flex>
                    <v-flex xs12 class="mt-3 pr-1 text-center" v-if="!isDriverOwnerOperator && location.onSiteByDriverTS > 0">
                      <v-text-field
                        :label="$t('Loads.CheckOut')"
                        hide-details
                        @change="changeLocationCheckInCheckOut('checkOut', location, $event)"
                        :value="location.checkOut"
                        append-icon="mdi-check"
                        @click:append="setCheckOut(location)"
                        dense
                        outlined/>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                      <v-flex xs12 class="mt-3 pr-1 text-center" v-if="!isDriverOwnerOperator && location.startedByDriverTS > 0">
                        <!--<v-flex class="pt-1" style="max-width:300px;" v-if="!isDriverOwnerOperator && (locations[index+1].driverOnTheWayCheck || edit)">-->
                          <OdometerInput
                            :label="$root.t('Loads.odometerStartData')"
                            :locationOdometerHidden="locationOdometerHidden"
                            :locationOdometerReadOnly="locationOdometerReadOnly || !edit"
                            :odometerUPLOADHidden="odometerUPLOADHidden"
                            :odometerUPLOADReadOnly="odometerUPLOADReadOnly || !edit"
                            folderName="odometerStart"
                            :disabled="readonly"
                            :hideUploadBtn="true"
                            :field="location.odometerStart"
                            :fieldUPLOAD="location.odometerStartUPLOAD"
                            @odometerValueChanged="(val) => { onOdometerStartChanged(index, val) }"
                            @odometerUploaded="(val) => { onOdometerStartUploadChanged(index, val) }"
                            @odometerRemove="() => { onOdometerStartUploadChanged(index, '') }"
                          />
                      </v-flex>
                      <v-flex xs12 class="mt-3 pr-1 text-center" v-if="!isDriverOwnerOperator && location.onSiteByDriverTS > 0">
                          <OdometerInput
                            :label="$root.t('Loads.odometerEndData')"
                            :locationOdometerHidden="locationOdometerHidden"
                            :locationOdometerReadOnly="locationOdometerReadOnly || !edit"
                            :odometerUPLOADHidden="odometerUPLOADHidden"
                            :odometerUPLOADReadOnly="odometerUPLOADReadOnly || !edit"
                            folderName="odometer"
                            :disabled="readonly"
                            :hideUploadBtn="true"
                            :field="location.odometer"
                            :fieldUPLOAD="location.odometerUPLOAD"
                            @odometerValueChanged="(val) => { onOdometerEndChanged(index, val); }"
                            @odometerUploaded="(val) => { onOdometerEndUploadChanged(index, val); }"
                            @odometerRemove="() => { onOdometerEndUploadChanged(index, ''); }"
                          />
                    </v-flex>
                  </v-layout>
                </template>
              </v-card>
            </v-flex>
          </template>
        <div v-if="!edit && index === 0">
          <b>{{$t('Loads.shipper')}}</b>:&nbsp;{{shipper}}
          <v-btn v-if="shipper" small icon @click.stop="$root.copyToClipboard(shipper)"><v-icon small>mdi-content-copy</v-icon></v-btn>&nbsp;
          <br/>
          <b>{{$t('Loads.shipTo')}}</b>:&nbsp;{{consignee}}
          <v-btn v-if="consignee" small icon @click.stop="$root.copyToClipboard(consignee)"><v-icon small>mdi-content-copy</v-icon></v-btn>&nbsp;
          <br/>
        </div>
        <template v-if="Object.keys(appointmentItems).length > 0">
          <h4>{{$t('Loads.setAppoinment')}}:</h4>
          <v-list dense outlined class="mt-2">
            <template v-for="item in appointmentItems">
              <v-list-item :key="'appoinment' + item.id">
                <v-list-item-avatar>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-avatar v-on="on"
                                @click.stop="$root.onShowUserPreview(item.userId, item.userName)"
                                style="cursor: pointer;"
                                class="ml-1"
                                :size="24"
                                :color="(item.userInitials && !item.userAvatar)?'#015aaa':null">
                        <v-img :src="$root.getImageUrl('avatar', item.userAvatar)" v-if="item.userAvatar"></v-img>
                        <span style="color:#fff;" v-else-if="item.userInitials">{{item.userInitials}}</span>
                        <v-icon large v-else>account_circle</v-icon>
                      </v-avatar>
                    </template>
                    <span>{{item.userName}}</span>
                  </v-tooltip>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title :class="item.deleted === 0 ? 'text-left' : 'text-left text-decoration-line-through'"
                               v-html="$root.newLineToBR(item.details.replace(/<p><br><\/p>/img, '').replace(/(<p[^>]+?>|<p>)/img, '').replace(/<\/p>/img, '<br>').trim())" />
                  <v-list-item-subtitle>
                    {{item.createdPST}}
                    <v-btn small icon @click.stop="$root.copyToClipboard(item.details.replace(/<\/p>/img, '</p>\n').replace(/(<([^>]+)>)/ig, ''))"><v-icon small>mdi-content-copy</v-icon></v-btn>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" @click="onHideAppointmentDetails(item.id, item)">
                        <v-icon v-if="item.deleted === 0">mdi-eye-off</v-icon>
                        <v-icon v-else>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>{{item.deleted === 0 ? $root.t('hide') : $root.t('unhide')}}</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
              <v-divider  :key="'appoinment_divider' + item.id"/>
            </template>
          </v-list>
        </template>

        <template v-if="!loadId || !edit">
          <v-card v-if="detailsEditing || (edit && index === 0)" outlined shaped :loading="detailsSaving" class="mt-1">
            <v-card-text v-if="detailsEditing || edit">
              <div style="background-color:#fff;color: #000;" class="mb-2">
                <VueEditor v-model="location.details" @text-change="()=>{}" :editorToolbar="$root.customToolbar"></VueEditor>
              </div>
            </v-card-text>
            <v-card-text v-else-if="location.details && location.details !== ''"
                         class="text-left"
                         v-html="$root.newLineToBR(location.details.replace(/<p><br><\/p>/img, '').replace(/(<p[^>]+?>|<p>)/img, '').replace(/<\/p>/img, '<br>').trim())" />
            <v-card-actions v-if="!edit">
              <v-spacer></v-spacer>
              <v-btn small @click="onLocationDetailsSave(index)" :disabled="readonly" :loading="detailsSaving" v-if="detailsEditing" color="primary"><v-icon left>mdi-content-save</v-icon>{{$t('Save')}}</v-btn>
              <!--<v-btn small text @click="onEditLocationDetails(index)" :disabled="readonly" v-if="!detailsEditing"><v-icon left>mdi-pen</v-icon>{{$t('Edit')}}</v-btn>-->
              <v-btn small text @click="()=>{detailsEditing = false;}"><v-icon left>mdi-close</v-icon>{{$t('Close')}}</v-btn>
            </v-card-actions>
          </v-card>
          <v-layout v-else wrap>
            <v-flex xs6 class="text-left" @click="toggleLocationDetails(location.id)" v-if="index > 0">
              <v-btn small class="mt-1" v-if="$root.isMobile" :disabled="readonly || location.canceled === 1">
                <v-icon :left="!$root.isMobile">mdi-details</v-icon>
                <template v-if="!$root.isMobile">Odometer/Check InOut</template>
              </v-btn>
              <template v-else-if="index < locations.length - 1">
                <span class="ml-3">{{$t('Loads.ToLocationTarget')}}</span>:&nbsp;
                <v-icon class="ml-3" small>fa-route</v-icon> {{$root.metersToMiles(locations[index].distance).toLocaleString() }}&nbsp;{{$t('milesShort')}}&nbsp;
                <v-icon class="ml-3">mdi-clock-fast</v-icon> {{ $root.showDuration(locations[index].duration) }}
                <template v-if="locations[index + 1].startedByDriverTS > 0 && !locations[index + 1].onSiteByDriverTS">
                  <br/>
                  <span class="ml-3">{{$t('Loads.ToLocationLeft')}}</span>:&nbsp;
                  <v-icon class="ml-2" small>fa-route</v-icon> {{$root.metersToMiles(locations[index + 1].currentDistance).toLocaleString()}}&nbsp;{{$t('milesShort')}}
                  <v-icon class="ml-2">mdi-clock-fast</v-icon> {{$root.showDuration(locations[index + 1].currentDuration)}}
                  &nbsp;
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" small icon class="mr-2" @click="$emit('refreshDistanceAndDuration', index + 1)">
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t('Refresh')}}</span>
                  </v-tooltip>
                </template>
              </template>
            </v-flex>
            <v-flex :xs6="index > 0" :xs12="index === 0" class="text-right">
              <v-btn :small="$root.isMobile" :x-small="!$root.isMobile" @click="()=>{detailsEditing = true}" :disabled="readonly || location.canceled === 1" class="mt-1">
                <v-icon :left="!$root.isMobile">mdi-comment-plus</v-icon>
                <template v-if="!$root.isMobile">
                  {{$t('Loads.setAppoinment')}}
                </template>
              </v-btn>
            </v-flex>
            <v-flex xs12 class="pt-2" v-if="index < locations.length - 1 && $root.isMobile">
              <template>
                <span class="ml-3">{{$t('Loads.ToLocationTarget')}}</span>:&nbsp;
                <v-icon class="ml-3" small>fa-route</v-icon> {{$root.metersToMiles(locations[index].distance).toLocaleString() }}&nbsp;{{$t('milesShort')}}&nbsp;
                <v-icon class="ml-3">mdi-clock-fast</v-icon> {{ $root.showDuration(locations[index].duration) }}
              </template>
              <template v-if="locations[index + 1].startedByDriverTS > 0 && !locations[index + 1].onSiteByDriverTS">
                <br/>
                <span class="ml-3">{{$t('Loads.ToLocationLeft')}}</span>:&nbsp;
                <v-icon class="ml-2" small>fa-route</v-icon> {{$root.metersToMiles(locations[index + 1].currentDistance).toLocaleString()}}&nbsp;{{$t('milesShort')}}
                <v-icon class="ml-2">mdi-clock-fast</v-icon> {{$root.showDuration(locations[index + 1].currentDuration)}}
                &nbsp;
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" small icon class="mr-2" @click="$emit('refreshDistanceAndDuration', index + 1)">
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('Refresh')}}</span>
                </v-tooltip>
              </template>
            </v-flex>
          </v-layout>
        </template>
      </v-card-text>
    </v-card>
    <NextTruckLoadSnippet
      v-if="!edit && index > 0 && (index === locations.length - 1 || location.truck !== locations[index-1].truck)"
      :current-load-id="loadId" :truck-id="locations[index-1].truck"/>
  </div>
</template>

<script>
import fieldAccessRightMixin from '../mixins/fieldAccessRightsMixin.js';
import loadsMixin from '../mixins/loadsMixin';
import SelectTruck from '../components/SelectTruck.vue';
import SelectUser from '../components/SelectUser.vue';
import TruckSnippet from '../components/TruckSnippet.vue';
import UserSnippet from '../components/UserSnippet.vue';
import OdometerInput from '../components/OdometerInput.vue';
import TimeOnLocationSnippet from '../components/TimeOnLocationSnippet.vue';
import ChildLocationsProgress from '../components/ChildLocationsProgress.vue';
import ActualDurationAndDistanceSnippet from '../components/ActualDurationAndDistanceSnippet.vue';
import NextTruckLoadSnippet from './NextTruckLoadSnippet';
import PrevTruckLoadSnippet from './PrevTruckLoadSnippet';
import { VueEditor } from 'vue2-editor';

export default {
  name: 'LoadProgressCard',
  props: [
    'loadId', 'index', 'edit', 'finished', 'hoursUntilDetention',
    'locationDetailsHidden', 'locationDetailsReadOnly',
    'locationsHidden', 'locationsReadOnly',
    'locationStatusHidden', 'locationStatusReadOnly',
    'locationOdometerHidden', 'locationOdometerReadOnly',
    'odometerUPLOADHidden', 'odometerUPLOADReadOnly',
    'isDriverOwnerOperator', 'forceShowButtons',
    'shipper', 'consignee', 'driversRates', 'driversInfo', 'usersInfo', 'trucksInfo', 'readonly', 'appointmentData', 'isReturnToHQ', 'childLocations', 'showDeactivatedDrivers', 'isSetDriverOORateAllowed', 'isEasyMode'
  ],
  components: { NextTruckLoadSnippet, PrevTruckLoadSnippet, SelectTruck, SelectUser, TruckSnippet, UserSnippet, OdometerInput, VueEditor, TimeOnLocationSnippet, ChildLocationsProgress, ActualDurationAndDistanceSnippet },
  mixins: [fieldAccessRightMixin, loadsMixin],
  data () {
    return {
      fields: {
        truck: 0,
        driver: 0,
        codriver: 0,
        rateType: 0,
        driverRate: 0,
        codriverRate: 0
      },
      odometerUPLOADLoading: false,
      detentionHours: 2.0,
      showPlaceSaveBtn: false,
      showEditLocationField: false,
      currentSelectedPlace: null,
      currentSelectedPlaceName: '',
      detailsEditing: false,
      detailsSaving: false,
      isDriverOO: false,
      isLocationTimeEdit: false,
      locationTime: '',
      showLocationDetails: {},
      rules: {
        datetimeUSA: value => new Date(value).getTime() ? true : false || this.$root.t('Loads.DateFormatError')
      }
    };
  },
  computed: {
    locations () {
      return this.$store.getters['loads/getLocations'];
    },
    location () {
      return this.$store.getters['loads/getLocation'](this.index);
    },
    appointmentItems () {
      if (this.location && this.location.id && this.appointmentData && this.appointmentData.hasOwnProperty(this.location.id)) {
        return this.appointmentData[this.location.id];
      }
      return [];
    },
    isLastLocation () {
      return parseInt(this.index) === this.locations.length - 1;
    },
    isApproveLocationBtn () {
      const index = this.index;
      const isLocationTypeAndStatusMatch = this.locations[index].doneByDriverTS > 0 || this.locations[index].notDoneByDriverTS > 0 || this.locations[index].exchange === 1 || this.locations[index].parking === 1 || this.locations[index].returnToHQ === 1;
      const isApprovedOrCanceled = index > 0 && (this.locations[index - 1].approvedByDispatcherTS > 0 || this.locations[index - 1].canceled > 0);
      const isStartedAndOnSite = this.locations[index].startedByDriverTS > 0 && this.locations[index].onSiteByDriverTS > 0;

      return ( this.forceShowButtons ||
               index === 0 ||
               ( index > 0 &&
                 isApprovedOrCanceled &&
                 ( isStartedAndOnSite || this.isDriverOwnerOperator) &&
                 ( isLocationTypeAndStatusMatch || this.isDriverOwnerOperator)
               )
             ) && this.locations[index].canceled === 0 && !this.locationStatusHidden;
    },
    isApproveLocationDisabled () {
      const index = this.index;
      const isDoneWithRightType = this.locations[index].doneByDriverTS === 0 && this.locations[index].notDoneByDriverTS === 0 && this.locations[index].exchange === 0 && this.locations[index].parking === 0 && this.locations[index].returnToHQ === 0;
      const isNotSet = index < this.locations.length - 1 && (parseInt(this.locations[index].truck) === 0 || parseInt(location.driver) === 0) && !this.isDriverOwnerOperator && this.locations[index].exchange === 0 && this.locations[index].parking === 0 && this.locations[index].returnToHQ === 0;

      return this.index > 0 && (this.locations[index].startedByDriverTS === 0 || this.locations[index].onSiteByDriverTS === 0 || isDoneWithRightType || this.readonly || isNotSet);
    },
    isShowNextStatusButtons() {
      if (this.index === 0 ||
          this.edit ||
          this.locationStatusHidden) return false;

      if (this.locations[this.index].canceled === 1) return false;

      if (this.locations[this.index - 1].dispatcherOnTheWayCheck) {
        return true;
      }

      if (this.locations[this.index - 1].canceled === 1) {
        for (let i = this.index - 1; i >= 0; i--) {
          if (!this.locations[i].dispatcherOnTheWayCheck && this.locations[i].canceled === 0) {
            return false;
          }
        }
        return true;
      }

      return false;
    },
    cardStyle () {
      return {
        backgroundColor: this.$root.getLocationColor(this.location, this.index) + ' !important'
      }
    }
  },
  mounted () {
    if (this.hoursUntilDetention) {
      this.detentionHours = this.hoursUntilDetention;
    } else {
      this.detentionHours = 2;
    }

    if (this.location.driver > 0) {
      this.$root.getUserRole(this.location.driver).then((resoponse) => {
        if (resoponse.status === 'ok') {
          this.isDriverOO = resoponse.result === this.$store.getters['roleDriverOwnerOperator'];
        }
      });
    }

    this.updatingEnabled = true;
    this.applyRights('', 'loads', {
      'truck': 0,
      'driver': 0,
      'codriver': 0,
      'rateType': 0,
      'driverRate': 0,
      'codriverRate': 0
    });
  },
  methods: {
    toggleLocationDetails(id) {
      if (this.showLocationDetails[id]) {
        this.$set(this.showLocationDetails, id, false);
      } else {
        this.$set(this.showLocationDetails, id, true);
      }
    },
    changeLocationCheckInCheckOut (field, location, value) {
      if (value !== '' && !new Date(value).getTime()) {
        this.$root.toast(this.$i18n.t('Loads.DateFormatError'), { color: 'error' });
      } else {
        let fields = {};
        fields[field] = value;
        this.setLoadLocationFieldsById(location.loadId, location.id, fields).then((response) => {
          if (response.status === 'ok') {
            this.$store.dispatch('loads/setLocationField', {
              index: this.index,
              field: field,
              value: value
            });
            this.$store.commit('loads/updateLoadUpdated', response.updated);
            this.$root.toast(this.$i18n.t('ChangesAreSaved'), { color: 'success' });
          }
        });
      }
    },
    onLocationTimeEdit (value) {
      this.isLocationTimeEdit = true;
      this.locationTime = value;
    },
    saveLocationTime () {
      let i = 0;
      let lastLocationTS = 0;
      if (!new Date(this.locationTime).getTime()) {
        this.$root.toast(this.$i18n.t('Loads.DateFormatError'), { color: 'error' });
        return;
      }

      let targetStartTimestamp = this.$root.getTimestampFromDateTimeString(this.locationTime);
      let targetEndTimestamp = targetStartTimestamp + this.location.duration;

      for (i = 1; i < this.locations.length; i++) {
        const ts = i === parseInt(this.index) ? parseInt(targetStartTimestamp) : parseInt(this.locations[i].targetStartTimestamp);
        if (ts > lastLocationTS) {
          lastLocationTS = ts;
        } else {
          this.$root.toast(this.$root.t('Loads.LocationsDatesAreNotConsecutive'), { color: 'error' });
          return false;
        }
      }

      this.setLoadLocationFieldsById(this.location.loadId, this.location.id, {
        'targetStartTimestamp': targetStartTimestamp,
        'targetEndTimestamp': targetEndTimestamp
      }).then((response) => {
        if (response.status === 'ok') {
          this.$store.dispatch('loads/setLocationField', { index: this.index, field: 'targetStartTimestamp', value: targetStartTimestamp });
          this.$store.dispatch('loads/setLocationField', { index: this.index, field: 'targetEndTimestamp', value: targetEndTimestamp });
          this.$store.commit('loads/updateLoadUpdated', response.updated);
          this.isLocationTimeEdit = false;
          this.$root.toast(this.$i18n.t('ChangesAreSaved'), { color: 'success' });
        }
      });
    },
    onHideAppointmentDetails (id, item) {
      this.$dialog.confirm(this.$i18n.t('PleaseConfirmYourChoice')).then(dialog => {
        if (parseInt(item.deleted) === 0) {
          this.setLocationsDetailsVisibility(id, 1).then((response) => {
            if (response.status === 'ok') {
              this.$root.refresh();
            }
          });
        } else {
          this.setLocationsDetailsVisibility(id, 0).then((response) => {
            if (response.status === 'ok') {
              this.$root.refresh();
            }
          });
        }
      });
    },
    isDriverOrTruckChanged () {
      return this.index > 0 && (this.locations[this.index].truck !== this.locations[this.index - 1].truck ||
             this.locations[this.index].driver !== this.locations[this.index - 1].driver || this.locations[this.index].codriver !== this.locations[this.index - 1].codriver);
    },
    isTruckChanged () {
      return this.index > 0 && this.index < this.locations.length - 1 && this.locations[this.index].truck !== this.locations[this.index - 1].truck;
    },
    onLocationDetailsSave (index) {
      this.addLocationsDetails(this.locations[index].id, this.locations[index].details).then((response) => {
        if (response.status === 'ok') {
          this.$store.commit('loads/updateLoadUpdated', response.results);
          this.detailsSaving = false;
          this.detailsEditing = false;
          this.$root.refresh();
        }
      });
      /* let fields = {};
      this.detailsSaving = true;
      fields = {
        locationsJSON: JSON.stringify(this.locations)
      };
      this.$root.saveLoad(fields, this.loadId).then(() => {
        this.$root.getLoadUpdated(this.loadId).then((response) => {
          if (response.status === 'ok') {
            this.$store.commit('loads/updateLoadUpdated', response.results);
            this.detailsSaving = false;
            this.detailsEditing = false;
          }
        });
      }); */
    },
    onEditLocationDetails (index) {
      this.detailsEditing = true;
    },
    getStatusLightStyle () {
      if (this.edit) {
        return {};
      }
      if (this.index === 0 && this.location.driverOnTheWayCheck > 0 && this.location.dispatcherOnTheWayCheck > 0) {
        return { boxShadow: '0px 0px 5px 5px #66BB6A' };
      } else if (this.location.driverDoneCheck || (((this.location.parking || this.location.exchange || this.location.returnToHQ) && this.location.driverOnSiteCheck) && this.location.dispatcherOnTheWayCheck > 0)) {
        return { boxShadow: '0px 0px 5px 5px #66BB6A' };
      } else if (this.location.driverOnSiteCheck > 0 && (!this.location.driverDoneCheck || !this.location.dispatcherOnTheWayCheck)) {
        return { boxShadow: '0px 0px 5px 5px #FF8F00' };
      }
      return {};
    },
    setPickupLocation (index) {
      this.$store.dispatch('loads/setPickupLocation', { index: index });
      this.$forceUpdate();
    },
    setDeliveryLocation (index) {
      this.$store.dispatch('loads/setDeliveryLocation', { index: index });
      this.$forceUpdate();
    },
    setParkingLocation (index) {
      this.$store.dispatch('loads/setParkingLocation', { index: index });
      this.updateLocationStartDatetime(index);
      this.$forceUpdate();
    },
    setExchangeLocation (index) {
      this.$store.dispatch('loads/setExchangeLocation', { index: index });
      this.updateLocationStartDatetime(index);
      this.$forceUpdate();
    },
    clearLocationType (index) {
      this.$store.dispatch('loads/clearLocationType', { index: index });
      this.updateLocationStartDatetime(index);
      this.$forceUpdate();
    },
    updateLocationStartDatetime (index) {
      const prevLocation = this.locations[index - 1];
      this.locations[index].targetDatetimes[0] = prevLocation.targetDatetimes[0] + prevLocation.duration;
    },
    initInsertedPlaceAutocomplete (index) {
      let autocomplete = new google.maps.places.Autocomplete(document.getElementById('newLocation' + index));
      autocomplete.setFields(['address_components', 'formatted_address', 'geometry', 'icon', 'name']);
      autocomplete.addListener('place_changed', () => {
        this.currentSelectedPlace = autocomplete.getPlace();
        if (this.currentSelectedPlace && this.currentSelectedPlace.geometry && this.currentSelectedPlace.address_components) {
          this.currentSelectedPlaceName = this.$root.getAddressStrFromComponentsObject(this.currentSelectedPlace.address_components);
          this.showPlaceSaveBtn = true;
        } else {
          this.$root.toast(this.$i18n.t('GetLocationError'), { color: 'error' });
        }
      });
    },

    saveNewLocations (index) {
      if (!this.edit || this.locations[index].returnToHQ) {
        this.$dialog.confirm(this.$root.t('Loads.updateReturnToHQLocationMsg')).then(dialog => {
          this.clearLocationType(index);
          this.$root.resetReturnToHQLocation(
            this.loadId,
            index,
            this.$root.getAddressStrFromComponentsObject(this.currentSelectedPlace.address_components),
            this.currentSelectedPlace.geometry.location.lat(),
            this.currentSelectedPlace.geometry.location.lng()).then((response) => {
            if (response.status === 'ok') {
              this.$root.refresh();
            } else if (response.status === 'error') {
              this.$root.toast(response.msg, {color: 'error'});
            }
          });
        });
      } else {
        if (this.currentSelectedPlace && this.currentSelectedPlace.geometry && this.currentSelectedPlace.address_components) {
          this.$store.dispatch('loads/setLocationField', { index: index, field: 'name', value: this.$root.getAddressStrFromComponentsObject(this.currentSelectedPlace.address_components) });
          this.$store.dispatch('loads/setLocationField', { index: index, field: 'lat', value: this.currentSelectedPlace.geometry.location.lat() });
          this.$store.dispatch('loads/setLocationField', { index: index, field: 'lng', value: this.currentSelectedPlace.geometry.location.lng() });
          this.$emit('locationUpdated');
        } else {
          this.$root.toast(this.$i18n.t('GetLocationError'), { color: 'error' });
        }
        this.showEditLocationField = false;
      }
    },
    // Deprecated since changing truck if location started is disabled
    checkAndResetLocationStatusAndOdometerValues (truck) {
      this.$store.dispatch('loads/setLocationField', { index: this.index, field: 'truck', value: truck });
      if (parseInt(this.locations[this.index + 1].odometerStart) > 0 ||
          parseInt(this.locations[this.index + 1].odometer) > 0 ||
          parseInt(this.locations[this.index + 1].driverOnTheWayCheck) > 0 ||
          parseInt(this.locations[this.index + 1].driverOnSiteCheck) > 0 ||
          parseInt(this.locations[this.index + 1].driverDoneCheck) > 0
      ) {
        this.$dialog.confirm(this.$i18n.t('Loads.truckChangedResetStatusMsg')).then(dialog => {
          this.$store.dispatch('loads/setLocationField', { index: this.index + 1, field: 'driverOnTheWayCheck', value: 0 });
          this.$store.dispatch('loads/setLocationField', { index: this.index + 1, field: 'startedByDriverTS', value: 0 });

          this.$store.dispatch('loads/setLocationField', { index: this.index + 1, field: 'driverOnSiteCheck', value: 0 });
          this.$store.dispatch('loads/setLocationField', { index: this.index + 1, field: 'onSiteByDriverTS', value: 0 });

          this.$store.dispatch('loads/setLocationField', { index: this.index + 1, field: 'driverDoneCheck', value: 0 });
          this.$store.dispatch('loads/setLocationField', { index: this.index + 1, field: 'doneByDriverTS', value: 0 });

          this.$store.dispatch('loads/setLocationField', { index: this.index + 1, field: 'notDoneByDriverTS', value: 0 });

          this.$store.dispatch('loads/setLocationField', { index: this.index + 1, field: 'odometer', value: 0 });
          this.$store.dispatch('loads/setLocationField', { index: this.index + 1, field: 'odometerStart', value: 0 });

          this.$root.resetLocationStatusAndOdometer(this.locations[this.index + 1].id);

          // this.locations[this.index + 1].odometer = 0;
          // this.locations[this.index + 1].odometerUPLOAD = 0;
          // this.$forceUpdate();

          // this.$root.toast(this.$i18n.t('Loads.truckChangedResetStatusMsg'), { color: 'info' });
        });
      }
    },
    truckClick () {
      this.updatingEnabled = true;
    },
    truckChanged (value) {
      if (!this.updatingEnabled) return;
      this.updatingEnabled = false;
      /* if (value === 0) {
        this.$store.dispatch('loads/setLocationField', { index: this.index, field: 'truck', value: value });
        // Reset status and odometer values
        if (this.updatingEnabled) {
          this.updatingEnabled = false;
          this.resetLocationStatusAndOdometerValues();
        }
        return;
      } */
      if (value > 0) {
        let prevTruckValue = this.locations[0].truck;
        this.$store.dispatch('loads/setLocationField', { index: this.index, field: 'truck', value: value });
        // fill all locations if new load and driver selected on first step
        if (!this.loadId && this.index === 0) {
          this.populateLocationSettingsDown();
        }
        // Update drivers only if we create load by checking bidId parameter in url
        this.$root.getTruck(value).then((response) => {
          if (response.status === 'ok') {
            // this.checkAndResetLocationStatusAndOdometerValues(value);
            if (this.index === 0) {
              // Ask to change location to current truck location
              if (!this.locations[1].startedByDriverTS && !prevTruckValue) {
                this.$dialog.confirm(this.$root.t('Loads.SetCurrentLocationFromSelectedTruckLocationConfirmMsg')).then(dialog => {
                  this.setLocationDataFromTruck(0, response.result);
                  this.$emit('locationUpdated');
                });
              }
            }
            /* if (response.result.driver) {
                        this.driverChanged(response.result.driver);
                    }
                    if (response.result.codriver) {
                        this.codriverChanged(response.result.codriver);
                    } */
          } else {
            this.$root.toast(response.msg, { color: 'error' });
          }
        });
      } else {
        this.$store.dispatch('loads/setLocationField', { index: this.index, field: 'truck', value: 0 });
      }
    },
    resetDriverToCurrentRate (driver, isCoDriver, isTeam, rateName) {
      let rateObject = {
        name: '',
        type: 0,
        minMiles: 0,
        soloRate: 0,
        teamRate: 0,
        soloRateFixed: 0,
        teamRateFixed: 0
      };
      let driverData = {};

      if (driver > 0) {
        rateObject = this.getRateFromListByDriverAndName(rateName, this.driversRates[driver]);
        rateName = rateObject.name;
        if (this.driversInfo.hasOwnProperty(driver)) {
          driverData = this.driversInfo[driver];
        }
      }

      this.$store.dispatch('loads/setLocationField', { index: this.index, field: isCoDriver ? 'coDriver' : 'driver', value: driver });
      this.$store.dispatch('loads/setLocationField', { index: this.index, field: isCoDriver ? 'coDriverRateName' : 'driverRateName', value: rateName });
      this.$store.dispatch('loads/setLocationField', { index: this.index, field: isCoDriver ? 'coDriverRateType' : 'driverRateType', value: rateObject.type });
      this.$store.dispatch('loads/setLocationField', { index: this.index, field: isCoDriver ? 'coDriverRateMinMiles' : 'driverRateMinMiles', value: rateObject.minMiles });
      this.$store.dispatch('loads/setLocationField', { index: this.index, field: isCoDriver ? 'coDriverRate' : 'driverRate', value: isTeam ? rateObject.teamRate : rateObject.soloRate });
      this.$store.dispatch('loads/setLocationField', { index: this.index, field: isCoDriver ? 'coDriverRateFixed' : 'driverRateFixed', value: isTeam ? rateObject.teamRateFixed : rateObject.soloRateFixed });

      if (driverData.hasOwnProperty('driverIsWorkOnPercentSWITCH') && parseInt(driverData.driverIsWorkOnPercentSWITCH) === 1 &&
          driverData.hasOwnProperty('driverPercentRate') && driverData.hasOwnProperty('driverTeamPercentRate')) {
        this.$store.dispatch('loads/setLocationField', {
          index: this.index,
          field: isCoDriver ? 'coDriverPercentRate' : 'driverPercentRate',
          value: isTeam ? driverData.driverTeamPercentRate : driverData.driverPercentRate
        });
      }
    },
    driverChanged (value, isCoDriver = false) {
      // check is new driver value equals to coDriver and wise versa
      if (this.location.coDriver && parseInt(this.location.coDriver) === parseInt(value)) {
        this.$root.toast(this.$i18n.t('Loads.DriversAreEqualError'), { color: 'error' });
        return;
      }

      if (this.location.coDriver > 0) {
        this.resetDriverToCurrentRate(value, false, true, this.location.driverRateName || 'Current Rate');
        this.resetDriverToCurrentRate(this.location.coDriver, true, true, this.location.coDriverRateName || 'Current Rate');
      } else {
        this.resetDriverToCurrentRate(value, false, false, this.location.driverRateName || 'Current Rate');
      }

      if (value === 0) {
        this.resetDriverToCurrentRate(this.location.coDriver, true, false, this.location.coDriverRateName || 'Current Rate');
      }

      //console.log(this.driversRates[value]);
      // fill all locations if new load and driver selected on first step
      if (!this.loadId && this.index === 0) {
        this.populateLocationSettingsDown();
      }
    },
    coDriverChanged (value) {
      // check is new driver value equals to coDriver
      if (this.location.driver && parseInt(this.location.driver) === parseInt(value)) {
        this.$root.toast(this.$i18n.t('Loads.DriversAreEqualError'), { color: 'error' });
        this.$forceUpdate();
        return;
      }

      if (this.location.driver > 0) {
        this.resetDriverToCurrentRate(this.location.driver, false, true, this.location.driverRateName || 'Current Rate');
        this.resetDriverToCurrentRate(value, true, true, this.location.coDriverRateName || 'Current Rate');
      } else {
        this.resetDriverToCurrentRate(value, true, false, this.location.coDriverRateName || 'Current Rate');
      }

      if (value === 0) {
        this.resetDriverToCurrentRate(this.location.driver, false, false, this.location.driverRateName || 'Current Rate');
      }
      // fill all locations if new load and driver selected on first step
      if (!this.loadId && this.index === 0) {
        this.populateLocationSettingsDown();
      }
    },
    driverRateTypeChanged (value, isCoDriver = false) {
      this.$store.dispatch('loads/setLocationField', { index: this.index, field: isCoDriver ? 'coDriverRateName' : 'driverRateName', value: value });
      this.resetDriverToCurrentRate(isCoDriver ? this.location.coDriver : this.location.driver, isCoDriver, this.location.coDriver > 0, value);
      // fill all locations if new load and driver selected on first step
      if (!this.loadId && this.index === 0) {
        this.populateLocationSettingsDown();
      }
    },
    driverRateChanged (value, isCoDriver = false) {
      this.$store.dispatch('loads/setLocationField', { index: this.index, field: isCoDriver ? 'coDriverRate' : 'driverRate', value: value });
      // fill all locations if new load and driver selected on first step
      if (!this.loadId && this.index === 0) {
        this.populateLocationSettingsDown();
      }
    },
    populateLocationSettingsDown (fieldsToCopyArray = []) {
      this.$store.dispatch('loads/populateLocationSettingsDown', { index: this.index, fieldsToCopyArray: fieldsToCopyArray });
    },
    setAppoinment (value) {
      this.$store.dispatch('loads/setAppoinment', { index: this.index, value: value });
    },
    onUpdateLocationCancel (value) {
      this.setLoadLocationsField(this.location.loadId, this.location.number, 'canceled', value).then((response) => {
        if (response.status === 'ok') {
          this.$store.commit('loads/updateLoadUpdated', response.updated);
          this.$store.dispatch('loads/setLocationField', { index: this.index, field: 'canceled', value: value });
        }
      });
    },
    onDispatcherApproved () {
      this.setLoadLocationsApprove(this.location.loadId, this.location.number).then(async (response) => {
        if (response.status === 'ok') {
          this.$store.dispatch('loads/statusChange', { index: this.index, value: 'approvedByDispatcherTS', userId: this.$store.getters.currentUser.id });
          this.$store.dispatch('loads/statusChange', { index: this.index, value: 'dispatcherOnTheWayCheck', userId: this.$store.getters.currentUser.id });
          if (this.finished === 0) {
            const checkResult = await this.$root.checkIsLoadFinished(this.loadId, this.locations, this.isReturnToHQ);
            if (checkResult.status === 'ok') {
              if (checkResult.addReturnToHQLocation) {
                this.$root.setDefaultHQ = async (place) => {
                  await this.$root.addReturnToHQToLoad(this.loadId, place);
                };
                this.$root.getDefaultHQ();
                this.$store.commit('loads/updateLoadUpdated', response.updated);
              }
            }
            this.$set(this.$root, 'loadModalKey', new Date().getTime());
          } else if (this.isLoadFinished(this.locations) && !this.locations.find(el => el.returnToHQ === 1 && el.deleted === 0)) {
            try {
              await this.$dialog.confirm(this.$root.t('Loads.onReturnToHQSwitchConfirmMsg'), {
                okText: 'Return to Home',
                cancelText: 'Do not know'
              });
              this.$root.setDefaultHQ = async (place) => {
                await this.$root.addReturnToHQToLoad(this.loadId, place);
              };
              this.$root.getDefaultHQ();
              this.$store.commit('loads/updateLoadUpdated', response.updated);
              this.$set(this.$root, 'loadModalKey', new Date().getTime());
              this.fetchData();
            } catch (e) {
            }
          }
        } else if (response.status === 'error') {
          this.$root.toast(response.msg, { color: 'error' });
        }
      });
    },
    onDispatcherApprovedCancel () {
      if (this.isCanCloseApprovedButton (this.index, this.locations)) {
        this.cancelLoadLocationsApprove(this.location.loadId, this.location.number).then((response) => {
          if (response.status === 'ok') {
            this.$store.commit('loads/updateLoadUpdated', response.updated);
            this.$store.dispatch('loads/statusChange', { index: this.index, value: 'approvedByDispatcherTS', userId: this.$store.getters.currentUser.id });
            this.$store.dispatch('loads/statusChange', { index: this.index, value: 'dispatcherOnTheWayCheck', userId: this.$store.getters.currentUser.id });
            if (this.finished === 0) {
              this.$root.checkIsLoadFinished(this.loadId, this.locations);
            }
          } else if (response.status === 'error') {
            this.$root.toast(response.msg, { color: 'error' });
          }
        });
      }
    },
    statusChange (value) {
      this.$store.dispatch('loads/statusChange', { index: this.index, value: value, userId: this.$store.getters.currentUser.id }).then(() => {
        let fields = {
          locationsJSON: JSON.stringify(this.locations)
        };
        if (value === 'driverDoneCheck') {
          // Do not calc detention automatically
          // fields['Detention'] = this.$root.getDetention(this.locations, this.$store.getters['loads/getMaxWaitingHoursUntileDetentionInSeconds'], this.$store.getters['loads/getDetentionPrice']);
        }
        this.$root.saveLoad(fields, this.loadId).then(() => {
          this.$root.getLoadUpdated(this.loadId).then((response) => {
            if (response.status === 'ok') {
              this.$store.commit('loads/updateLoadUpdated', response.results);
            } else if (response.status === 'error') {
              this.$root.toast(response.msg, { color: 'error' });
            }
          });
        });
        if (parseInt(this.finished) === 0) {
          this.$root.checkIsLoadFinished(this.loadId, this.locations);
        }
      });
    },
    isStartChecked (locations, index) {
      return locations[index].driverOnTheWayCheck > 0 ||
              this.locationStatusReadOnly ||
              this.readonly ||
              locations[index].canceled === 1;
    },
    onStartClick () {
      this.setLoadLocationsStart(this.location.loadId, this.location.number).then((response) => {
        if (response.status === 'ok') {
          this.$store.commit('loads/updateLoadUpdated', response.updated);
          this.$store.dispatch('loads/statusChange', { index: this.index, value: 'driverOnTheWayCheck', userId: this.$store.getters.currentUser.id });
          this.$store.dispatch('loads/statusChange', { index: this.index, value: 'startedByDriverTS', userId: this.$store.getters.currentUser.id });
          this.$emit('refreshDistanceAndDuration', this.index);
        } else if (response.status === 'error') {
          this.$root.toast(response.msg, { color: 'error' });
        }
      });
    },
    onStartCancelClick () {
      if (this.location.driverOnTheWayCheck > 0 && this.location.onSiteByDriverTS === 0) {
        this.cancelLoadLocationsStart(this.location.loadId, this.location.number).then((response) => {
          if (response.status === 'ok') {
            this.$store.commit('loads/updateLoadUpdated', response.updated);
            this.$store.dispatch('loads/statusChange', { index: this.index, value: 'driverOnTheWayCheck', userId: this.$store.getters.currentUser.id });
            this.$store.dispatch('loads/statusChange', { index: this.index, value: 'startedByDriverTS', userId: this.$store.getters.currentUser.id });
            this.$store.dispatch('loads/setLocationField', { index: this.index, field: 'odometer', value: response.odometerValue });

            this.$emit('refreshDistanceAndDuration', this.index);
          } else if (response.status === 'error') {
            this.$root.toast(response.msg, { color: 'error' });
          }
        });
      }
    },
    isOnSiteChecked(locations, index) {
      return  locations[index].onSiteByDriverTS > 0 ||
              this.locationStatusReadOnly ||
              this.readonly ||
              locations[index].canceled === 1 ||
              ( index < locations.length-1 &&
                parseInt(locations[index-1].truck) === 0 &&
                parseInt(locations[index-1].driver) === 0 &&
                !this.isDriverOwnerOperator
              );
    },
    onOnSiteClick () {
      this.setLoadLocationsOnSite(this.location.loadId, this.location.number).then((response) => {
        if (response.status === 'ok') {
          this.$store.commit('loads/updateLoadUpdated', response.updated);
          this.$store.dispatch('loads/statusChange', { index: this.index, value: 'driverOnSiteCheck', userId: this.$store.getters.currentUser.id });
          this.$store.dispatch('loads/statusChange', { index: this.index, value: 'onSiteByDriverTS', userId: this.$store.getters.currentUser.id });
          if (this.locations[this.index].exchange === 1 || this.locations[this.index].parking === 1 || this.locations[this.index].returnToHQ === 1) {
            this.$store.dispatch('loads/statusChange', { index: this.index, value: 'driverDoneCheck', userId: this.$store.getters.currentUser.id });
            this.$store.dispatch('loads/statusChange', { index: this.index, value: 'doneByDriverTS', userId: this.$store.getters.currentUser.id });
          }
          this.$store.dispatch('loads/setLocationField', { index: this.index, field: 'odometer', value: response.odometerValue });
          if (this.finished === 0) {
            this.$root.checkIsLoadFinished(this.loadId, this.locations);
          }
        } else if (response.status === 'error') {
          this.$root.toast(response.msg, { color: 'error' });
        }
      });
    },
    onOnSiteCancelClick () {
      if (this.location.onSiteByDriverTS > 0 &&
          !this.location.dispatcherOnTheWayCheck &&
          (!this.location.driverDoneCheck || this.isLocationDontHaveDoneBtn(this.location))) {
        this.cancelLoadLocationsOnSite(this.location.loadId, this.location.number).then((response) => {
          if (response.status === 'ok') {
            this.$store.commit('loads/updateLoadUpdated', response.updated);
            this.$store.dispatch('loads/statusChange', { index: this.index, value: 'driverOnSiteCheck', userId: this.$store.getters.currentUser.id });
            this.$store.dispatch('loads/statusChange', { index: this.index, value: 'onSiteByDriverTS', userId: this.$store.getters.currentUser.id });
            if (this.locations[this.index].exchange === 1 || this.locations[this.index].parking === 1 || this.locations[this.index].returnToHQ === 1) {
              this.$store.dispatch('loads/statusChange', { index: this.index, value: 'driverDoneCheck', userId: this.$store.getters.currentUser.id });
              this.$store.dispatch('loads/statusChange', { index: this.index, value: 'doneByDriverTS', userId: this.$store.getters.currentUser.id });
            }
            if (this.finished === 0) {
              this.$root.checkIsLoadFinished(this.loadId, this.locations);
            }
          } else if (response.status === 'error') {
            this.$root.toast(response.msg, { color: 'error' });
          }
        });
      }
    },
    isOnDoneChecked(locations, index) {
      return locations[index].doneByDriverTS > 0 ||
             this.locationStatusReadOnly ||
             locations[index].canceled === 1 ||
             this.readonly ||
             (
              index < locations.length - 1 &&
              parseInt(locations[index - 1].truck) === 0 &&
              parseInt(locations[index - 1].driver) === 0 &&
              !this.isDriverOwnerOperator
            );
    },
    onOnDoneClick () {
      this.setLoadLocationsDone(this.location.loadId, this.location.number).then((response) => {
        if (response.status === 'ok') {
          this.$store.commit('loads/updateLoadUpdated', response.updated);
          this.$store.dispatch('loads/statusChange', { index: this.index, value: 'driverDoneCheck', userId: this.$store.getters.currentUser.id });
          this.$store.dispatch('loads/statusChange', { index: this.index, value: 'doneByDriverTS', userId: this.$store.getters.currentUser.id });
          if (this.finished === 0) {
            this.$root.checkIsLoadFinished(this.loadId, this.locations);
          }
        } else if (response.status === 'error') {
          this.$root.toast(response.msg, { color: 'error' });
        }
      });
    },
    onOnDoneCancelClick () {
      if (this.location.doneByDriverTS > 0 && !this.location.dispatcherOnTheWayCheck) {
        this.cancelLoadLocationsDone(this.location.loadId, this.location.number).then((response) => {
          if (response.status === 'ok') {
            this.$store.commit('loads/updateLoadUpdated', response.updated);
            this.$store.dispatch('loads/statusChange', { index: this.index, value: 'driverDoneCheck', userId: this.$store.getters.currentUser.id });
            this.$store.dispatch('loads/statusChange', { index: this.index, value: 'doneByDriverTS', userId: this.$store.getters.currentUser.id });
            if (this.finished === 0) {
              this.$root.checkIsLoadFinished(this.loadId, this.locations);
            }
          } else if (response.status === 'error') {
            this.$root.toast(response.msg, { color: 'error' });
          }
        });
      }
    },
    isOnNotDoneChecked(locations, index) {
      return locations[index].notDoneByDriverTS > 0 ||
             this.locationStatusReadOnly ||
             locations[index].canceled === 1 ||
             this.readonly ||
             (
              index < locations.length - 1 &&
              parseInt(locations[index - 1].truck) === 0 &&
              parseInt(locations[index - 1].driver) === 0 &&
              !this.isDriverOwnerOperator
            );
    },
    onNotDoneClick () {
      let i = 0;
      this.setLoadLocationsNotDone(this.location.loadId, this.location.number).then(async (response) => {
        if (response.status === 'ok') {
          this.$store.commit('loads/updateLoadUpdated', response.updated);
          this.$store.dispatch('loads/statusChange', { index: this.index, value: 'driverNotDoneCheck', userId: this.$store.getters.currentUser.id });
          this.$store.dispatch('loads/statusChange', { index: this.index, value: 'notDoneByDriverTS', userId: this.$store.getters.currentUser.id });

          try {
            await this.$dialog.confirm(this.$root.t('Loads.cancelNextLocationsConfirmationMessage'));
            const response2 = await this.cancelAllNextLocations(this.location.loadId, this.location.number + 1);
            if (response2.status === 'ok') {
              for (i = 0; i < this.locations.length; i++) {
                if (this.locations[i].number > this.location.number && !locations.isReturnToHQ) {
                  this.$store.dispatch('loads/setLocationField', { index: i, field: 'canceled', value: 1 });
                }
              }
            } else if (response.status === 'error') {
              this.$root.toast(response.msg, { color: 'error' });
            }
          } catch (e) {
          }

          if (!this.isReturnToHQ && this.isLastLocation) {
            try {
              await this.$dialog.confirm(this.$root.t('Loads.onReturnToHQSwitchConfirmMsg'), {
                okText: 'Return to Home',
                cancelText: 'Do not know'
              });
              this.$root.setDefaultHQ = async (place) => {
                await this.$root.addReturnToHQToLoad(this.loadId);
              };
              this.$root.getDefaultHQ();
            } catch (e) {
            }
          }
        }
      });
    },
    onNotDoneCancelClick () {
      if (this.location.notDoneByDriverTS > 0 && !this.location.dispatcherOnTheWayCheck) {
        this.cancelLoadLocationsNotDone(this.location.loadId, this.location.number).then((response) => {
          if (response.status === 'ok') {
            this.$store.commit('loads/updateLoadUpdated', response.updated);
            this.$store.dispatch('loads/statusChange', { index: this.index, value: 'driverNotDoneCheck', userId: this.$store.getters.currentUser.id });
            this.$store.dispatch('loads/statusChange', { index: this.index, value: 'notDoneByDriverTS', userId: this.$store.getters.currentUser.id });
            /* if (this.finished === 0) {
              this.$root.checkIsLoadFinished(this.loadId, this.locations);
            } */
          } else if (response.status === 'error') {
            this.$root.toast(response.msg, { color: 'error' });
          }
        });
      }
    },

    setLocationDateTime (value, index) {
      const date = this.$root.getTimestampFromDateTimeString(value);
      if (this.$store.getters['loads/getFirstPickupLocationIndex'] === index) {
        this.$store.dispatch('loads/setStarted', date);
      }
      if (this.$store.getters['loads/getLastDeliveryLocationIndex'] === index) {
        this.$store.dispatch('loads/setEnded', date);
      }
      this.$store.dispatch('loads/setLocationDateTime', { index: this.index, value: date });
    },
    onOdometerStartChanged (index, value) {
      this.$store.dispatch('loads/setLocationField', { index: index, field: 'odometerStart', value: value });
      this.$forceUpdate();
    },
    onOdometerStartUploadChanged (index, value) {
      this.$store.dispatch('loads/setLocationField', { index: index, field: 'odometerStartUPLOAD', value: value });
      this.$forceUpdate();
    },
    onOdometerEndChanged (index, value) {
      this.$store.dispatch('loads/setLocationField', { index: index, field: 'odometer', value: value });
      this.$forceUpdate();
    },
    onOdometerEndUploadChanged (index, value) {
      this.$store.dispatch('loads/setLocationField', { index: index, field: 'odometerUPLOAD', value: value });
      this.$forceUpdate();
    },
    locationUp () {
      this.$emit('move-location-up', this.index);
      this.$forceUpdate();
    },
    locationDown () {
      this.$emit('move-location-down', this.index);
      this.$forceUpdate();
    },
    deleteLocation () {
      this.$emit('delete-location', this.index);
      this.$forceUpdate();
    },
    odometerUpload () {
      if (this.$refs.odometerUpload) {
        this.$refs.odometerUpload.pickFile();
      }
    },
    setCheckIn (location) {
      const value = this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).format('MM/DD/YYYY h:mm A');
      this.$store.dispatch('loads/setLocationField', { index: this.index, field: 'checkIn', value: value });
      this.changeLocationCheckInCheckOut('checkIn', location, value);
    },
    setCheckOut (location) {
      const value = this.$moment.moment().utcOffset(this.$store.getters.timezoneOffsetMinutes).format('MM/DD/YYYY h:mm A');
      this.$store.dispatch('loads/setLocationField', { index: this.index, field: 'checkOut', value: value });
      this.changeLocationCheckInCheckOut('checkOut', location, value);
    },
    setLocationDataFromTruck (index, truckData) {
      this.$store.dispatch('loads/setLocationField', {
        index: index,
        field: 'name',
        value: truckData.location
      });
      this.$store.dispatch('loads/setLocationField', {
        index: index,
        field: 'lat',
        value: truckData.lat
      });
      this.$store.dispatch('loads/setLocationField', {
        index: index,
        field: 'lng',
        value: truckData.lng
      });
    },
    isCanCloseApprovedButton(index, locations) {
      const isNextLocationCheck = locations.length - 1 > index &&
                                  locations[index + 1].approvedByDispatcherTS === 0 &&
                                  locations[index + 1].onSiteByDriverTS === 0 &&
                                  locations[index + 1].doneByDriverTS === 0 &&
                                  locations[index + 1].notDoneByDriverTS === 0 &&
                                  locations[index + 1].startedByDriverTS === 0;

      const isLastLoationCheck = locations.length - 1 === index
      return locations[index].approvedByDispatcherTS > 0 && (isLastLoationCheck || isNextLocationCheck);

    }
  }
};
</script>
