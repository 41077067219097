<template>
  <v-layout wrap>
    <v-flex xs12 md6 class="pt-2">
      <GoogleMapLoader v-if="$root.isUseGoogleMaps && locations"
                       :ref="'map' + loadId"
                       :height="(windowHeight * 0.5) + 'px'"
                       :showTraffic="false"
                       :showTrucks="false"
                       :waypoints="locations"
                       :loadStarted="started"
                       :loadEnded="ended"
                       :truckId="truckId"
                       :trucks="trucks"
                       :loadId="0"
                       :dontDrawTruckPaths="false"
                       :drawPathByWaypoints="false"
                       :showCustomIcons="true"
                       />
      <v-progress-linear indeterminate v-if="!$root.isUseGoogleMaps && osmMapLoading"/>
      <OSMMapLoader v-if="!$root.isUseGoogleMaps && locations"
                    v-show="!osmMapLoading"
                    :ref="'osmMap' + loadId"
                    :idOverride="'osm_map_locations_timeline_page'"
                    :height="(windowHeight * 0.5) + 'px'"
                    :showTraffic="false"
                    :showTrucks="false"
                    :key="osmMapKey"
                    :waypoints="locations"
                    :loadStarted="started"
                    :loadEnded="ended"
                    :truckId="truckId"
                    :trucks="trucks"
                    :loadId="0"
                    :dontDrawTruckPaths="false"
                    :drawPathByWaypoints="false"
                    :showCustomIcons="true"
                    :marker="locations.length > 0 ? locations[0] : null"/>

      <!--:loadStarted="fields.started"
      :loadEnded="fields.ended"-->
    </v-flex>
    <v-flex xs12 md6 :style="{height: (windowHeight - 100) + 'px', overflowY: 'auto', overflowX: 'hidden'}">
      <!--<v-timeline align-top dense>-->
        <v-list>
          <template v-for="(location, index) in locations">
            <!--<v-timeline-item-->
            <v-list-item :key="location.id"
                :icon="$root.getLocationIcon(location)"
                :icon-color="$root.getLocationColor(location)"
                color="#fff"
                small
                @click="() => { toggleDetails(index); }"
              >
              <v-row class="pt-1 mb-0">
                <v-col class="pb-0">
                  <div class="pa-1 ma-1" v-if="location.number === 0">
                    <i v-if="location.targetStartTimestamp > 0" class="ml-1" v-html="$root.getDateTimeStringFromTimestamp(location.targetStartTimestamp)"></i>
                    <v-icon :color="$root.getLocationColor(location)">{{ $root.getLocationIcon(location) }}</v-icon> <b>Start Location</b>&nbsp;
                    <strong>Load:&nbsp;<a href="#" @click.stop="$root.onShowLoadPreview(location.loadId)">#{{location.loadId}}</a>&nbsp;</strong><br/>
                    {{location.name}}
                  </div>
                  <div class="pa-1 ma-1" v-if="location.number > 0">
                    <i v-if="location.targetStartTimestamp > 0" class="ml-1" v-html="$root.getDateTimeStringFromTimestamp(location.targetStartTimestamp)"></i>
                    <table>
                        <tbody>
                          <tr class="caption">
                            <td colspan="2">
                              <v-icon class="mr-2" small :color="$root.getLocationColor(location)">{{ $root.getLocationIcon(location) }}</v-icon>
                              <strong>Load:&nbsp;<a href="#" @click.stop="$root.onShowLoadPreview(location.loadId)">#{{location.loadId}}</a>&nbsp;</strong>
                              {{location.name}}
                            </td>
                          </tr>
                          <template v-if="locationsShowDetails[index]">
                            <tr class="caption" v-if="location.truckName">
                              <td>Truck</td>
                              <td><a @click="$root.onShowTruckPreview(location.truck, location.truckName)">{{location.truckName}}</a></td>
                            </tr>
                            <tr class="caption">
                              <td>Started</td>
                              <td>{{location.startedByDriverTS > 0 ? $root.getDateTimeStringFromTimestamp(location.startedByDriverTS) : 'N/A'}}</td>
                            </tr>
                            <tr class="caption">
                              <td>On Site</td>
                              <td>{{location.onSiteByDriverTS > 0 ? $root.getDateTimeStringFromTimestamp(location.onSiteByDriverTS) : 'N/A'}}</td>
                            </tr>
                            <tr class="caption">
                              <td>Done</td>
                              <td>{{location.doneByDriverTS > 0 ? $root.getDateTimeStringFromTimestamp(location.doneByDriverTS) : 'N/A'}}</td>
                            </tr>
                            <tr class="caption">
                              <td>Odometer on start</td>
                              <td>{{location.odometerStart.toLocaleString()}}{{$t('milesShort')}}</td>
                            </tr>
                            <tr class="caption">
                              <td>Odometer on site</td>
                              <td>{{location.odometerEnd.toLocaleString()}}{{$t('milesShort')}}</td>
                            </tr>
                            <tr class="caption">
                              <td>Distance by maps</td>
                              <td>{{$root.metersToMiles(location.distance).toLocaleString()}} {{$t('milesShort')}}</td>
                            </tr>
                            <tr class="caption">
                              <td>Distance by odometer</td>
                              <td v-if="location.odometerStart > 0 && location.odometerEnd > 0">{{parseInt(location.odometerEnd - location.odometerStart).toLocaleString()}} {{$t('milesShort')}}</td>
                              <td v-else>N/A</td>
                            </tr>
                            <tr class="caption" v-if="location.checkIn">
                              <td>Check In</td>
                              <td>{{location.checkIn}}</td>
                            </tr>
                            <tr class="caption" v-if="location.checkOut">
                              <td>Check Out</td>
                              <td>{{location.checkOut}}</td>
                            </tr>
                            <tr class="caption" v-if="location.pickup || location.delivery">
                              <td>Detention</td>
                              <td>
                                <v-layout>
                                  <v-flex shrink class="pt-2">{{$root.showDuration(location.driverDetentionMinutes * 60)}}</v-flex>
                                  <v-flex shrink class="pt-1">
                                    <OverriddenFieldsButton
                                      :key="$root.overrideFieldKey"
                                      :userId="driverId"
                                      :itemType="$store.getters.overriddenFieldsTypes.LOADS_LOCATIONS"
                                      :itemId="location.id"
                                      prefix="hh:mm"
                                      :isTime="true"
                                      fieldName="driverDetentionMinutes"
                                      :currentNote="location.driverDetentionMinutesNote"
                                      :currentValue="location.driverDetentionMinutes"
                                      :showCancel="location.isOverriddenDriverDetentionMinutes"
                                      @change="$emit('change')"
                                    />
                                  </v-flex>
                                </v-layout>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-list-item>
            <v-divider/>
            <!-- </v-timeline-item> -->
          </template>
        </v-list>
      <!-- </v-timeline> -->
    </v-flex>
  </v-layout>
</template>

<script>

import GoogleMapLoader from './GoogleMapLoader';
import OSMMapLoader from './OSMMapLoader';
import OverriddenFieldsButton from './OverriddenFieldsButton'

export default {
  name: 'LocationsTimeline',
  components: { OSMMapLoader, GoogleMapLoader, OverriddenFieldsButton },
  props: ['locations', 'started', 'ended', 'truckId', 'trucks', 'driverId', 'loadId'],
  data () {
    return {
      osmMapKey: new Date().getTime(),
      osmMapLoading: true,
      locationsShowDetails: {}
    }
  },
  computed: {
    windowHeight () {
      return window.innerHeight;
    },
  },
  mounted () {
    this.$refs['osmMap' + this.loadId].refreshMap();

    setTimeout(() => {
      this.osmMapKey = 'LocationsTimelineOsmMapKey' + new Date().getTime();
      this.$set(this, 'osmMapLoading', false);
    }, 1000);
  },
  methods: {
    toggleDetails(index ) {
      if (this.locationsShowDetails[index]) {
        this.$set(this.locationsShowDetails, index, false);
      }
      else {
        this.$set(this.locationsShowDetails, index, true);
      }
    }
  }
}


</script>
