<template>
  <div>W9 Form</div>
</template>

<script>
export default {
  name: 'W9Form',
  props: [],
  mixins: [],
  data () {
    return {
    };
  }
}
</script>

