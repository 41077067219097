<template>
  <v-container grid-list-md style="max-width:3840px;">
    <div v-if="error">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <div v-else>
      <v-layout v-if="!hideFilters">
        <v-flex xs12>
          <FiltersPanel
            ref="filtersPanel"
            prefix="notificationsList"
            outlined
            :isExpansionPanel="$root.isMobile"
            :loading="loading"
            :items="filtersItems"
            :key="filtersKey"
            @change="onFilterChanged"/>
        </v-flex>
      </v-layout>

      <v-card v-if="!hideTitle">
        <v-card-title>
          <v-layout wrap>
            <v-flex :shrink="!$root.isMobile">
              <h4 v-if="$root.isMobile">
                {{$t('Notifications.ListTitle')}}
                <v-btn
                  v-if="$root.isMobile"
                  small
                  class="float-right"
                  @click="$refs.filtersPanel.showFiltersDrawer()">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </h4>
              <h3 v-else class="mt-1">
                {{$t('Notifications.ListTitle')}}
              </h3>
            </v-flex>
            <v-flex v-if="!$root.isMobile" shrink>
              <v-btn small color="success" class="ml-2" @click="this.$root.contextCreate"><v-icon left>mdi-plus</v-icon>{{$t('Create')}}</v-btn>
            </v-flex>
            <v-flex :grow="!$root.isMobile" :xs12="$root.isMobile">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                dense
                outlined
                hide-details
              />
            </v-flex>
          </v-layout>
        </v-card-title>
      </v-card>

      <v-progress-linear v-if="loading && !error" indeterminate/>
      <v-list>
          <v-list-item v-if="itemsCount > itemsPerPage">
            <v-list-item-content>
              <v-layout wrap>
                <v-flex md1 v-if="!$root.isMobile"></v-flex>
                <v-flex xs12 md10>
                  <Pagination :key="'Pagination' + page"
                              :value="page"
                              :itemsCount="itemsCount"
                              :itemsPerPage="itemsPerPage"
                              @page-changed="pageChanged"/>
                  </v-flex>
                  <v-flex xs12 md1 class="pt-3">
                    <v-btn @click.stop="onMarkAllAsRead()" v-if="$store.getters.newNotificationsCount > 0" class="float-right">
                      {{$t('Notifications.MarkAllAsRead')}}
                      <v-icon right>mdi-email-open</v-icon>
                    </v-btn>
                  </v-flex>
              </v-layout>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isDrawer && items.length > 0">
            <v-list-item-content>
              <v-btn @click.stop="onMarkAllAsRead()" :disabled="$store.getters.newNotificationsCount === 0">
                <v-icon>mdi-email-open</v-icon>
                {{$t('Notifications.MarkAllAsRead')}}
              </v-btn>
            </v-list-item-content>
          </v-list-item>

          <v-list-item-group>
          <template v-for="(item, index) in items">
            <v-list-item @click="onOpen(item.id, item.url, item)" :key="'notification' + index">
              <v-list-item-icon v-if="!$root.isMobile">
                <div class="text-center" style="width:50px;" v-if="item.userId > 0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-avatar v-on="on"
                                style="cursor: pointer;"
                                class="ml-1"
                                :size="32"
                                :color="(userData['user_'+item.userId].initials && !userData['user_'+item.userId].avatarUPLOAD)?'#015aaa':null">
                        <v-img :src="$root.getImageUrl('avatar', userData['user_'+item.userId].avatarUPLOAD)" v-if="userData['user_'+item.userId].avatarUPLOAD"></v-img>
                        <span class="white--text" v-else-if="userData['user_'+item.userId].initials">{{userData['user_'+item.userId].initials}}</span>
                        <v-icon large v-else>account_circle</v-icon>
                      </v-avatar>
                    </template>
                    <span>{{userData['user_'+item.userId].fullName}}</span>
                  </v-tooltip>
                </div>
                <div v-else class="text-center" style="width:50px;overflow: hidden;">
                  <v-icon>mdi-account-group</v-icon>
                  <br/>
                  <span class="caption">{{item.userRole === '' ? $t('All') : item.userRole}}</span>
                </div>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :class="item.status === 0 ? 'text-wrap font-weight-bold' : 'text-wrap'">
                  <template v-if="$root.isMobile">
                    <span class="caption">{{userData['user_'+item.userId].fullName}}</span><br/>
                  </template>
                  {{item.title}}
                  <v-chip color="warning" small v-if="item.status === 0 && item.userId === $store.getters.currentUserId">Unread</v-chip>
                </v-list-item-title>
                <v-list-item-subtitle class="text-wrap">
                  {{item.body}}
                  <span class="caption">{{item.created.substr(0,10)}}</span>
                  <span class="caption">{{item.created.substr(11)}}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn v-if="item.status === 0"
                      @click.stop="onMarkAsRead(item.uid, item)"
                      icon>
                  <v-icon>mdi-email-open</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="'notification_item_divider' + index"/>
          </template>
        </v-list-item-group>
        <v-list-item v-if="itemsCount > itemsPerPage">
          <v-list-item-content>
            <v-layout wrap>
              <v-flex md1 v-if="!$root.isMobile"></v-flex>
              <v-flex xs12 md10>
                <Pagination
                        :key="'Pagination' + page"
                        :value="page"
                        :itemsCount="itemsCount"
                        :itemsPerPage="itemsPerPage"
                        @page-changed="pageChanged"/>
              </v-flex>
              <v-flex xs12 md1 v-if="!$root.isMobile">
              </v-flex>
            </v-layout>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
    <v-dialog v-model="dialog" scrollable fullscreen persistent hide-overlay transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar dark :color="$root.currentTheme.primary" dense fixed style="flex:0;">
          <v-toolbar-items>
            <v-btn icon dark @click="() => { dialog = false; }"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar-items>
          <v-toolbar-title class="text-no-wrap">
            {{$t('Notifications.CreateNewTitle')}}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" @input="formInput">
            <v-layout wrap>
              <v-flex xs12>
                <v-radio-group v-model="sendDestinationType" row left @change="onTypeChanged">
                  <v-radio :label="$root.t('Notifications.SendAll')" value="all"></v-radio>
                  <v-radio :label="$root.t('Notifications.SendByRole')" value="by_role"></v-radio>
                  <v-radio :label="$root.t('Notifications.SendByUsers')" value="by_user"></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex xs12 md6 lg2 class="pt-2 pb-2" v-if="sendDestinationType === 'by_role'">
                <v-select
                  data-cy="roleSelect"
                  id="role_select_id"
                  height="50px"
                  v-model="selectedRole"
                  :rules="$root.fieldRequiredRules"
                  :items="roleItems"
                  :label="$t('CreateUser.SelectRole')"
                  outlined dense hide-details
                ></v-select>
              </v-flex>
              <v-flex xs12 class="pt-2 pb-2" v-if="sendDestinationType === 'by_user'">
                <SelectUser
                  excludeRole="Broker"
                  :multiple="true"
                  :hidePrependIcon="true"
                  :label="$t('Notifications.SelectUsers')"
                  :rules="$root.fieldRequiredRules"
                  :key="fields.dispatcher ? 'dispatcher'+fields.dispatcher : null"
                  :value="selectedUsers"
                  @change="onUserChanged">
                </SelectUser>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  width="100%"
                  :rules="$root.fieldRequiredRules"
                  :label="$t('Notifications.Title')"
                  v-model="fields.title"
                  dense outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  :rules="$root.fieldRequiredRules"
                  :label="$t('Notifications.Body')"
                  width="100%"
                  outlined dense
                  v-model="fields.body">
                </v-textarea>
              </v-flex>
            </v-layout>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="createNotification()"><v-icon left>mdi-send</v-icon>{{$t('Send')}}</v-btn>
            <v-btn @click="dialog = false;">{{$t('Cancel')}}</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import fieldAccessRightMixin from './../../mixins/fieldAccessRightsMixin';
import notificationsMixin from './../../mixins/notificationsMixin';
import SelectUser from './../../components/SelectUser.vue';
import Pagination from '../../components/Pagination';
import FiltersPanel from '../../components/FiltersPanel';

export default {
  props: ['isDrawer', 'hideFilters', 'hideTitle'],
  components: { SelectUser, Pagination, FiltersPanel },
  mixins: [fieldAccessRightMixin, notificationsMixin],
  data () {
    return {
      valid: true,
      polling: null,
      loading: true,
      awaitingSearch: false,
      error: '',
      dialog: false,
      filtersPanel: 1,
      itemsCount: 0,
      itemsPerPage: 25,
      page: 1,
      search: '',
      options: {},
      fields: {
        title: '',
        body: '',
        userIds: []
      },
      items: [],
      userData: {},
      sendDestinationType: 'all',
      selectedRole: '',
      selectedUsers: [],
      roleItems: [],
      statusFilter: 'All',
      userFilter: 0,
      notificationsFromOthers: false,
      filterUserList: [],
      isFilterInitialized: false,
      filtersKey: new Date().getTime(),
      filtersItems: [
        {
          name: 'statusFilter',
          label: this.$root.t('Notifications.StatusFilter'),
          field: 'All',
          multiple: false,
          list: [
            { id: 'Unread', name: 'Unreaded' },
            { id: 'Read', name: 'Readed' },
            { id: 'All', name: 'All' }
          ]
        },
        {
          name: 'userFilter',
          label: this.$root.t('Notifications.ListUsers'),
          fields: '',
          multiple: true,
          list: [
          ]
        }
      ]
    };
  },
  computed: {
  },
  watch: {
    search () {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.options.page = 1;
          this.page = 1;
          this.fetchData();
          this.awaitingSearch = false;
        }, 1000);
      }
      this.awaitingSearch = true;
    },
    options: {
      handler () {
        let { page, itemsPerPage } = this.options;
        if (!this.isMounted) return;
        if (itemsPerPage) {
          this.$root.setLocalStorageItem('notificationsItemsPerPage', itemsPerPage);
          this.itemsPerPage = itemsPerPage;
        }
        if (page) {
          this.$root.setLocalStorageItem('notificationsPage', page);
        }
        this.fetchData().then(data => {
          this.items = data.items;
          this.itemsCount = data.total;
        });
      },
      deep: true
    }
  },
  mounted () {
    let _r = this.$store.getters['rolesList'];
    this.roleItems = [];
    for (let i = 0; i < _r.length; i++) {
      // Hide owner operators, this role will be switched from driver role
      if (_r[i] === 'Broker') continue;
      this.roleItems.push({ text: this.$root.t('Roles.' + _r[i]), value: _r[i] });
    }

    this.$root.setLastListPath(this.$route.path);
    this.$root.contextCreate = () => {
      this.dialog = true;
      this.fields = {
        title: '',
        body: '',
        userIds: []
      }
    };

    // set saved items per page if exist
    if (this.$root.getLocalStorageItem('notificationsItemsPerPage')) {
      this.itemsPerPage = parseInt(this.$root.getLocalStorageItem('notificationsItemsPerPage'));
    }

    // set saved page if exist
    if (this.$root.getLocalStorageItem('notificationsPage')) {
        this.options.page = parseInt(this.$root.getLocalStorageItem('notificationsPage'));
        this.page = parseInt(this.$root.getLocalStorageItem('notificationsPage'));
    }

    this.fetchData().then(() => {
      this.isFilterInitialized = true;
    });

    this.loading = false;
    this.polling = setInterval(() => {
      // Do not call interval methods if page not visible
      if (!this.$store.getters.isPageVisible) return;
      this.fetchData(false).then(() => {
        this.isFilterInitialized = true;
      });
    }, this.$store.getters.pullIntervalList);
  },
  beforeDestroy () {
    clearInterval(this.polling);
  },
  methods: {
    formInput (event) {
      this.$store.commit('setIsCurrentFormUpdated', true);
    },
    onFilterChanged (e) {
      let i = 0;
      let field = '';
      let value = '';
      const filtersFields = Object.keys(e);
      for (i = 0; i < filtersFields.length; i++) {
        field = filtersFields[i];
        value = Object.prototype.hasOwnProperty.call(e, field) ? e[field] : null;
        if (value !== '') {
          this[field] = Array.isArray(value) ? value.join(',') : value;
        }
      }
      if (this.isFilterInitialized) {
        this.options.page = 1;
        this.page = 1;
        this.fetchData();
      }

      this.isFilterInitialized = true;
    },
    // statusFilterChanged () {
    //   if (this.statusFilter !== 'All') {
    //     this.$root.setLocalStorageItem('notificationsFilterStatus', this.statusFilter);
    //   } else {
    //     this.$root.removeFromLocalStorageItem('notificationsFilterStatus');
    //   }
    //   this.fetchData();
    // },
    // filterUserChanged () {
    //   if (this.userFilter !== 0) {
    //     this.$root.setLocalStorageItem('notificationsFilterUser', this.userFilter);
    //   } else {
    //     this.$root.removeFromLocalStorageItem('notificationsFilterUser');
    //   }
    //   this.fetchData();
    // },
    onOpen (id, url, item, uid) {
      let routeUrl = url.substring(this.$store.getters.appHost.length - 1);
      // console.log(id, url, item, uid);
      // console.log(routeUrl);
      // console.log(this.$store.getters.appHost);
      if (this.$store.getters.role === 'Driver' || this.$store.getters.role === 'DriverOwnerOperator') {
        if (routeUrl.includes('/loads/page/')) {
          routeUrl = routeUrl.replace('page', 'driverPage');
        }
        this.markAsRead(uid, this.$store.getters.currentUser.id);
        this.$root.route(routeUrl);
        if (this.isDrawer) {
          this.$emit('close');
        }
        return;
      }
      this.markAsRead(uid, this.$store.getters.currentUser.id);
      this.$root.route(url.substring(this.$store.getters.appHost.length - 1));
      if (this.isDrawer) {
        this.$emit('close');
      }
    },
    onMarkAsRead (uid, item) {
      this.markAsRead(uid, this.$store.getters.currentUser.id);
      this.fetchData();
    },
    onMarkAllAsRead () {
      this.markAllAsRead().then(() => {
        this.fetchData();
        this.$root.statusUpdate();

        if (this.isDrawer) {
          this.$emit('close');
        }
      });
    },
    onTypeChanged () {
      this.selectedUsers = [];
      this.selectedRole = '';
    },
    pageChanged (event) {
      this.page = event;
      this.fetchData();
    },
    onUserChanged (value, doNotUpdateData = false) {
      this.selectedUsers = value;
    },
    createNotification () {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.addNotification(this.fields.title, {
                            body: this.fields.body
                          },
                          this.selectedUsers,
                          this.selectedRole);
      this.dialog = false;
      this.fetchData();
    },
    updateNotificationById (id) {

    },
    fetchData (showLoading = true) {
      if (!this.isNotificationsViewAllowed) {
        this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
        this.loading = false;
        return;
      }

      return new Promise((resolve, reject) => {
        if (showLoading) this.loading = true;
        let isFilterDataUpdated = false;
        this.notificationsList(this.page - 1, this.itemsPerPage, this.$store.getters.pushNotificationsEndpointUrl, this.statusFilter, this.userFilter, this.search).then((response) => {
          let i = 0;
          let _item = {};
          let _itemOptions = {};
          this.items = [];
          if (response.status === 'ok') {
            this.notificationsFromOthers = response.notificationsFromOthers;
            this.userData = response.userData;
            this.itemsCount = parseInt(response.itemsCount);
            for (i = 0; i < response.results.length; i++) {
              _itemOptions = JSON.parse(response.results[i].options);
              _item = {
                id: response.results[i].id,
                created: response.results[i].createdPST,
                userId: response.results[i].userId,
                userRole: response.results[i].userRole,
                title: response.results[i].title,
                body: _itemOptions.body,
                status: response.results[i].status,
                url: response.results[i].url,
                uid: response.results[i].uid
              };
              this.items.push(_item);
            }
          }

          isFilterDataUpdated = false;
          for (let f = 0; f < this.filtersItems.length; f++) {
            if (this.filtersItems[f].name === 'userFilter') {
              if (!this.filtersItems[f].list || this.filtersItems[f].list.length === 0) {
                let usersRows = [];
                for (let userKey in response.userData) {
                  usersRows.push({
                    id: response.userData[userKey].id,
                    name: response.userData[userKey].firstName + ' ' + response.userData[userKey].lastName
                  });
                }
                this.$set(this.filtersItems[f], 'list', usersRows);
                isFilterDataUpdated = true;
              }
            }

          }
          if (isFilterDataUpdated) {
            this.isFilterInitialized = false;
            this.filtersKey = new Date().getTime();
          }

          this.loading = false;
          resolve();
        });
      });
    }
  }
};
</script>
