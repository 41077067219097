<template>
  <v-container>
    <v-row align="center" justify="center" width="100%">
      <v-card light flat style="width: 21cm;">
        <v-card-text>
          <v-row>
            <v-col xs="12" class="mt-1 pa-0">
              <v-row>
                <v-col xs="6" class="text-left pr-0 pl-4">
                  <img responsive width="80%" :src="logoPath"/>
                  <br/>
                  <div class="text-left" style="width: 80%;">
                    <span v-html="contactInfo ? $root.newLineToBR(contactInfo) : null"></span>
                  </div>
                </v-col>
                <v-col xs="6" class="pt-5">
                  <div style="border-left: 4px #999 solid" class="pl-2">
                    <v-simple-table dense class="mb-5">
                      <template v-slot:default>
                        <tbody>
                        <tr>
                          <th class="text-left" colspan="2">
                            <h1>Totals</h1>
                          </th>
                        </tr>
                        <tr>
                          <td class="text-left">Loads Count</td>
                          <td class="text-left">{{parseInt(data.loadsCount).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Total Initial Loads Rate</td>
                          <td class="text-left">${{parseFloat(data.totalLoadsRate).round(2).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Total Additional Payments</td>
                          <td class="text-left">${{parseFloat(data.totalAdditionalPayments).round(2).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Total Loads Rate</td>
                          <td class="text-left">${{parseFloat(data.totalLoadsRateWithAdditionalPayments).round(2).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Total Payment From Loads Rate</td>
                          <td class="text-left">${{parseFloat(data.totalDispatcherPaymentFromRate).round(2).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Total Payment From Bonus</td>
                          <td class="text-left">${{parseFloat(data.totalDispatcherPaymentFromBonus).round(2).toLocaleString()}}</td>
                        </tr>
                        <tr>
                          <td class="text-left">Total Salary</td>
                          <td class="text-left">${{parseFloat(data.totalDispatcherRate).round(2).toLocaleString()}}</td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-col>
              </v-row>
              <h3 style="line-height: 32px;" class="text-center" v-html="title"></h3>
              <v-simple-table dense
                              style="border: 1px solid #000; width:100%;background: transparent;"
                              class="text-left">
                <tbody>
                <tr style="background:#0090D3;color:#fff;page-break-inside: avoid;">
                  <th class="report_font_size text-center">#</th>
                  <th class="report_font_size text-center" style="width: 100px;">Started<br/>Ended</th>
                  <th class="report_font_size text-center">Locations</th>
                  <th class="report_font_size text-center">Trucks<br/>Drivers</th>
                  <th class="report_font_size text-center">Load Number<br/>Load UID</th>
                  <th class="report_font_size text-center">Load Rate</th>
                  <th class="report_font_size text-center">From Rate</th>
                  <th class="report_font_size text-center">From Bonus</th>
                  <th class="report_font_size text-center">Salary</th>
                </tr>
                <tr v-for="item in data.loads" style="page-break-inside: avoid;" :key="'entry'+item.id">
                  <td class="report_font_size">#{{item.id}}<br/>{{item.type}}</td>
                  <td class="report_font_size">
                    <div class="text-no-wrap">{{item.startedPST}}</div>
                    <div class="text-no-wrap">{{item.endedPST}}</div>
                  </td>
                  <td class="report_font_size">
                    <div class="text-no-wrap" style="max-width: 150px; text-overflow: ellipsis; overflow: hidden;" v-if="item.name">
                      {{item.name.split('--')[0]}}
                    </div>
                    <div class="text-no-wrap" style="max-width: 150px; text-overflow: ellipsis; overflow: hidden;" v-if="item.name">
                      {{item.name.split('--')[1]}}
                    </div>
                  </td>
                  <td class="report_font_size">
                    <template v-for="(truck, index) in item.trucks">
                      <div class="text-no-wrap" :key="'truck'+item.id+'_'+index">
                        {{truck.truckName}}&nbsp;
                        <template v-for="(driver) in truck.drivers">
                          {{driver.driverInitials}}&nbsp;
                        </template>
                      </div>
                    </template>
                  </td>
                  <td class="report_font_size">
                    {{item.loadNumber}}
                    <br/>
                    {{item.uid}}
                  </td>
                  <td class="report_font_size text-center">
                    <template v-if="item.additionalPayments !== 0">
                      {{ parseFloat(item.Rate.toFixed(2)) }}
                      {{ item.RTLRate ? '+' + parseFloat(item.RTLRate.toFixed(2)).toLocaleString() : '' }}
                      {{ item.additionalPayments > 0?'+':'-'}}{{parseFloat(item.additionalPayments.toFixed(2)).toLocaleString()}}=
                      <br/>
                      ${{getTotalRate(item)}}
                    </template>
                    <template v-else>
                      ${{getTotalRate(item)}}
                    </template>
                  </td>
                  <td class="report_font_size text-center">
                    ${{parseFloat(item.dispatcherRate.toFixed(2)).toLocaleString()}}
                    <br/>
                    <span>{{parseFloat(item.dispatchersRatePercent).toFixed(2)}}%</span>
                  </td>
                  <td class="report_font_size text-center">
                    ${{parseFloat(item.dispatcherRateFromBonus.toFixed(2)).toLocaleString()}}
                    <br/>
                    <span>{{parseFloat(item.dispatcherPaymentPercentFromBonus).toFixed(2)}}%</span>
                  </td>

                  <td class="report_font_size text-center" v-if="item.isPaid">
                    <v-chip color="success" x-small>${{parseFloat(item.dispatcherRateTotal.toFixed(2)).toLocaleString()}}&nbsp;{{$t('Reports.Paid')}}</v-chip>
                    <br/>
                    <div class="text-no-wrap">{{item.paidDateTime}}</div>
                  </td>
                  <td v-else class="report_font_size text-center">
                    ${{parseFloat(item.dispatcherRateTotal.toFixed(2)).toLocaleString()}}
                    <template v-if="item.finished === 2"><br/><v-chip color="warning" x-small>TONU</v-chip></template>
                  </td>

                </tr>
                </tbody>
              </v-simple-table>
              <template v-if="reportData && reportData.hasOwnProperty('footerText')">
                {{reportData.footerText}}
              </template>
              <template v-else>
                Report Created at {{currentDateTime}}&nbsp;by&nbsp;{{$store.getters.currentUserName}}
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>

import reportsMixin from '../../mixins/reportsMixin.js';

export default {
  name: 'DispatchersReportTemplate',
  props: ['reportData', 'entries', 'print', 'from', 'to', 'dispatcherName', 'totals', 'currentDateTime', 'companyId'],
  mixins: [reportsMixin],
  components: {},
  data: function () {
    return {
      byStatusCounts: {},
      publicPath: process.env.BASE_URL,
      contactInfo: '',
      title: '',
      logoPath: '',
      data: {}
    };
  },
  computed: {
  },
  mounted () {
    this.$nextTick(() => {
      if (this.entries) {
        this.$set(this, 'data', this.totals);
        this.$set(this.data, 'loads', this.entries);
      } else {
        this.$set(this, 'data', this.reportData);
        //console.log(this.data)
      }
      this.$root.getInvoiceLogoPath(this.companyId).then(path => {
        this.logoPath = path;
      });
      this.$root.getGlobalSettingsField('invoiceContactInfo', this.companyId).then((response) => {
        if (response.status === 'ok') {
          this.contactInfo = response.result;
        }
      });
      this.$forceUpdate();
      this.generateReportTitle();
    });
    document.title = this.title;
  },
  methods: {
    generateReportTitle () {
      const _dispatcherName = this.dispatcherName ? this.dispatcherName : 'All Dispatchers';
      this.title = _dispatcherName + '&nbsp;' + this.$root.t('Report') + ' ' + (parseInt(this.$route.params.report_number) > 0 ? '#' + this.$route.params.report_number : '') + ': ';
      this.$root.currentReportFileName = _dispatcherName.replace(' ', '_') + '_' + this.$root.t('Report') + (parseInt(this.$route.params.report_number) > 0 ? '_' + this.$route.params.report_number : '') + '_';

      if (this.data.loads && this.data.loads.length > 1) {
        this.title += this.data.loads[0].startedPST.substring(0, 10) + ' - ' + this.data.loads[this.data.loads.length - 1].startedPST.substring(0, 10);
        this.$root.currentReportFileName += this.$root.parseDate(this.data.loads[0].startedPST.substring(0, 10)) + '-' + this.$root.parseDate(this.data.loads[this.data.loads.length - 1].endedPST.substring(0, 10));
      }
      if (this.data.loads && this.data.loads.length === 1) {
        this.title += this.data.loads[0].startedPST.substring(0, 10);
        this.$root.currentReportFileName += this.$root.parseDate(this.data.loads[0].startedPST.substring(0, 10));
      }
    }
  }
};
</script>
