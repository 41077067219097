export default {
  data: function () {
  },
  methods: {
    getMailsList (groupByThread = 1, page = 0, itemsPerPage = 10, showOnlySelfMails = 1, filterUser = 0, filterStatus = 'All', search = '') {
      return this.$root.get('/api/mail/list/' + groupByThread + '/' + page + '/' + itemsPerPage + '/' + showOnlySelfMails + '/' + filterUser + '/' + filterStatus + '/' + search);
    },
    getMailsListByBidId (id) {
      return this.$root.get('/api/mail/listByItem/bid/' + id);
    },
    getMailsListByLoadId (id) {
      return this.$root.get('/api/mail/listByItem/load/' + id);
    },
    getMailsListByInvoiceId (id) {
      return this.$root.get('/api/mail/listByItem/invoice/' + id);
    },
    getMailsListByThreadId (threadId) {
      return this.$root.get('/api/mail/listByThread/' + threadId);
    },
    getMailsListByParentId (parentId) {
      return this.$root.get('/api/mail/listByParent/' + parentId);
    },
    forwardMail (to, messageId) {
      return this.$root.post('/api/mail/forward', {
        to: to,
        messageId: messageId
      });
    },
    forwardThread (to, threadId, loadId = 0) {
      return this.$root.post('/api/mail/forward/' + threadId, {
        loadId: loadId,
        to: to
      });
    },
    sendMail (from, to, cc, subject, body, bid_id = 0, load_id = 0, invoice_id = 0, gmail_signature = false, user_id = false) {
      return this.$root.post('/api/mail/send', {
        userId: user_id,
        from: from,
        to: to,
        cc: cc,
        subject: subject,
        body: body,
        bid_id: bid_id,
        load_id: load_id,
        invoice_id: invoice_id,
        append_signature: gmail_signature
      });
    },
    sendReply (inReplyMsgId, from, to, cc, subject, body, attArray = [], gmailSignature = false) {
      return this.$root.post('/api/mail/sendReply', {
        in_reply_msg_id: inReplyMsgId,
        from: from,
        to: to,
        cc: cc,
        subject: subject,
        body: body,
        attArray: attArray,
        append_signature: gmailSignature
      });
    },
    sendWithAtt (from, to, cc, subject, body, attArray, bid_id = 0, load_id = 0, invoice_id = 0, gmail_signature = false) {
      return this.$root.post('/api/mail/sendWithAtt', {
        from: from,
        to: to,
        cc: cc,
        subject: subject,
        body: body,
        attArray: attArray,
        bid_id: bid_id,
        load_id: load_id,
        invoice_id: invoice_id,
        append_signature: gmail_signature
      });
    },
    setMessageAsRead (id) {
      return this.$root.post('/api/mail/markAsRead', { id: id });
    },
    setMessageAsUnRead (id) {
      return this.$root.post('/api/mail/markAsUnRead', { id: id });
    },
    getMailById (id) {
      return this.$root.get('/api/mail/getMailById/' + id);
    },
    recipientsSearch (val) {
      return this.$root.get('/api/mail/getRecipientsData/' + val);
    }
  }
};
