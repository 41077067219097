export default {
  data () {
  },
  methods: {
    getLocationMapIcon (location) {
      let result = {};
      let color = this.$root.getLocationColor(location, location.number);
      let strokeWith = 1.0;
      let path = 'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z';
      if (location.approvedByDispatcherTS > 0) {
        path = 'M12,2C15.86,2 19,5.14 19,9C19,14.25 12,22 12,22C12,22 5,14.25 5,9C5,5.14 8.14,2 12,2M10.47,14L17,7.41L15.6,6L10.47,11.18L8.4,9.09L7,10.5L10.47,14Z';
        // color = '#4caf50';
        // strokeWith = 2.0;
      }
      result = {
        path: path,
        fillOpacity: 0.8,
        scale: 2.0,
        strokeWeight: strokeWith,
        strokeColor: '#fff',
        fillColor: '#ff5252',
        anchor: { x: 10, y: 10 }
        // anchor: {x:-10,y:-10}
      };

      result.fillColor = color;

      return result;
    },
    getAddressByCoords (lat, lng) {
      return new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({
          'latLng': new google.maps.LatLng(lat, lng)
        }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[1]) {
              resolve(results[1]);
            } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
              reject('No results found');
            } else {
              reject('No results found');
            }
          } else {
            reject(status);
          }
        });
      });
    },
    getCoordsByAddress (address) {
      return new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({
          'address': address
        }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              resolve(results[0]);
            } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
              reject('No results found');
            } else {
              reject('No results found');
            }
          } else {
            reject(status);
          }
        });
      });
    },
    calcDistanceOSRM (waypoints, map = null) {
      return new Promise((resolve, reject) => {
        this.$root.post('/api/maps/getRoute', {
          waypoints: waypoints
        }).then(response => {
          if (response.status === 'ok') {
            resolve(response.result);
          }
        });
      });
    },
    calcDistance (waypoints, map = null, showTraffic = false, trafficModel = null, showCustomIcons = false) {
      const isGoogleMaps = parseInt(this.$store.getters.globalSettingsFields.useGoogleMaps) == 1;
      // Google maps implementation
      let i = 0;
      let j = 0;
      const max = 10; // 25 - 1;
      let parts = [];
      let waypts = [];
      let _waypoints = [];
      let request = {};
      let trafficModelValue = google.maps.TrafficModel.BEST_GUESS;

      if (!isGoogleMaps) {
        for (i = 0; i < waypoints.length; i++) {
          _waypoints.push([
            waypoints[i].lng,
            waypoints[i].lat,
          ]);
        }
        return this.calcDistanceOSRM(_waypoints, map);
      }

      return new Promise((resolve, reject) => {
        if (waypoints.length < 2) {
          resolve('Not enough waypoints');
          return;
        }

        var directionsService = new google.maps.DirectionsService();

        if (showTraffic) {
          var trafficLayer = new google.maps.TrafficLayer();
          trafficLayer.setMap(map);
        }
        if (waypoints.length >= 2) {
          for (i = 0; i < waypoints.length; i++) {
            waypts.push({ location: waypoints[i], stopover: true });
            if (showCustomIcons) {
              new google.maps.Marker({
                icon: this.getLocationMapIcon(waypoints[i]),
                position: waypoints[i],
                map: map,
                title: waypoints[i].name
              });
            }
          }
        }

        if (trafficModel === 'pessimistic') {
          trafficModelValue = google.maps.TrafficModel.PESSIMISTIC;
        }
        if (trafficModel === 'optimistic') {
          trafficModelValue = google.maps.TrafficModel.OPTIMISTIC;
        }

        for (i = 0, parts = []; i < waypts.length; i = i + max) {
          parts.push(waypts.slice(i, i + max + 1));
        }

        for (i = 0; i < parts.length; i++) {
          if (parseInt(parts[i][0].location.lat) === 0 || parseInt(parts[i][0].location.lng) === 0) continue;
          waypoints = [];
          for (j = 1; j < parts[i].length - 1; j++) {
            _waypoints.push(parts[i][j]);
          }

          request = {
            origin: parts[i][0].location,
            destination: parts[i][parts[i].length - 1].location,
            waypoints: _waypoints,
            optimizeWaypoints: false,
            travelMode: google.maps.TravelMode.DRIVING
          };

          if (trafficModel) {
            /* request['drivingOptions'] = {
              departureTime: new Date(Date.now()),
              trafficModel: trafficModelValue
            }; */
          }

          directionsService.route(request, (response, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              if (map) {
                let directionsDisplay = new google.maps.DirectionsRenderer();
                directionsDisplay.setDirections(response);
                directionsDisplay.setMap(map);
                if (showCustomIcons) {
                  directionsDisplay.setOptions({
                    suppressMarkers: true,
                    polylineOptions: {
                      strokeOpacity: 0,
                      icons: [
                        {
                          icon: {
                            path: 'M 0,-1 0,1',
                            strokeOpacity: 1,
                            scale: 4
                          },
                          offset: '0',
                          repeat: '20px'
                        }
                      ]
                    }
                  });
                }
              }
              //console.log(response);
              resolve(response);
            } else {
              reject(status);
            }
          });
        }
      });
    },
    drawRouteByCoords (waypoints, map) {
      const arrowSymbol = {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        strokeOpacity: 0.8,
        fillOpacity: 1,
        fillColor: '#1B5E20',
        strokeColor: '#000000',
        strokeWeight: 2,
        scale: 3
      };
      let flightPath = new google.maps.Polyline({
        path: waypoints,
        geodesic: true,
        zIndex: 4,
        strokeColor: '#4caf50',
        strokeOpacity: 0.8,
        strokeWeight: 8,
        icons: [{
          icon: arrowSymbol,
          offset: '100%',
          repeat: '30px'
        }]
      });

      flightPath.setMap(map);
    }
  }
};
