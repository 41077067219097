<template>
  <v-sheet style="text-align: left;" elevation="0" class="mb-2 text-center">
    <!--<v-expansion-panels v-model="panelsModel" v-if="filesObject.length > 0" multiple>
      <v-expansion-panel v-for="(item, index) in filesObject" :key="index">
        <v-expansion-panel-header>{{label ? label : $t('User.Fields.'+fieldName)}}: {{filesObject[index].name}} </v-expansion-panel-header>
        <v-expansion-panel-content>
          <FileUpload
            :pickOnMount="filesObject[index].hash === '' ? true : false"
            miltifileupload="true"
            :disabled="readonly"
            :downloadAllowed="downloadAllowed"
            :label="$t('CreateUser.PressToSelectFile')"
            :url="filesUrls[index]"
            :field="filesObject[index].hash"
            :filename="filesObject[index].name"
            :loading="isFieldLoading(fieldName + index)"
            @change="fileUpload($event, index)"
            @remove="fileRemove(index)"
            @removeFile="removeDocument(index)"></FileUpload>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>-->
    <v-btn small v-if="filesObject.length === 0 && sampleDownloadUrl" :href="sampleDownloadUrl" target="_blank">
       <v-icon left :small="!$root.isMobile" :left="!$root.isMobile">mdi-download</v-icon>
       <template v-if="!$root.isMobile">{{ $t('DownloadSample') }}</template>
    </v-btn>

    <template v-if="!maxFilesCount || filesObject.length < maxFilesCount">
      <v-btn v-if="(!readonly && filesObject.length === 0) || storeUID"
            @click="addDocument"
            color="success"
            small
            :width="addBtnWidth ? addBtnWidth : null"
            class="ma-2">
        <v-icon small left>mdi-plus</v-icon>{{label}}
      </v-btn>
    </template>
    <!--<v-overlay :value="reUploading" :absolute="true">
      {{$t('Uploading')}}
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>-->

    <!--<v-list v-if="notLoadedFiles.length > 0" dense>
      <v-subheader>{{$t('Loads.NotLoadedFiles')}}</v-subheader>
      <template v-for="(item, index) in notLoadedFiles">
        <v-list-item :key="'notLoadedFiles'+index">
          <v-list-item-avatar tile>
            <v-img v-if="item.type.indexOf('image') >= 0"
                   :src="item.data"></v-img>
            <v-icon v-else-if="item.type.indexOf('pdf') >= 0">mdi-file-pdf-outline</v-icon>
            <v-icon v-else>mdi-file</v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="text-left">
            <v-list-item-title>
              {{item.name}}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{$root.getDateTimeStringFromTimestamp(item.timestamp)}}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="reUploadFile(item, index, item.timestamp)"
                   :loading="reUploading">
              <v-icon :left="!$root.isMobile">mdi-sync</v-icon>
              <template v-if="!$root.isMobile">{{$t('ReUploadFile')}}</template>
            </v-btn>
          </v-list-item-action>
          &lt;!&ndash;<img contain style="width:auto; max-height:400px;" v-img:images alt="" :src="item.data"/>&ndash;&gt;
        </v-list-item>
      </template>
    </v-list>-->
    <v-carousel  v-if="filesObject.length > 0" :light="!$vuetify.theme.dark"
                v-model="panelsModel"
                :key="carouselKey"
                delimiter-icon="mdi-file-document"
                :height="height ? height : ($root.isMobile ? '500px' : '800px')"
                :show-arrows="false"
                :show-arrows-on-hover="false">
      <template v-for="(item, index) in filesObject" >
        <v-carousel-item :key="index">
          <!--<b>{{doc.name}}</b> - {{doc.filename}}-->
          <v-sheet height="100%" tile>
            {{label ? label : $t('User.Fields.'+fieldName)}}:&nbsp;{{filesObject[index].name}}&nbsp;{{$t('At')}}&nbsp;<i>{{filesObject[index].timestamp ? $root.getDateTimeStringFromTimestamp(filesObject[index].timestamp / 1000) : ''}}</i>
            <span v-if="index < filesObject.length && filesObject[index].user">
              {{$t('by')}}&nbsp;
              <span v-if="noLinkToUser">{{filesObject[index].userFullName}}</span>
              <a v-else @click="$root.route('/user/page/'+filesObject[index].user)">{{filesObject[index].userFullName}}</a>
            </span>
            <FileUpload
              :pickOnMount="!filesUrls[index] ? true : false"
              :key="filesUrls[index]"
              miltifileupload="true"
              :disabled="readonly"
              :deleteAllowed="deleteAllowed"
              :accept="accept"
              :height="height ? (parseInt(height)) : ($root.isMobile ? 450 : 750)"
              :downloadAllowed="downloadAllowed"
              :send-via-email-allowed="sendViaEmailAllowed"
              :label="$t('CreateUser.PressToSelectFile')"
              :addNewBtnLabel="label"
              :readonly="readonly"
              :url="filesData[index] ? filesData[index] : filesUrls[index]"
              :downloadUrl="downloadUrls[index]"
              :field="filesObject[index].hash"
              :filename="filesObject[index].name"
              :recipientsList="recipientsList"
              :loading="isFieldLoading(fieldName + index)"
              :isImageEditing="isImageEditing"
              :uploadFolder="uploadFolder"
              :hideAddBtn="maxFilesCount && filesObject.length >= maxFilesCount"
              :sampleDownloadUrl="sampleDownloadUrl"
              @change="fileUpload($event, index, true)"
              @changeAndSave="fileUpload($event, index, true)"
              @add-and-save="addAndUpload($event, index, true)"
              @remove="fileRemove(index)"
              @removeFile="removeDocument(index)"
              @add-new="addDocument()"
            />
            <br/><br/>
          </v-sheet>
        </v-carousel-item>
      </template>
    </v-carousel>
    <template v-if="showSizeWarning">
      <v-alert color="success" class="white--text" v-if="totalSize <= 20*1024*1024">
        Total FIles Size: {{ $root.fileSizeString(totalSize, true)}}
      </v-alert>
      <v-alert color="error" class="white--text" v-if="totalSize > 20*1024*1024">
        Total FIles Size: {{ $root.fileSizeString(totalSize, true)}} This is more the you can send in one email.
      </v-alert>
    </template>

  </v-sheet>
</template>

<script>
import FileUpload from './FileUpload.vue';
import fieldLoadingMixin from '../mixins/fieldLoadingMixin';

export default {
  components: { FileUpload },
  mixins: [fieldLoadingMixin],
  props: ['field', 'fieldName', 'readonly', 'uploadFolder', 'downloadAllowed', 'sendViaEmailAllowed', 'label', 'gallery',
          'recipientsList', 'accept', 'deleteAllowed', 'height', 'addBtnWidth', 'storeUID', 'isImageEditing', 'noLinkToUser',
          'showSizeWarning', 'maxFilesCount', 'globalSettingSampleName', 'globalSettingSampleFolder'],
  data: () => ({
    panelsModel: 0,
    filesUrls: [],
    filesData: [],
    downloadUrls: [],
    filesObject: [],
    notLoadedFiles: [],
    gallerySelected: 0,
    carouselKey: new Date().getTime(),
    reUploading: false,
    totalSize: 0,
    sampleDownloadUrl: null
  }),
  watch: {
    field: function (newfield, oldfield) {
      let k = 0;
      this.filesObject = this.field ? JSON.parse(this.field) : [];
      for (k = 0; k < this.filesObject.length; k++) {
        if (this.filesObject[k].uploadFolder) {
          this.filesUrls[k] = this.$root.getImageUrl(this.filesObject[k].uploadFolder, this.filesObject[k].hash);
          this.downloadUrls[k] = this.$root.getImageUrl(this.filesObject[k].uploadFolder, this.filesObject[k].hash, this.filesObject[k].name);
        } else {
          this.filesUrls[k] = this.$root.getImageUrl(this.uploadFolder, this.filesObject[k].hash);
          this.downloadUrls[k] = this.$root.getImageUrl(this.uploadFolder, this.filesObject[k].hash, this.filesObject[k].name);
        }
      }
    }
  },
  mounted () {
    let k = 0;
    this.filesObject = this.field ? JSON.parse(this.field) : [];
    for (k = 0; k < this.filesObject.length; k++) {
      if (this.showSizeWarning) {
        if (this.filesObject[k].hasOwnProperty('hash')) {
          this.$root.getDocumentByHash(this.filesObject[k].hash).then(response => {
            if (response.status === 'ok') {
              if (response.result.hasOwnProperty('size')) {
                this.totalSize += response.result.size;
              }
            }
          });
        }
      }

      if (this.filesObject[k].uploadFolder) {
        this.filesUrls[k] = this.$root.getImageUrl(this.filesObject[k].uploadFolder, this.filesObject[k].hash);
        this.downloadUrls[k] = this.$root.getImageUrl(this.filesObject[k].uploadFolder, this.filesObject[k].hash, this.filesObject[k].name);
      } else {
        this.filesUrls[k] = this.$root.getImageUrl(this.uploadFolder, this.filesObject[k].hash);
        this.downloadUrls[k] = this.$root.getImageUrl(this.uploadFolder, this.filesObject[k].hash, this.filesObject[k].name);
      }

      if (this.filesObject[k].user > 0) {
        let _k = k;
        this.$root.loadUserFullName(this.filesObject[k].user).then((response) => {
          this.filesObject[_k].userFullName = response.result;
          this.$forceUpdate();
        });
      }
    }

    if (this.globalSettingSampleName && this.globalSettingSampleFolder) {
      this.$root.getGlobalSettingsField(this.globalSettingSampleName).then((response) => {
      if (response.status === 'ok' && response.result) {
        var sampleFiles = JSON.parse(response.result);
        if (sampleFiles && sampleFiles.length > 0) {
          this.sampleDownloadUrl = this.$root.getImageUrl(this.globalSettingSampleFolder, sampleFiles[0].hash, sampleFiles[0].name);
        }
      }
    });
    }

    /* this.$root.retrieveDataFromIndexedDB('driverAppDB', 'filesUploads', this.storeUID + '_Count').then(countVal => {
      const _countVal = countVal ? parseInt(countVal) : 0;
      for (k = 0; k < _countVal + 50; k++) {
        this.$root.retrieveDataFromIndexedDB('driverAppDB', 'filesUploads', this.storeUID + '_' + k).then(fileData => {
          if (fileData) {
            this.notLoadedFiles.push((JSON.parse(fileData)));
          }
        });
      }
    }); */
  },
  methods: {
    addDocument () {
      this.filesObject.push({ name: '', hash: '', timestamp: 0, user: 0 });

      // this.panelsModel.push(this.filesObject.length - 1);
      this.panelsModel = this.filesObject.length - 1;
      this.$nextTick(() => {
        // this.$refs.files[this.filesObject.length - 1].pickFile();
      });
    },
    addAndUpload (files, startIndex, triggerSaveEvent = true, timestamp = 0) {
      //console.warn(files);
      if (files.length > 0) {
        this.filesObject.push({
          name: files[0].name,
          hash: '',
          timestamp: 0,
          user: this.$store.getters.currentUser.id
        });
        this.panelsModel = this.filesObject.length - 1;
        this.$nextTick(() => {
          this.fileUpload(files, startIndex + 1, triggerSaveEvent, timestamp);
        });
      }
    },
    removeDocument (index) {
      this.filesObject.splice(index, 1);
      this.panelsModel = 0;
      this.carouselKey = new Date().getTime();
      this.$emit('change', JSON.stringify(this.filesObject), true);
    },
    fileRemove (index) {
      this.filesUrls.splice(index, 1);
      this.filesData.splice(index, 1);
      this.downloadUrls.splice(index, 1);
      this.filesObject.splice(index, 1);
      this.panelsModel = 0;
      this.carouselKey = new Date().getTime();
      this.$emit('change', JSON.stringify(this.filesObject), true);
    },
    /* getOrientation (file, callback) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var view = new DataView(e.target.result);
        if (view.getUint16(0, false) != 0xFFD8) {
          return callback(-2);
        }
        var length = view.byteLength; var offset = 2;
        while (offset < length) {
          if (view.getUint16(offset + 2, false) <= 8) return callback(-1);
          var marker = view.getUint16(offset, false);
          offset += 2;
          if (marker == 0xFFE1) {
            if (view.getUint32(offset += 2, false) != 0x45786966) {
              return callback(-1);
            }

            var little = view.getUint16(offset += 6, false) == 0x4949;
            offset += view.getUint32(offset + 4, little);
            var tags = view.getUint16(offset, little);
            offset += 2;
            for (var i = 0; i < tags; i++) {
              if (view.getUint16(offset + (i * 12), little) == 0x0112) {
                return callback(view.getUint16(offset + (i * 12) + 8, little));
              }
            }
          } else if ((marker & 0xFF00) != 0xFF00) {
            break;
          } else {
            offset += view.getUint16(offset, false);
          }
        }
        return callback(-1);
      };
      reader.readAsArrayBuffer(file);
    }, */
    makeFileObject (savedFileObject) {
      /* return new Promise(async (resolve) => {
        const res = await fetch(savedFileObject.data);
        const blob = await res.blob();
        resolve(new File([blob], savedFileObject.name, { type: blob.type }));
      }); */
    },
    reUploadFile (savedFileObject, index, timestamp) {
      /* const startIndex = this.filesObject.length;
      this.reUploading = true;
      this.makeFileObject(savedFileObject).then((file) => {
        this.fileUpload([file], startIndex, true, timestamp);
      }); */
    },
    resizeImage (readerEvent) {
      /* return new Promise((resolve, reject) => {
        let image = new Image();
        image.onload = (imageEvent) => {
          let ret = '';
          let canvas = document.createElement('canvas');
          let maxSize = 2000; // 3508 is max size of pixels on 3dpi letter size print
          let width = image.width;
          let height = image.height;

          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }
          canvas.width = width;
          canvas.height = height;
          canvas.getContext('2d').drawImage(image, 0, 0, width, height);
          ret = canvas.toDataURL('image/jpeg');
          // document.removeChild(canvas);
          resolve(ret);
        };
        image.src = readerEvent.target.result;
      }); */
    },
    async removeStoredFileAfterUpload (index) {
      /* let countVal = 0;
      let s = 0;
      // Remove data from indexedDB storage since upload is successful, and update counter
      await this.$root.deleteDataInIndexedDB('driverAppDB', 'filesUploads', this.storeUID + '_' + index);
      countVal = await this.$root.retrieveDataFromIndexedDB('driverAppDB', 'filesUploads', this.storeUID + '_Count');
      countVal = countVal ? parseInt(countVal) : 0;
      countVal -= 1;
      if (countVal <= 0) {
        await this.$root.deleteDataInIndexedDB('driverAppDB', 'filesUploads', this.storeUID + '_Count');
        // Make leftovers cleanup
        for (s = 0; s < 50; s++) {
          await this.$root.deleteDataInIndexedDB('driverAppDB', 'filesUploads', this.storeUID + '_' + s);
        }
      } else {
        await this.$root.storeDataInIndexedDB('driverAppDB', 'filesUploads', this.storeUID + '_Count', countVal);
      } */
    },
    async storeFileBeforeUpload (file, fileData) {
      /* // Resize only images with size more that 2 mb
              if (file.size > 2 * 1024 * 1024 && file.type.indexOf('image') >= 0) {
                fileData = await this.resizeImage(readerEvent);
              } */
      // Save stored count
      /* let _data = '{}';
      let countVal = await this.$root.retrieveDataFromIndexedDB('driverAppDB', 'filesUploads', this.storeUID + '_Count');
      countVal = countVal ? parseInt(countVal) : 0;
      // Save file data
      try {
        _data = JSON.stringify({
          timestamp: new Date().getTime(),
          name: file.name,
          type: file.type,
          user: this.$store.getters.currentUserId,
          uploadFolder: this.uploadFolder,
          fieldName: this.fieldName,
          data: fileData
        });
        await this.$root.storeDataInIndexedDB('driverAppDB', 'filesUploads', this.storeUID + '_' + countVal, _data);
        // Increment counter
        countVal += 1;
        await this.$root.storeDataInIndexedDB('driverAppDB', 'filesUploads', this.storeUID + '_Count', countVal);
      } catch (e) {
        console.error(e);
        /!* this.$dialog.alert(this.$root.t('Errors.FileStoringErrorMsg'), {
          okText: 'Ok'
        }); *!/
      } */
    },
    fileUpload (files, startIndex, triggerSaveEvent = true, timestamp = 0) {
      /* this.getOrientation(files[0], function (orientation) {
        alert('orientation: ' + orientation);
      }); */
      let asyncFunc = async () => {
        let i = 0;
        let index = 0;
        let file = null;

        // this.$emit('on-upload');
        for (i = 0; i < files.length; i++) {
          index = startIndex + i;
          file = files[i];
          // Save file to localStorage if storeUID is provided and file is not reuploading
          /* TODO removed maybe ios bug  if (this.storeUID && !this.reUploading) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (readerEvent) => {
              await this.storeFileBeforeUpload(file, reader.result);
            }
            // reader.onerror = error => reject(error);
          } */

          if (!file) {
            this.filesUrls[index] = '';
            this.filesData[index] = '';
            this.downloadUrls[index] = '';
            continue;
          }
          // Check is file has extention
          if (file.name.lastIndexOf('.') <= 0) {
            // TODO add error message here
            continue;
          }
          this.setLoadingField(this.fieldName + index, true);
          // Load file to corresponding img

          const result = await this.$root.readWithFileReaderAsync(file);
          const responseS3 = await this.$root.uploadUserFileToS3SignedUrlAsync(this.uploadFolder, file);
          if (responseS3.status === 'ok') {
            this.$set(this.filesData, index, result);
            this.$set(this.filesObject, index, {
              name: file.name,
              hash: responseS3.hash,
              user: this.$store.getters.currentUser.id,
              timestamp: new Date().getTime()
            });

            this.$emit('change', JSON.stringify(this.filesObject), triggerSaveEvent, null, responseS3.hash);
          }

          // Upload actual file
          /* let response = await this.$root.uploadUserFileAsync(this.uploadFolder, file);

          if (response.status === 'ok') {
            if (index >= this.filesObject.length) {
              this.addDocument();
            }
            this.filesObject[index].hash = response.data.fileHash ? (response.data.fileHash + ext) : '';
            this.filesObject[index].timestamp = new Date().getTime();
            this.filesObject[index].name = file.name;
            this.filesObject[index].user = this.$store.getters.currentUserId;
            this.setLoadingField(this.fieldName + index, false);
            this.$emit('change', JSON.stringify(this.filesObject), triggerSaveEvent);

            // check is not loaded files available and remove if needed
            /!* TODO removed maybe ios bug if (this.notLoadedFiles.length > 0) {
              this.notLoadedFiles = this.notLoadedFiles.filter(e => e.timestamp !== timestamp);
            }

            await this.removeStoredFileAfterUpload(index); *!/
          } else {
            this.$dialog.alert(this.$root.t('Errors.FileUploadErrorMsg'), {
              okText: 'Ok'
            }).then(() => {
              this.$root.statusUpdate();
              this.$root.refresh();
            });
          }
*/
          /* TODO removed maybe ios bug
         // TODO remove after some time when deprecated values will be removed from drivers
          // Remove data from local storage since upload is successful, and update counter
          this.$root.removeFromLocalStorageItem(this.storeUID + '_' + index);
          let countVal = parseInt(this.$root.getLocalStorageItem(this.storeUID + '_Count'));
          countVal -= 1;
          if (countVal <= 0) {
            this.$root.removeFromLocalStorageItem(this.storeUID + '_Count');
            // Make leftovers cleanup
            for (let s = 0; s < 50; s++) {
              this.$root.removeFromLocalStorageItem(this.storeUID + '_' + s);
            }
          } else {
            this.$root.setLocalStorageItem(this.storeUID + '_Count', countVal);
          } */

          this.setLoadingField(this.fieldName + index, false);
        }
        // this.reUploading = false;
        this.$emit('on-finish');
      };
      asyncFunc();
    }
  }
};
</script>
