<template>
  <span>
    <template v-if="isTooltipBtn">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" small icon :loading="loading" @mouseover="onMouseOverTooltip()" @click="prevLoadData && prevLoadData.prevLoadId ? $root.onShowLoadPreview(prevLoadData.prevLoadId, 0, true) : null">
              <v-icon small>mdi-debug-step-over</v-icon>
            </v-btn>
        </template>
        <span v-if="prevLoadData && prevLoadData.prevLoadId > 0">
          {{$t('Loads.PrevLoadForTruck', {truckName: prevLoadData.truckName})}}:&nbsp;#{{prevLoadData.prevLoadId}}&nbsp;{{prevLoadData.pickupLocationName}} -- {{prevLoadData.deliveryLocationName}}&nbsp;|&nbsp;{{prevLoadData.dispatcherName}}
        </span>
        <span v-else>
          {{ loading ? $t('Loading') : $t('Loads.NoTruckNextLoad')}}
        </span>
      </v-tooltip>
    </template>
    <template v-else-if="prevLoadData && prevLoadData.prevLoadId > 0">
      <v-icon small>mdi-chevron-left</v-icon>{{$t('Loads.PrevLoadForTruck', {truckName: prevLoadData.truckName})}}:&nbsp;<a @click.stop="$root.onShowLoadPreview(prevLoadData.prevLoadId, truckId, true)">
      #{{prevLoadData.prevLoadId}}
      &nbsp;{{prevLoadData.pickupLocationName}} -- {{prevLoadData.deliveryLocationName}}</a>&nbsp;|&nbsp;{{prevLoadData.dispatcherName}}
    </template>
    <template v-else>
      &nbsp;
    </template>
  </span>
</template>

<script>
export default {
  name: 'PrevTruckLoadSnippet',
  props: ['currentLoadId', 'truckId', 'isTooltipBtn'],
  data: function () {
    return {
      loading: false,
      isFetched: false,
      prevLoadData: null
    };
  },
  computed: {
    avatarLetters () {
      if (this.userData.firstName && this.userData.lastName) {
        return this.userData.firstName[0] + this.userData.lastName[0];
      }
      return false;
    },
    fullName () {
      return this.userData.firstName + ' ' + this.userData.lastName;
    }
  },
  mounted () {
    if (!this.isTooltipBtn) {
      this.fetchData();
    }
  },
  methods: {
    onMouseOverTooltip () {
      if (!this.isFetched) {
        this.fetchData();
      }
    },
    fetchData () {
      this.loading = true;
      this.$root.getTruckPrevLoad(this.truckId, this.currentLoadId).then((response) => {
        if (response.status === 'ok') {
          this.prevLoadData = response;
          this.isFetched = true;
        }
        this.loading = false;
      });
    }
  }
};
</script>
