<template>
  <div class="text-center">
    <!--<v-container fill-height grid-list-md text-xs-center v-if="loading">
      <div class="lds-facebook" style="margin:auto;"><div></div><div></div><div></div></div>
    </v-container>-->
    <div v-if="error">
      <v-flex class="text-center error pa-2">
        <h1 class="title">{{error}}</h1>
      </v-flex>
    </div>
    <div v-else>
      <v-overlay :value="loading" :absolute="true">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
      <v-layout>
        <v-flex xs12 class="pa-2 pt-8 text-right">
          <v-btn small color="primary" @click="onSave()" class="mr-1"><v-icon left>mdi-content-save</v-icon>{{$route.params.loadId ? $t('Create') : $t('Save')}}</v-btn>
          <v-btn small @click="onCancel"><v-icon left color="error">mdi-cancel</v-icon>{{$t('Cancel')}}</v-btn>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex class="text-left pl-2" grow>
          {{$t('Invoices.FromLoadsList')}}:
          <v-chip class="ma-1" color="success">
            #{{loadFields.id}}
          </v-chip>
          <v-chip v-for="loadId in additionalLinkedLoadsIds" :key="'additionalLinkedLoads' + loadId"
                  @click:close="removeFromAdditionalLoads(loadId)"
                  close
                  class="ma-1"
                  color="success">
            #{{loadId}}
          </v-chip>
          <v-btn small icon @click="addLoadModal = true"><v-icon>mdi-plus</v-icon></v-btn>
        </v-flex>
      </v-layout>
      <v-dialog v-model="addLoadModal" width="500">
        <v-card>
          <v-card-title>Type to select load</v-card-title>
          <v-card-text>
          <SelectLoad @change="(value)=>{ selectedAdditionalLinkedLoadsId = value; }"
                      :key="selectedAdditionalLinkedLoadsId"
                      :excludeLoad="$route.params.loadId ? $route.params.loadId : fields.load_id"
                      :value="selectedAdditionalLinkedLoadsId"
          ></SelectLoad>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn class="btn" color="primary" @click="onAddAdditionalLoad()">{{$t('Add')}}</v-btn>
            <v-btn class="btny" @click="onCloseAdditionalLoadModal()">{{$t('Close')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-form ref="form" @input="formInput">
        <v-subheader v-if="parentId">
          {{$t('Invoices.parentInvoice')}}:
          &nbsp;#{{parentInvoiceNumber.toString().padStart(4, '0')}}&nbsp;&mdash;&nbsp;
          <a @click="$root.isInvoicesViewAllowed ? $root.route('/invoices/page/' + $route.params.parentInvoiceId) : null">
            {{parentInvoiceName}}
          </a>
          &nbsp;${{parentInvoiceTotal.toLocaleString()}}
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click.stop="$root.addNewPageTab('/invoices/page/' + $route.params.parentInvoiceId)" v-if="$root.isInvoicesViewAllowed">
                <v-icon small class="mr-1">mdi-open-in-new</v-icon>
              </v-btn>
            </template>
            <span>{{$t('OpenExternal')}}</span>
          </v-tooltip>
        </v-subheader>
        <v-layout wrap>
          <v-flex xs12 md6>
            <v-text-field
              class="ma-1"
              label="Name"
              outlined dense
              hide-details
              v-model="fields.name"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-select
              class="mr-1 mt-1"
              v-model="drivers"
              :items="driversItems"
              :label="$t('Invoices.drivers')"
              item-value="id"
              item-text="name"
              dense multiple outlined
              hide-details
            ></v-select>
          </v-flex>
          <v-flex xs12 md3>
            <v-select
              class="mt-1"
              v-model="trucks"
              :items="trucksItems"
              :label="$t('Invoices.trucks')"
              item-value="id"
              item-text="name"
              dense multiple outlined
              hide-details
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs10 md3 class="pt-1">
            <SelectUser
              ref="brokerSelect"
              :rules="$root.fieldRequiredRules"
              :label="$t('Invoices.SelectBroker')"
              role="Broker"
              :hidePrependIcon="true"
              :key="fields.broker ? 'broker'+fields.broker : null"
              :value="fields.broker"
              :required="true"
              @change="brokerChanged"/>
          </v-flex>
          <v-flex shrink class="pt-2">
            <v-btn icon @click="newBrokerModal = true"><v-icon color="success">mdi-plus</v-icon></v-btn>
          </v-flex>
          <v-flex xs12 md4>
            <v-text-field
              class="ma-1 mt-2"
              :label="$t('Invoices.DueDate')"
              outlined dense
              prepend-inner-icon="mdi-calendar-alert"
              v-model="formatedDueDate"
              @change="formatedDateChanged($event,'dueDate')"
            >
            </v-text-field>
          </v-flex>
          <template v-if="$root.isFullFinancialDataAllowedToView">
            <v-flex xs6 md2>
              <v-switch v-model="isFactoring"
                        class="ml-2"
                        @change="onFactoringSwitchChange"
                        inset
                        :label="$t('Invoices.isFactoring')"></v-switch>
            </v-flex>
            <v-flex xs6 md2>
              <v-text-field
                v-if="isFactoring"
                class="ma-1"
                :label="$t('Invoices.Factoring')"
                :height="50"
                outlined dense
                prefix="%"
                type="number" min="0.0" step="0.1" @mousewheel.prevent=""
                v-model="fields.factoring"
              />
            </v-flex>
          </template>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 md6 class="pa-3">
            <LoadDocumentsList v-if="fields.load_id"
                               :fields="loadFields"
                               :currentInvoiceDocumentsField="fields.invoiceDocumentsUPLOAD"
                               :key="loadFieldsUpdateKey"
                               @selected="onFilesSelected"
            />
            <div v-else>
              Invoice doesn`t have linked load
            </div>
          </v-flex>
          <v-flex xs12 md6>
            <!--style="min-width: 21cm;width: 21cm;min-height: 23cm; margin:auto;"-->
            <v-card light>
              <v-card-text>
                <v-layout>
                  <v-flex xs6 class="text-left pr-0 pl-4">
                    <img responsive width="80%" :src="logoPath"/>
                    <br/>
                    <div class="text-left" style="width: 80%;">
                      <span v-html="invoiceContactInfo ? $root.newLineToBR(invoiceContactInfo) : null"></span>
                    </div>
                  </v-flex>
                  <v-flex xs6 class="ma-2 text-right">
                    <h1>Invoice</h1>
                    <v-simple-table class="mt-2 text-left" dense style="border: 1px solid #000;">
                      <tbody>
                      <tr style="background:#015aaa;">
                        <td style="border-right: 1px solid #000;color:#fff;">
                          <v-text-field dense outlined dark hide-details v-model="fields.headers.DateHeader"/>
                        </td>
                        <td style="color:#fff;">
                          <v-text-field dense outlined dark hide-details v-model="fields.headers.InvoiceNumberHeader"/>
                        </td>
                      </tr>
                      <tr>
                        <td style="border-right: 1px solid #000;">
                          <v-text-field
                            v-model="formatedDate"
                            hide-details
                            @change="formatedDateChanged($event,'date')"
                          />
                        </td>
                        <td>
                          <v-text-field
                            hide-details
                            v-model="fields.invoiceNumber"
                          />
                        </td>
                      </tr>
                      <tr style="background:#015aaa;">
                        <td colspan="2"  style="border: 1px solid #000;color:#fff;">
                          <h4>Load Unique Identifier</h4>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                          <v-text-field
                            hide-details
                            v-model="fields.loadUID"
                          />
                        </td>
                      </tr>
                      </tbody>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs6 class="ma-2">
                    <v-simple-table dense class="text-left" style="border: 1px solid #000;">
                      <tbody>
                      <tr style="background:#015aaa;color:#fff;">
                        <td>
                          <v-text-field dense outlined dark hide-details v-model="fields.headers.BillToHeader"/>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <v-textarea
                            v-model="fields.billTo"
                            hide-details
                          >
                          </v-textarea>
                        </td>
                      </tr>
                      </tbody>
                    </v-simple-table>

                    <v-simple-table class="mt-2 text-left" dense style="border: 1px solid #000;">
                      <tbody>
                      <tr style="background:#015aaa;">
                        <td style="border-right: 1px solid #000;color:#fff;">
                          <v-text-field dense outlined dark hide-details v-model="fields.headers.TermsHeader"/>
                        </td>
                        <td style="color:#fff;">
                          <v-text-field dense outlined dark hide-details v-model="fields.headers.ShipDateHeader"/>
                        </td>
                      </tr>
                      <tr>
                        <td style="border-right: 1px solid #000;">
                          <v-text-field
                            hide-details
                            v-model="fields.terms"
                          />
                        </td>
                        <td>
                          <v-text-field
                            v-model="formatedShipDate"
                            hide-details
                            @change="formatedDateChanged($event,'shipDate')"
                          />
                        </td>
                      </tr>
                      </tbody>
                    </v-simple-table>
                  </v-flex>
                  <v-flex xs6 class="ma-2">
                    <v-simple-table dense class="text-left" style="border: 1px solid #000;color:#fff;">
                      <tbody>
                      <template  v-if="!isFactoring || invoiceFactoringShipTo === ''">
                        <tr style="background:#015aaa;">
                          <td>
                            <v-text-field dense outlined dark hide-details v-model="fields.headers.ShipToHeader"/>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <v-textarea
                              hide-details
                              v-model="fields.shipTo"
                            />
                          </td>
                        </tr>
                      </template>
                      <div v-html="invoiceFactoringShipTo" style="text-align: center; color: #000;" v-else/>
                      </tbody>
                    </v-simple-table>
                    <v-simple-table dense class="mt-2 text-left" style="border: 1px solid #000;color:#fff;">
                      <tbody>
                      <tr style="background:#015aaa;">
                        <td>
                          <v-text-field dense outlined dark hide-details v-model="fields.headers.LoadNumberHeader"/>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <v-text-field
                            hide-details
                            v-model="fields.loadNumber"
                          />
                        </td>
                      </tr>
                      </tbody>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 class="mt-2">
                    <v-simple-table style="border: 1px solid #000;" class="text-left">
                      <tbody>
                      <tr style="background:#015aaa;">
                        <td style="border-right: 1px solid #000; border-bottom: 1px solid #000;color:#fff;">
                          <v-text-field dense outlined dark hide-details v-model="fields.headers.ShipperHeader"/>
                        </td>
                        <td style="border-right: 1px solid #000; border-bottom: 1px solid #000;color:#fff;">
                          <v-text-field dense outlined dark hide-details v-model="fields.headers.ConsigneeHeader"/>
                        </td>
                        <td style="border-right: 1px solid #000; border-bottom: 1px solid #000;color:#fff;">
                          <v-text-field dense outlined dark hide-details v-model="fields.headers.DescriptionHeader"/>
                        <td style="border-bottom: 1px solid #000;color:#fff;" colspan="2">
                          <v-text-field dense outlined dark hide-details v-model="fields.headers.AmountHeader"/>
                        </td>
                      </tr>
                      <tr v-for="(entry, index) in entries">
                        <td style="border-right: 1px solid #000;">
                          <v-textarea
                            :value="entry.shipper"
                            hide-details
                            @change="shipperChanged($event, index)"
                          ></v-textarea>
                        </td>
                        <td style="border-right: 1px solid #000;">
                          <v-textarea
                            :value="entry.consignee"
                            hide-details
                            @change="consigneeChanged($event, index)"
                          ></v-textarea>
                        </td>
                        <td style="border-right: 1px solid #000;">
                          <v-textarea
                            :value="entry.descriptionDimensions"
                            hide-details
                            @change="descriptionDimensionsChanged($event, index)"
                          ></v-textarea>
                        </td>
                        <td style="vertical-align: bottom;">
                          <v-text-field
                            v-model="entry.amount"
                            hide-details
                            prefix="$"
                            type="number" min="0.00" step="1.00"
                            @mousewheel.prevent=""
                            @input="calcTotal"
                            @change="calcTotal"
                          >
                          </v-text-field>
                        </td>
                        <td style="vertical-align: bottom;">
                          <v-btn icon @click="deleteEntry(index)" class="mb-4">
                            <v-icon color="error">mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="5" class="text-right">
                          <v-btn @click="addEntry" small><v-icon left color="success">mdi-plus</v-icon> {{$t('Invoices.AddEntry')}}</v-btn>
                        </td>
                      </tr>

                      <tr style="background:#015aaa;">
                        <td colspan="3" class="text-center" style="color:#fff;border-right: 1px solid #000;border-top: 1px solid #000; vertical-align: top;">
                          <v-text-field dense outlined dark hide-details v-model="fields.headers.ThankYouHeader"/>
                        </td>
                        <td colspan="2" class="text-left" style="color:#fff;border-top: 1px solid #000;">
                          <v-text-field dense outlined dark hide-details v-model="fields.headers.TotalHeader"/>
                          <v-text-field
                            v-model="fields.total"
                            hide-details
                            prefix="$"
                            type="number" min="0.00" step="1.00"
                            dark
                          />
                        </td>
                      </tr>
                      </tbody>
                    </v-simple-table>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-form>
      <v-layout>
        <v-flex xs12 class="pa-2 pt-8 text-right">
          <v-btn small color="primary" @click="onSave()" class="mr-1"><v-icon left>mdi-content-save</v-icon>{{$route.params.loadId ? $t('Create') : $t('Save')}}</v-btn>
          <v-btn small @click="$route.params.loadId ? $router.go(-1) : $root.route('/invoices/page/' + invoiceId)"><v-icon left color="error">mdi-cancel</v-icon>{{$t('Cancel')}}</v-btn>
        </v-flex>
      </v-layout>
    </div>
    <v-dialog v-model="newBrokerModal" max-width="450">
      <v-card>
        <v-card-title>
          {{$t('CreateNewBroker')}}
          <v-spacer></v-spacer>
          <v-btn icon class="mr-4" @click="newBrokerModal=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="newBrokerForm">
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="newBrokerName"
                  :label="$t('User.Fields.brokerName')"
                  :rules="$root.fieldRequiredRules"
                  :required="true"
                  prepend-icon="fa-user-alt"
                  class="mb-2"
                  outlined dense hide-details
                />
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  :label="$t('User.Fields.brokerBillTo')"
                  v-model="newBrokerBillTo"
                  outlined dense hide-details
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions class="text-right">
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="onNewBrokerSave">{{$t('Create')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SelectUser from '../../components/SelectUser.vue';
import LoadDocumentsList from '../../components/LoadDocumentsList.vue';
import userFormsCommonMixin from '../../mixins/userFormsCommonMixin';
import fieldLoadingMixin from '../../mixins/fieldLoadingMixin';
import fieldAccessRightMixin from '../../mixins/fieldAccessRightsMixin';
import SelectLoad from '../../components/SelectLoad.vue';

export default {
  components: { SelectUser, LoadDocumentsList, SelectLoad },
  mixins: [userFormsCommonMixin, fieldLoadingMixin, fieldAccessRightMixin],
  props: ['id', 'isOpenedInModal'],
  data: () => ({
    publicPath: process.env.BASE_URL,
    fields: {
      status: '',
      parent: 0,
      name: '',
      load_id: 0,
      broker: 0,
      accountManagers: '[]',
      date: '',
      dueDate: '',
      invoiceNumber: 0,
      billTo: '',
      shipTo: '',
      terms: 'NET 30',
      shipDate: '',
      loadNumber: '',
      entries: '[]',
      loadUID: '',
      /* Shipper: '',
      Consignee: '',
      DescriptionDimensions: '',
      Amount: 0, */
      total: 0,
      updateNumber: 0,
      factoring: 0.0,
      invoiceDocumentsUPLOAD: '[]',
      drivers: '[]',
      trucks: '[]',
      headers: {
        DateHeader: 'Date',
        InvoiceNumberHeader: 'Invoice #',
        BillToHeader: 'Bill To',
        ShipToHeader: 'Ship To',
        TermsHeader: 'Terms',
        ShipDateHeader: 'Ship Date',
        LoadNumberHeader: 'Load Number',
        ShipperHeader: 'Shipper',
        ConsigneeHeader: 'Consignee',
        DescriptionHeader: 'Description/Dimensions',
        AmountHeader: 'Amount',
        TotalHeader: 'Total',
        ThankYouHeader: 'Thank you for your business.'
      }
    },
    isFactoring: false,
    loadFields: {},
    loadFieldsUpdateKey: new Date().getTime(),
    invoiceContactInfo: '',
    invoiceFactoringShipTo: '',
    formatedDate: '',
    formatedDueDate: '',
    formatedShipDate: '',
    entries: [],
    loading: false,
    error: false,
    parentInvoiceNumber: 0,
    parentInvoiceName: '',
    parentInvoiceTotal: 0,
    parentId: 0,
    drivers: [],
    driversItems: [],
    trucks: [],
    trucksItems: [],
    selectedAdditionalLinkedLoadsId: 0,
    additionalLinkedLoadsIds: [],
    addLoadModal: false,
    newBrokerModal: false,
    newBrokerName: '',
    newBrokerBillTo: '',
    logoPath: ''
  }),
  computed: {
    invoiceId () {
      return this.id || this.$route.params.id;
    }
  },
  watch: {
  },
  mounted () {
    this.$root.getInvoiceLogoPath().then(path => {
      this.logoPath = path;
    });
    this.trucksItems = [];
    this.$root.getTrucksList().then((response) => {
      if (response.status === 'ok') {
        for (let i = 0; i < response.result.length; i++) {
          this.trucksItems.push({
            id: response.result[i].id,
            name: response.result[i].name
          });
        }
      }
    });

    this.driversItems = [];
    this.$root.loadUsersList('Driver').then((response) => {
      if (response.status === 'ok') {
        for (let i = 0; i < response.result.data.length; i++) {
          this.driversItems.push({
            id: response.result.data[i].id,
            name: response.result.data[i].firstName + ' ' + response.result.data[i].lastName
          });
        }
      }
    });

    this.$root.loadUsersList('DriverOwnerOperator').then((response) => {
      if (response.status === 'ok') {
        for (let i = 0; i < response.result.data.length; i++) {
          this.driversItems.push({
            id: response.result.data[i].id,
            name: response.result.data[i].firstName + ' ' + response.result.data[i].lastName
          });
        }
      }
    });

    this.$root.getGlobalSettingsField('invoiceContactInfo').then((response) => {
      if (response.status === 'ok') {
        this.invoiceContactInfo = response.result;
      }
    });

    this.$root.getGlobalSettingsField('invoiceFactoringShipTo').then((response) => {
      if (response.status === 'ok') {
        this.invoiceFactoringShipTo = response.result;
      }
    });

    if (!this.$route.params.parentInvoiceId) {
      this.$root.getGlobalSettingsField('defaultFactoringPercent').then((response) => {
        if (response.status === 'ok') {
          this.fields.factoring = parseFloat(response.result);
        }
      });
    }

    this.$root.contextSave = () => {
      this.save();
    };
    if (this.invoiceId) {
      this.fetchData();
    } else {
      this.addEntry();
      // initialize dates
      this.formatedDate = this.$root.getDateTimeStringFromTimestamp(new Date().getTime() / 1000).split(' ')[0];
      this.formatedDateChanged(this.formatedDate, 'date');
      // Due Date = current date + 1 month
      this.formatedDueDate = this.$root.getDateTimeStringFromTimestamp((new Date().getTime() / 1000) + 2529000).split(' ')[0];
      this.formatedDateChanged(this.formatedDueDate, 'dueDate');

      if (!this.$route.params.parentInvoiceId) {
        this.$root.getMaxInvoiceNumber().then((response) => {
          if (response.status === 'ok') {
            if (response.result && response.result.hasOwnProperty('maxInvoiceNumber')) {
              this.fields.invoiceNumber = parseInt(response.result.maxInvoiceNumber) + 1;
            }
          }
        });
      }

      // If load id was passed to url, use 'load' data
      if (this.$route.params.loadId) {
        this.fields.load_id = this.$route.params.loadId;
        this.$root.getLoad(this.$route.params.loadId).then((response) => {
          if (response.status === 'ok') {
            if (!response.isInvoiceCreateAllowed) {
              this.error = this.$root.t('Loads.DocumentsNotMeetRequirements');
              return;
            }
            this.loadFields = response.result;
            this.loadFieldsUpdateKey = new Date().getTime();
            this.fields.loadUID = response.result['uid'];
            this.fields.shipTo = response.result['consignee'];
            this.fields.notes = response.result['notes'];
            this.fields.loadNumber = response.result['loadNumber'];
            if (parseInt(response.result['isFactoring']) === 1) {
              this.isFactoring = true;
              this.fields.factoring = response.result['factoringPercent'];
            } else {
              this.isFactoring = false;
              this.fields.factoring = -1;
            }

            // fill drivers and trucks dropdowns
            this.driversItems = response.drivers;
            this.trucksItems = response.trucks;

            // Preselect trucks and drivers
            const locations = JSON.parse(response.result['locationsJSON'].replace(/\n/g, '\\n').replace(/\r/g, '\\r').replace(/\t/g, '\\t'));

            let driversUniqueObj = {};
            let trucksUniqueObj = {};
            for (let l = 0; l < locations.length - 1; l++) {
              if (locations[l] && locations[l].truck && parseInt(locations[l].truck) > 0) {
                trucksUniqueObj[parseInt(locations[l].truck)] = true;
              }
              if (locations[l] && locations[l].driver && parseInt(locations[l].driver) > 0) {
                driversUniqueObj[parseInt(locations[l].driver)] = true;
              }
              if (locations[l] && locations[l].codriver && parseInt(locations[l].codriver) > 0) {
                driversUniqueObj[parseInt(locations[l].codriver)] = true;
              }
            }

            if (parseInt(this.loadFields['isDriverOwnerOperator']) === 1) {
              if (parseInt(this.loadFields['driverOO']) > 0) {
                this.drivers = [parseInt(this.loadFields['driverOO'])];
              }
              if (parseInt(this.loadFields['truckOO']) > 0) {
                this.trucks = [parseInt(this.loadFields['truckOO'])];
              }
            } else {
              this.trucks = [];
              for (let i = 0; i < Object.keys(trucksUniqueObj).length; i++) {
                this.trucks.push(parseInt(Object.keys(trucksUniqueObj)[i]));
              }
              this.fields.trucks = JSON.stringify(this.trucks);

              this.drivers = [];
              for (let i = 0; i < Object.keys(driversUniqueObj).length; i++) {
                this.drivers.push(parseInt(Object.keys(driversUniqueObj)[i]));
              }
              this.fields.drivers = JSON.stringify(this.drivers);
            }

            this.brokerChanged(response.result['broker']);

            if (parseInt(response.result['ended']) > 0) {
              this.formatedShipDate = this.$root.getDateTimeStringFromTimestamp(parseInt(response.result['started'])).split(' ')[0];
              this.formatedDateChanged(this.formatedShipDate, 'shipDate');
              this.entries[0].shipper = response.result['shipper'];
              this.entries[0].consignee = response.result['consignee'];
              this.entries[0].descriptionDimensions = response.result['loadDescription'];

              if (parseInt(response.result['finished']) === 2) {
                this.entries[0].amount = parseFloat(response.result['RateTONU']) +
                      parseFloat(response.result['Detention']) +
                      parseFloat(response.result['Layover']) -
                      parseFloat(response.result['Fine']);
              } else {
                const rtlRate = parseFloat(response.result['RTLRate']) || 0;
                this.entries[0].amount = parseFloat(response.result['Rate']) + rtlRate +
                      parseFloat(response.result['Detention']) +
                      parseFloat(response.result['Layover']) -
                      parseFloat(response.result['Fine']);
              }

              if (parseInt(response.result['isDriverAssist']) === 1) {
                this.entries[0].amount += parseFloat(response.result['DriverAssist']);
              }

              // Add expenses included in rate
              const expenses = JSON.parse(response.result['expensesJSON']);
              if (expenses) {
                for (let i = 0; i < expenses.length; i++) {
                  if (parseInt(expenses[i].addToRate) === 1) {
                    this.entries[0].amount += parseFloat(expenses[i].total);
                  }
                }
              }
              if (this.entries.length > 0) {
                this.entries[0].amount = parseFloat(this.entries[0].amount).toFixed(2);
              }

              this.calcTotal();
              if (parseInt(response.result['bid'])) {
                this.$root.getBid(parseInt(response.result['bid'])).then((responseForBid) => {
                  if (responseForBid.status === 'ok') {
                    this.fields.name = responseForBid.result['subject'];
                  }
                });
              }
            }
          }
        });
      } else if (this.$route.params.parentInvoiceId) {
        this.parentId = this.$route.params.parentInvoiceId;
        this.$root.getInvoice(this.$route.params.parentInvoiceId).then((response) => {
          if (response.status === 'ok') {
            // this.fields.status = 'New Updated';
            this.fields.parent = this.$route.params.parentInvoiceId;
            this.fields.load_id = response.result['load_id'];
            // Calc invoice number
            this.$root.getInvoiceCountByLoadId(this.fields.load_id).then((response2) => {
              if (response2.status === 'ok') {
                if (response2.result && response2.result.hasOwnProperty('invoicesCount')) {
                  const updateNumber = parseInt(response2.result.invoicesCount) + 1;
                  this.fields.name = response.result['name'];
                  this.fields.updateNumber = updateNumber;
                }
              }
            });
            // Get load Data
            this.$root.getLoad(this.fields.load_id).then((response2) => {
              if (response2.status === 'ok') {
                this.loadFields = response2.result;
                this.loadFieldsUpdateKey = new Date().getTime();
              }
            });

            // fill drivers and trucks dropdowns
            this.driversItems = response.drivers;
            this.trucksItems = response.trucks;

            this.fields.broker = response.result['broker'];
            this.fields.accountManagers = response.result['accountManagers'];

            this.fields.shipDate = response.result['shipDate'] ? response.result['shipDate'].split('T')[0] : '';
            this.fields.date = response.result['date'] ? response.result['date'].split('T')[0] : '';
            this.fields.dueDate = response.result['dueDate'] ? response.result['dueDate'].split('T')[0] : '';

            this.parentInvoiceNumber = response.result['invoiceNumber'];
            this.parentInvoiceName = response.result['name'];
            this.parentInvoiceTotal = response.result['total'];

            this.fields.invoiceNumber = this.parentInvoiceNumber;
            this.fields.factoring = 0.0;

            this.fields.billTo = response.result['billTo'];
            this.fields.shipTo = response.result['shipTo'];
            this.fields.terms = response.result['terms'];
            this.fields.loadNumber = response.result['loadNumber'];
            this.fields.entries = response.result['entries'];
            this.fields.loadUID = response.result['loadUID'];
            this.fields.total = response.result['total'];

            this.fields.drivers = response.result['drivers'];
            this.drivers = JSON.parse(response.result['drivers']);

            this.fields.trucks = response.result['trucks'];
            this.trucks = JSON.parse(response.result['trucks']);

            if (this.fields.date) {
              let _dateArray = this.fields.date.split('T')[0].split('-');
              this.formatedDate = _dateArray[1] + '/' + _dateArray[2] + '/' + _dateArray[0];
            }

            if (this.fields.dueDate) {
              let _dateArray = this.fields.dueDate.split('T')[0].split('-');
              this.formatedDueDate = _dateArray[1] + '/' + _dateArray[2] + '/' + _dateArray[0];
            }

            if (this.fields.shipDate) {
              let _dateArray = this.fields.shipDate.split('T')[0].split('-');
              this.formatedShipDate = _dateArray[1] + '/' + _dateArray[2] + '/' + _dateArray[0];
            }

            if (response.result['entries']) {
              this.entries = JSON.parse(response.result['entries'].replace(/\n/g, '\\n').replace(/\r/g, '\\r').replace(/\t/g, '\\t'));
            }
          }
        });
      }
    }
  },
  beforeDestroy () {
    this.$root.contextSave = () => {};
  },
  methods: {
    onNewBrokerSave () {
      this.$nextTick(() => {
        if (this.newBrokerName.length > 0) {
          this.$root.createUser('', '', this.$store.getters.roleBroker, { firstName: this.newBrokerName, brokerBillTo: this.newBrokerBillTo }).then(response => {
            if (response.status === 'ok') {
              this.fields.broker = response.result.insertId;
              if (this.fields.billTo === '') {
                this.fields.billTo = this.newBrokerBillTo;
              }
              this.newBrokerName = '';
              this.newBrokerBillTo = '';
              this.newBrokerModal = false;
            }
          });
        }
      });
    },
    onAddAdditionalLoad () {
      if (this.selectedAdditionalLinkedLoadsId > 0) {
        this.additionalLinkedLoadsIds.push(this.selectedAdditionalLinkedLoadsId);
        this.selectedAdditionalLinkedLoadsId = 0;
        this.addLoadModal = false;
      }
    },
    onCloseAdditionalLoadModal () {
      this.selectedAdditionalLinkedLoadsId = 0;
      this.addLoadModal = false;
    },
    removeFromAdditionalLoads (loadId) {
      if (this.additionalLinkedLoadsIds.length === 1) {
        this.additionalLinkedLoadsIds = [];
      } else if (this.additionalLinkedLoadsIds.length > 1) {
        const id = this.additionalLinkedLoadsIds.indexOf(loadId);
        if (id >= 0) {
          //console.log(id, loadId, this.additionalLinkedLoadsIds);
          this.additionalLinkedLoadsIds.splice(id, 1);
        }
      }
    },
    onCancel () {
      if (this.isOpenedInModal) {
        this.$emit('on-close');
      } else if (this.$route.params.loadId) {
        this.$router.go(-1);
      } else {
        this.$root.route('/invoices/page/' + this.invoiceId);
      }
    },
    onSave () {
      this.save();
    },
    formInput (event) {
      this.$store.commit('setIsCurrentFormUpdated', true);
    },
    onFactoringSwitchChange () {
      if (this.isFactoring) {
        if (this.fields.factoring < 0) {
          this.fields.factoring = 0.0;
        }
      } else {
        this.fields.factoring = -1.0;
      }
    },
    onFilesSelected (value) {
      this.fields.invoiceDocumentsUPLOAD = JSON.stringify(value);
    },
    deleteEntry (index) {
      this.$dialog.confirm(this.$root.t('Invoices.DeleteEntryConfirmationMsg')).then(dialog => {
        this.entries.splice(index, 1);
        this.calcTotal();
      }).catch(() => {
      });
    },
    shipperChanged (value, index) {
      this.entries[index].shipper = value;// .replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t")
    },
    consigneeChanged (value, index) {
      this.entries[index].consignee = value;// .replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t")
    },
    descriptionDimensionsChanged (value, index) {
      this.entries[index].descriptionDimensions = value;// .replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t")
    },
    formatedDateChanged (value, field) {
      if (value.length !== 10) return;
      if (value.split('/').length !== 3) return;
      let _formatedDateArray = value.split('/');
      this.fields[field] = _formatedDateArray[2] + '-' + _formatedDateArray[0] + '-' + _formatedDateArray[1];
    },
    calcTotal () {
      this.fields.total = 0;
      this.entries.forEach((element) => {
        this.fields.total += parseFloat(element.amount);
      });

      this.fields.total = this.fields.total.toFixed(2);
    },
    addEntry () {
      this.entries.push({
        shipper: '',
        consignee: '',
        descriptionDimensions: '',
        amount: 0
      });
      this.$forceUpdate();
    },
    brokerChanged (value, doNotUpdateData = false) {
      this.fields.broker = value;
      if (doNotUpdateData) return;
      this.$root.loadUserFields(value).then((response) => {
        if (response.status === 'ok') {
          this.fields.billTo = response.fields.brokerBillTo;
          /* if (response.fields.brokerCompanyName && response.fields.brokerCompanyName != 'null') {
            this.fields.shipTo = response.fields.brokerCompanyName + '\n';
          }

          if (response.fields.phoneNumber && response.fields.phoneNumber != 'null') {
            this.fields.shipTo += response.fields.phoneNumber + '\n';
          }

          if (response.fields.brokerContactPerson && response.fields.brokerContactPerson != 'null') {
            this.fields.shipTo += response.fields.brokerContactPerson + '\n';
          } */
        }
      });
    },
    save () {
      return new Promise(async (resolve, reject) => {
        // if (this.$refs.form.validate()) {
        //   resolve();
        //   return;
        // }
        if (!this.invoiceId) {
          const resp = await this.$root.getInvoiceByNumber(this.fields.invoiceNumber);
          if (resp.status === 'ok') {
            const resp2 = await this.$root.getMaxInvoiceNumber();
            if (resp2.status === 'ok' && resp2.result && resp2.result.hasOwnProperty('maxInvoiceNumber')) {
              this.$root.toast(this.$i18n.t('Invoices.InvoiceWithNumberExists'), {color: 'error'});
              this.fields.invoiceNumber = parseInt(resp2.result.maxInvoiceNumber) + 1;
              this.$root.toast(this.$i18n.t('Invoices.InvoiceWithNumberExistsUseNumber', this.fields.invoiceNumber), {color: 'error'});
              resolve();
              return;

            } else {
              this.$root.toast(this.$i18n.t('Invoices.InvoiceWithNumberExists'), {color: 'error'});
              resolve();
              return;
            }
          }
        }

        this.$dialog.confirm(this.$root.t('Invoices.SaveConfirmationMsg')).then(dialog => {
          // Set specific fields
          this.fields.entries = JSON.stringify(this.entries);
          this.fields.drivers = JSON.stringify(this.drivers);
          this.fields.trucks = JSON.stringify(this.trucks);
          // Save
          if (this.invoiceId) {
            // TODO fix this bug, factoring sometime not fetched properly and stays zero
            if (this.fields.hasOwnProperty('factoring') && parseFloat(this.fields['factoring']) === 0.0) {
              console.error('facroting is zero', this.invoiceId, this.fields);
              delete this.fields['factoring'];
            }
            this.$root.saveInvoice(this.fields, this.invoiceId, false, this.additionalLinkedLoadsIds).then((response) => {
              if (response.status === 'ok') {
                this.$store.commit('setIsCurrentFormUpdated', false);
                if (this.isOpenedInModal) {
                  this.$emit('on-close');
                } else {
                  this.$root.route('/invoices/page/' + this.invoiceId);
                }
              }
            });
          } else {
            this.fields.status = 'New';
            this.$root.createInvoice(this.fields, this.additionalLinkedLoadsIds).then((response) => {
              if (response.status === 'ok') {
                this.$store.commit('setIsCurrentFormUpdated', false);
                this.$root.route('/invoices/page/' + response.createdId);
              }
              resolve();
            });
          }
        }).catch(() => {
        });
      });
    },
    create () {
    },
    fetchData () {
      let i = 0;
      if (!this.invoiceId && !this.isInvoicesCreateAllowed) {
        this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
        this.loading = false;
        return;
      }

      if (this.invoiceId) {
        if (!this.isInvoicesViewAllowed || !this.isInvoicesEditAllowed) {
          this.error = this.$root.t('Errors.AccessDeniedAuthorizationFailed');
          this.loading = false;
          return;
        }
      }

      this.loading = true;
      this.$root.getInvoice(this.invoiceId).then((response) => {
        if (response.status === 'ok') {
          if (!response.isEditAllowed) {
            this.error = 'Access Denied';
          }
          // fill drivers and trucks dropdowns
          this.driversItems = response.drivers;
          this.trucksItems = response.trucks;
          this.additionalLinkedLoadsIds = [];
          for (i = 0; i < response.linkedLoadsIds.length; i++) {
            this.additionalLinkedLoadsIds.push(response.linkedLoadsIds[i].loadId);
          }
          this.fields.status = response.result['status'];
          this.fields.name = response.result['name'];
          this.fields.parent = response.result['parent'];
          this.parentId = parseInt(this.fields.parent);
          if (this.parentId > 0) {
            this.$root.getInvoice(this.parentId).then((response2) => {
              if (response2.status === 'ok') {
                this.parentInvoiceNumber = response2.result['invoiceNumber'];
                this.parentInvoiceName = response2.result['name'];
                this.parentInvoiceTotal = response2.result['total'];
              }
            });
          }
          this.fields.broker = response.result['broker'];
          this.brokerChanged(response.result['broker'], true);
          this.fields.load_id = response.result['load_id'];

          if (this.fields.load_id > 0) {
            this.$root.getLoad(this.fields.load_id).then((response) => {
              if (response.status === 'ok') {
                this.loadFields = response.result;
                this.loadFieldsUpdateKey = new Date().getTime();
              }
            });
          }

          this.fields.loadUID = response.result['loadUID'];
          // accountManagers: '[]',
          this.fields.date = response.result['date'] ? response.result['date'].split('T')[0] : null;
          this.fields.dueDate = response.result['dueDate'] ? response.result['dueDate'].split('T')[0] : null;
          this.fields.invoiceNumber = response.result['invoiceNumber'];
          this.fields.billTo = response.result['billTo'];
          this.fields.shipTo = response.result['shipTo'];
          this.fields.terms = response.result['terms'];
          this.fields.shipDate = response.result['shipDate'] ? response.result['shipDate'].split('T')[0] : null;
          this.fields.loadNumber = response.result['loadNumber'];
          this.fields.total = response.result['total'];
          this.fields.updateNumber = response.result['updateNumber'];
          this.fields.factoring = parseFloat(response.result['factoring']);
          if (this.fields.factoring >= 0.0) {
            this.isFactoring = true;
          }

          if (response.result['drivers'] && response.result['drivers'].indexOf('[') === 0) {
            this.fields.drivers = response.result['drivers'];
            this.drivers = JSON.parse(response.result['drivers']);
          }

          if (response.result['trucks'] && response.result['trucks'].indexOf('[') === 0) {
            this.fields.trucks = response.result['trucks'];
            this.trucks = JSON.parse(response.result['trucks']);
          }

          if (response.result['invoiceDocumentsUPLOAD'] && response.result['invoiceDocumentsUPLOAD'].indexOf('[') === 0) {
            this.fields.invoiceDocumentsUPLOAD = response.result['invoiceDocumentsUPLOAD'];
            this.loadFieldsUpdateKey = new Date().getTime();
          }

          if (this.fields.date) {
            let _dateArray = this.fields.date.split('T')[0].split('-');
            this.formatedDate = _dateArray[1] + '/' + _dateArray[2] + '/' + _dateArray[0];
          }

          if (this.fields.dueDate) {
            let _dateArray = this.fields.dueDate.split('T')[0].split('-');
            this.formatedDueDate = _dateArray[1] + '/' + _dateArray[2] + '/' + _dateArray[0];
          }

          if (this.fields.shipDate) {
            let _dateArray = this.fields.shipDate.split('T')[0].split('-');
            this.formatedShipDate = _dateArray[1] + '/' + _dateArray[2] + '/' + _dateArray[0];
          }

          if (response.result['entries']) {
            this.entries = [];
            let _entries = JSON.parse(response.result['entries'].replace(/\n/g, '\\n').replace(/\r/g, '\\r').replace(/\t/g, '\\t'));
            for (i = 0; i < _entries.length; i++) {
              let _entry = _entries[i];
              _entry.amount = parseFloat(parseFloat(_entries[i].amount).toFixed(2));
              this.entries.push(_entry);
            }
          }
          // Set headers if they available
          for (const [header, value] of Object.entries(this.fields.headers)) {
            if (response.result.hasOwnProperty(header)) {
              this.fields.headers[header] = response.result[header];
            }
          }
        } else {
          this.error = response.msg;
        }
        this.loading = false;
        this.$nextTick(() => {
          setTimeout(() => {
            this.$store.commit('setIsCurrentFormUpdated', false);
          }, 1000);
        });
      });
    }
  }
};
</script>
