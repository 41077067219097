<template>
  <div v-if="userId">
    <v-tabs v-model="documentsTab" grow class="mt-2" show-arrows active-class="tabs-active-color">
      <v-tab key="agreementUPLOAD" v-if="!isFieldHidden('agreementUPLOAD')">
        {{ $t('User.Fields.agreementUPLOAD') }}({{agreementUPLOADCount}})
      </v-tab>
      <v-tab key="passportUPLOAD" v-if="!isFieldHidden('passportUPLOAD')">
        {{ $t('User.Fields.passportUPLOAD') }}({{passportUPLOADCount}})
      </v-tab>
      <v-tab key="resumeUPLOAD" v-if="!isFieldHidden('resumeUPLOAD')">
        {{ $t('User.Fields.resumeUPLOAD') }}({{resumeUPLOADCount}})
      </v-tab>
      <v-tab key="W2FormUPLOAD" v-if="!isFieldHidden('W2FormUPLOAD')">
        {{ $t('User.Fields.W2FormUPLOAD') }}({{W2FormUPLOADCount}})
      </v-tab>
      <v-tab key="W9FormUPLOAD" v-if="!isFieldHidden('W9FormUPLOAD')">
        {{ $t('User.Fields.W9FormUPLOAD') }}({{W9FormUPLOADCount}})
      </v-tab>
      <template v-if="userRole === $store.getters.roleDispatcher">
        <v-tab key="paymentDetailsUPLOAD" v-if="!isFieldHidden('paymentDetailsUPLOAD')">
          {{ $t('User.Fields.paymentDetailsUPLOAD') }}({{paymentDetailsUPLOADCount}})
        </v-tab>
      </template>
      <template v-if="userRole === $store.getters.roleDriver">
        <v-tab key="driverDrivingLicenseUPLOAD" v-if="!isFieldHidden('driverDrivingLicenseUPLOAD')">
          {{ $t('User.Fields.driverDrivingLicenseUPLOAD') }}({{driverDrivingLicenseUPLOADCount}})
        </v-tab>
        <v-tab key="driverMedicalCardUPLOAD" v-if="!isFieldHidden('driverMedicalCardUPLOAD')">
          {{ $t('User.Fields.driverMedicalCardUPLOAD') }}({{driverMedicalCardUPLOADCount}})
        </v-tab>
        <v-tab key="driverWorkPermitOrGreenCardUPLOAD" v-if="!isFieldHidden('driverWorkPermitOrGreenCardUPLOAD')">
          {{ $t('User.Fields.driverWorkPermitOrGreenCardUPLOAD') }}({{driverWorkPermitOrGreenCardUPLOADCount}})
        </v-tab>
      </template>
      <template v-if="userRole === $store.getters.roleDriverOwnerOperator">
        <v-tab key="driverOwnerOperatorIENUPLOAD" v-if="!isFieldHidden('driverOwnerOperatorIENUPLOAD')">
          {{ $t('User.Fields.driverOwnerOperatorIENUPLOAD') }}({{driverOwnerOperatorIENUPLOADCount}})
        </v-tab>
        <v-tab key="driverOwnerOperatorInsuranceUPLOAD" v-if="!isFieldHidden('driverOwnerOperatorInsuranceUPLOAD')">
          {{ $t('User.Fields.driverOwnerOperatorInsuranceUPLOAD') }}({{driverOwnerOperatorInsuranceUPLOADCount}})
        </v-tab>
        <v-tab key="driverOwnerOperatorAuthorityFormUSDOTUPLOAD" v-if="!isFieldHidden('driverOwnerOperatorAuthorityFormUSDOTUPLOAD')">
          {{ $t('User.Fields.driverOwnerOperatorAuthorityFormUSDOTUPLOAD') }}({{driverOwnerOperatorAuthorityFormUSDOTUPLOADCount}})
        </v-tab>
        <v-tab key="driverOwnerOperatorAuthorityFormMCUPLOAD" v-if="!isFieldHidden('driverOwnerOperatorAuthorityFormMCUPLOAD')">
          {{ $t('User.Fields.driverOwnerOperatorAuthorityFormMCUPLOAD') }}({{driverOwnerOperatorAuthorityFormMCUPLOADCount}})
        </v-tab>
        <v-tab key="driverOwnerOperatorAuthorityFormCAUPLOAD" v-if="!isFieldHidden('driverOwnerOperatorAuthorityFormCAUPLOAD')">
          {{ $t('User.Fields.driverOwnerOperatorAuthorityFormCAUPLOAD') }}({{driverOwnerOperatorAuthorityFormCAUPLOADCount}})
        </v-tab>
        <v-tab key="driverOwnerOperatorStatementOfInformationCorporationUPLOAD" v-if="!isFieldHidden('driverOwnerOperatorStatementOfInformationCorporationUPLOAD')">
          {{ $t('User.Fields.driverOwnerOperatorStatementOfInformationCorporationUPLOAD') }}({{driverOwnerOperatorStatementOfInformationCorporationUPLOADCount}})
        </v-tab>
      </template>
      <template v-if="userRole === $store.getters.roleBroker">
        <v-tab key="brokerSetupPackageUPLOAD" v-if="!isFieldHidden('brokerSetupPackageUPLOAD')">
          {{ $t('User.Fields.brokerSetupPackageUPLOAD') }}({{brokerSetupPackageUPLOADCount}})
        </v-tab>
      </template>
    </v-tabs>

    <v-tabs-items v-model="documentsTab" v-if="fields">
      <v-tab-item key="agreementUPLOAD">
        <MultiFileUpload v-if="!isFieldHidden('agreementUPLOAD')"
                         :downloadAllowed="isFieldDownloadAllowed('agreementUPLOAD')"
                         :readonly="isFieldReadOnly('agreementUPLOAD')"
                         :field="fields.agreementUPLOAD"
                         :label="$t('User.Fields.agreementUPLOAD')"
                         @change="fileChange('agreementUPLOAD', $event, true, () => { saveFiles('agreementUPLOAD') })"
                         fieldName="agreementUPLOAD"
                         :deleteAllowed="!isFieldHidden('deleteUsersDocuments') && !isFieldReadOnly('deleteUsersDocuments')"
                         uploadFolder="agreement"
                         globalSettingSampleName="globalAgreementUPLOAD"
                         globalSettingSampleFolder="globalAgreement"
                         />
      </v-tab-item>
      <v-tab-item key="passportUPLOAD">
        <MultiFileUpload v-if="!isFieldHidden('passportUPLOAD')"
                         :downloadAllowed="isFieldDownloadAllowed('passportUPLOAD')"
                         :readonly="isFieldReadOnly('passportUPLOAD')"
                         :field="fields.passportUPLOAD"
                         :label="$t('User.Fields.passportUPLOAD')"
                         @change="fileChange('passportUPLOAD', $event, true, () => { saveFiles('passportUPLOAD') })"
                         fieldName="passportUPLOAD"
                         :deleteAllowed="!isFieldHidden('deleteUsersDocuments') && !isFieldReadOnly('deleteUsersDocuments')"
                         uploadFolder="passport"></MultiFileUpload>
      </v-tab-item>
      <v-tab-item key="resumeUPLOAD">
        <MultiFileUpload v-if="!isFieldHidden('resumeUPLOAD')"
                         :downloadAllowed="isFieldDownloadAllowed('resumeUPLOAD')"
                         :readonly="isFieldReadOnly('resumeUPLOAD')"
                         :field="fields.resumeUPLOAD"
                         :label="$t('User.Fields.resumeUPLOAD')"
                         @change="fileChange('resumeUPLOAD', $event, true, () => { saveFiles('resumeUPLOAD') })"
                         fieldName="resumeUPLOAD"
                         :deleteAllowed="!isFieldHidden('deleteUsersDocuments') && !isFieldReadOnly('deleteUsersDocuments')"
                         uploadFolder="resume"></MultiFileUpload>
      </v-tab-item>
      <v-tab-item key="W2FormUPLOAD">
        <MultiFileUpload v-if="!isFieldHidden('W2FormUPLOAD')"
                         :downloadAllowed="isFieldDownloadAllowed('W2FormUPLOAD')"
                         :readonly="isFieldReadOnly('W2FormUPLOAD')"
                         :field="fields.W2FormUPLOAD"
                         :label="$t('User.Fields.W2FormUPLOAD')"
                         @change="fileChange('W2FormUPLOAD', $event, true, () => { saveFiles('W2FormUPLOAD') })"
                         fieldName="W2FormUPLOAD"
                         :deleteAllowed="!isFieldHidden('deleteUsersDocuments') && !isFieldReadOnly('deleteUsersDocuments')"
                         uploadFolder="W2Form"
                         globalSettingSampleName="globalW2FormUPLOAD"
                         globalSettingSampleFolder="globalW2Form"/>
      </v-tab-item>
      <v-tab-item key="W9FormUPLOAD">
        <MultiFileUpload v-if="!isFieldHidden('W9FormUPLOAD')"
                         :downloadAllowed="isFieldDownloadAllowed('W9FormUPLOAD')"
                         :readonly="isFieldReadOnly('W9FormUPLOAD')"
                         :field="fields.W9FormUPLOAD"
                         :label="$t('User.Fields.W9FormUPLOAD')"
                         @change="fileChange('W9FormUPLOAD', $event, true, () => { saveFiles('W9FormUPLOAD') })"
                         fieldName="W9FormUPLOAD"
                         :deleteAllowed="!isFieldHidden('deleteUsersDocuments') && !isFieldReadOnly('deleteUsersDocuments')"
                         uploadFolder="W9Form"
                         globalSettingSampleName="globalW9FormUPLOAD"
                         globalSettingSampleFolder="globalW9Form"
                         />
      </v-tab-item>

      <template v-if="userRole === $store.getters.roleDispatcher">
        <v-tab-item key="paymentDetailsUPLOAD">
          <MultiFileUpload v-if="!isFieldHidden('paymentDetailsUPLOAD')"
                           :downloadAllowed="isFieldDownloadAllowed('paymentDetailsUPLOAD')"
                           :readonly="isFieldReadOnly('paymentDetails')"
                           :field="fields.paymentDetailsUPLOAD"
                           :label="$t('User.Fields.paymentDetailsUPLOAD')"
                           @change="fileChange('paymentDetailsUPLOAD', $event, true, () => { saveFiles('paymentDetailsUPLOAD') })"
                           fieldName="paymentDetailsUPLOAD"
                           :deleteAllowed="!isFieldHidden('deleteUsersDocuments') && !isFieldReadOnly('deleteUsersDocuments')"
                           uploadFolder="paymentDetails"></MultiFileUpload>
        </v-tab-item>
      </template>


      <template v-if="userRole === $store.getters.roleDriver">
        <v-tab-item key="driverDrivingLicenseUPLOAD">
          <MultiFileUpload v-if="!isFieldHidden('driverDrivingLicenseUPLOAD')"
                           :downloadAllowed="isFieldDownloadAllowed('driverDrivingLicenseUPLOAD')"
                           :readonly="isFieldReadOnly('driverDrivingLicenseUPLOAD')"
                           :field="fields.driverDrivingLicenseUPLOAD"
                           :label="$t('User.Fields.driverDrivingLicenseUPLOAD')"
                           @change="fileChange('driverDrivingLicenseUPLOAD', $event, true, () => { saveFiles('driverDrivingLicenseUPLOAD') })"
                           fieldName="driverDrivingLicenseUPLOAD"
                           :deleteAllowed="!isFieldHidden('deleteUsersDocuments') && !isFieldReadOnly('deleteUsersDocuments')"
                           uploadFolder="driverDrivingLicense"></MultiFileUpload>
        </v-tab-item>
        <v-tab-item key="driverMedicalCardUPLOAD">
          <MultiFileUpload v-if="!isFieldHidden('driverMedicalCardUPLOAD')"
                           :downloadAllowed="isFieldDownloadAllowed('driverMedicalCardUPLOAD')"
                           :readonly="isFieldReadOnly('driverMedicalCardUPLOAD')"
                           :field="fields.driverMedicalCardUPLOAD"
                           :label="$t('User.Fields.driverMedicalCardUPLOAD')"
                           @change="fileChange('driverMedicalCardUPLOAD', $event, true, () => { saveFiles('driverMedicalCardUPLOAD') })"
                           fieldName="driverMedicalCardUPLOAD"
                           :deleteAllowed="!isFieldHidden('deleteUsersDocuments') && !isFieldReadOnly('deleteUsersDocuments')"
                           uploadFolder="driverMedicalCard"></MultiFileUpload>
        </v-tab-item>
        <v-tab-item key="driverWorkPermitOrGreenCardUPLOAD">
          <MultiFileUpload v-if="!isFieldHidden('driverWorkPermitOrGreenCardUPLOAD')"
                           :downloadAllowed="isFieldDownloadAllowed('driverWorkPermitOrGreenCardUPLOAD')"
                           :readonly="isFieldReadOnly('driverWorkPermitOrGreenCardUPLOAD')"
                           :field="fields.driverWorkPermitOrGreenCardUPLOAD"
                           :label="$t('User.Fields.driverWorkPermitOrGreenCardUPLOAD')"
                           @change="fileChange('driverWorkPermitOrGreenCardUPLOAD', $event, true, () => { saveFiles('driverWorkPermitOrGreenCardUPLOAD') })"
                           fieldName="driverWorkPermitOrGreenCardUPLOAD"
                           :deleteAllowed="!isFieldHidden('deleteUsersDocuments') && !isFieldReadOnly('deleteUsersDocuments')"
                           uploadFolder="driverWorkPermitOrGreenCard"></MultiFileUpload>
        </v-tab-item>
      </template>
      <template v-if="userRole === $store.getters.roleDriverOwnerOperator">
        <v-tab-item key="driverOwnerOperatorIENUPLOAD">
          <MultiFileUpload v-if="!isFieldHidden('driverOwnerOperatorIENUPLOAD')"
                           :downloadAllowed="isFieldDownloadAllowed('driverOwnerOperatorIENUPLOAD')"
                           :readonly="isFieldReadOnly('driverOwnerOperatorIENUPLOAD')"
                           :field="fields.driverOwnerOperatorIENUPLOAD"
                           :label="$t('User.Fields.driverOwnerOperatorIENUPLOAD')"
                           @change="fileChange('driverOwnerOperatorIENUPLOAD', $event)"
                           fieldName="driverOwnerOperatorIENUPLOAD"
                           :deleteAllowed="!isFieldHidden('deleteUsersDocuments') && !isFieldReadOnly('deleteUsersDocuments')"
                           uploadFolder="driverOwnerOperatorIEN"></MultiFileUpload>
        </v-tab-item>
        <v-tab-item key="driverOwnerOperatorInsuranceUPLOAD">
          <MultiFileUpload v-if="!isFieldHidden('driverOwnerOperatorInsuranceUPLOAD')"
                           :downloadAllowed="isFieldDownloadAllowed('driverOwnerOperatorInsuranceUPLOAD')"
                           :readonly="isFieldReadOnly('driverOwnerOperatorInsuranceUPLOAD')"
                           :field="fields.driverOwnerOperatorInsuranceUPLOAD"
                           :label="$t('User.Fields.driverOwnerOperatorInsuranceUPLOAD')"
                           @change="fileChange('driverOwnerOperatorInsuranceUPLOAD', $event)"
                           fieldName="driverOwnerOperatorInsuranceUPLOAD"
                           :deleteAllowed="!isFieldHidden('deleteUsersDocuments') && !isFieldReadOnly('deleteUsersDocuments')"
                           uploadFolder="driverOwnerOperatorInsurance"></MultiFileUpload>
        </v-tab-item>
        <v-tab-item key="driverOwnerOperatorAuthorityFormUSDOTUPLOAD">
          <MultiFileUpload v-if="!isFieldHidden('driverOwnerOperatorAuthorityFormUSDOTUPLOAD')"
                           :downloadAllowed="isFieldDownloadAllowed('driverOwnerOperatorAuthorityFormUSDOTUPLOAD')"
                           :readonly="isFieldReadOnly('driverOwnerOperatorAuthorityFormUSDOTUPLOAD')"
                           :field="fields.driverOwnerOperatorAuthorityFormUSDOTUPLOAD"
                           :label="$t('User.Fields.driverOwnerOperatorAuthorityFormUSDOTUPLOAD')"
                           @change="fileChange('driverOwnerOperatorAuthorityFormUSDOTUPLOAD', $event)"
                           fieldName="driverOwnerOperatorAuthorityFormUSDOTUPLOAD"
                           :deleteAllowed="!isFieldHidden('deleteUsersDocuments') && !isFieldReadOnly('deleteUsersDocuments')"
                           uploadFolder="driverOwnerOperatorAuthorityFormUSDOT"></MultiFileUpload>
        </v-tab-item>
        <v-tab-item key="driverOwnerOperatorAuthorityFormMCUPLOAD">
          <MultiFileUpload v-if="!isFieldHidden('driverOwnerOperatorAuthorityFormMCUPLOAD')"
                           :downloadAllowed="isFieldDownloadAllowed('driverOwnerOperatorAuthorityFormMCUPLOAD')"
                           :readonly="isFieldReadOnly('driverOwnerOperatorAuthorityFormMCUPLOAD')"
                           :field="fields.driverOwnerOperatorAuthorityFormMCUPLOAD"
                           :label="$t('User.Fields.driverOwnerOperatorAuthorityFormMCUPLOAD')"
                           @change="fileChange('driverOwnerOperatorAuthorityFormMCUPLOAD', $event)"
                           fieldName="driverOwnerOperatorAuthorityFormMCUPLOAD"
                           :deleteAllowed="!isFieldHidden('deleteUsersDocuments') && !isFieldReadOnly('deleteUsersDocuments')"
                           uploadFolder="driverOwnerOperatorAuthorityFormMC"></MultiFileUpload>
        </v-tab-item>
        <v-tab-item key="driverOwnerOperatorAuthorityFormCAUPLOAD">
          <MultiFileUpload v-if="!isFieldHidden('driverOwnerOperatorAuthorityFormCAUPLOAD')"
                           :downloadAllowed="isFieldDownloadAllowed('driverOwnerOperatorAuthorityFormCAUPLOAD')"
                           :readonly="isFieldReadOnly('driverOwnerOperatorAuthorityFormCAUPLOAD')"
                           :field="fields.driverOwnerOperatorAuthorityFormCAUPLOAD"
                           :label="$t('User.Fields.driverOwnerOperatorAuthorityFormCAUPLOAD')"
                           @change="fileChange('driverOwnerOperatorAuthorityFormCAUPLOAD', $event)"
                           fieldName="driverOwnerOperatorAuthorityFormCAUPLOAD"
                           :deleteAllowed="!isFieldHidden('deleteUsersDocuments') && !isFieldReadOnly('deleteUsersDocuments')"
                           uploadFolder="driverOwnerOperatorAuthorityFormCA"></MultiFileUpload>
        </v-tab-item>
        <v-tab-item key="driverOwnerOperatorStatementOfInformationCorporationUPLOAD">
          <MultiFileUpload v-if="!isFieldHidden('driverOwnerOperatorStatementOfInformationCorporationUPLOAD')"
                           :downloadAllowed="isFieldDownloadAllowed('driverOwnerOperatorStatementOfInformationCorporationUPLOAD')"
                           :readonly="isFieldReadOnly('driverOwnerOperatorStatementOfInformationCorporationUPLOAD')"
                           :field="fields.driverOwnerOperatorStatementOfInformationCorporationUPLOAD"
                           :label="$t('User.Fields.driverOwnerOperatorStatementOfInformationCorporationUPLOAD')"
                           @change="fileChange('driverOwnerOperatorStatementOfInformationCorporationUPLOAD', $event)"
                           fieldName="driverOwnerOperatorStatementOfInformationCorporationUPLOAD"
                           :deleteAllowed="!isFieldHidden('deleteUsersDocuments') && !isFieldReadOnly('deleteUsersDocuments')"
                           uploadFolder="driverOwnerOperatorStatementOfInformationCorporation"></MultiFileUpload>
        </v-tab-item>
      </template>
      <template v-if="userRole === $store.getters.roleBroker">
        <v-tab-item key="brokerSetupPackageUPLOAD">
          <MultiFileUpload v-if="!isFieldHidden('brokerSetupPackageUPLOAD')"
                           :downloadAllowed="isFieldDownloadAllowed('brokerSetupPackageUPLOAD')"
                           :readonly="isFieldReadOnly('brokerSetupPackageUPLOAD')"
                           :field="fields.brokerSetupPackageUPLOAD"
                           :label="$t('User.Fields.brokerSetupPackageUPLOAD')"
                           @change="fileChange('brokerSetupPackageUPLOAD', $event)"
                           fieldName="brokerSetupPackageUPLOAD"
                           :deleteAllowed="!isFieldHidden('deleteUsersDocuments') && !isFieldReadOnly('deleteUsersDocuments')"
                           uploadFolder="brokerSetupPackage">
          </MultiFileUpload>
        </v-tab-item>
      </template>
    </v-tabs-items>
  </div>
</template>

<script>
import MultiFileUpload from '../../components/MultiFileUpload';
import fieldLoadingMixin from '../../mixins/fieldLoadingMixin';
import fieldAccessRightMixin from '../../mixins/fieldAccessRightsMixin';
import userFormsCommonMixin from '../../mixins/userFormsCommonMixin';

export default {
  props: ['userId', 'userRole', 'fields', 'isOwnSettings'],
  components: { MultiFileUpload },
  mixins: [fieldLoadingMixin, fieldAccessRightMixin, userFormsCommonMixin],
  data: () => ({
    documentsTab: 0
  }),
  mounted () {
    this.updatingEnabled = true;
    this.applyRights(this.isOwnSettings ? '' : this.userRole, this.isOwnSettings ? 'settings' : 'users', { deleteUsersDocuments: 0 });
  },
  methods: {
    saveFiles (field) {
      let fields = {};
      fields[field] = this.fields[field];
      this.$root.updateUserFields(fields, this.userId).then((response) => {
        if (response.status === 'ok') {
          this.$root.toast(this.$i18n.t('User.UserDocumentsSaved'), { color: 'success' });
        } else {
          this.$root.toast(this.$i18n.t('User.UserDocumentsSaveError') + ': ' + response.msg, { color: 'error' });
        }
      });
    }
  }
}
</script>
