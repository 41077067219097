<template>
  <v-app v-if="!loading">
    <v-overlay :value="$root.globalLoading" :absolute="true">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-system-bar v-if="((!$store.getters.isUserRoleDriver && !$store.getters.isUserRoleDriverOO) || $root.isFullUI) && $store.getters.appVersion !== null && $store.getters.appVersion !== version"
                  class="text-no-wrap text-center"
                  style="z-index:9999999;position: fixed;left: 0;bottom: 0;width: 100%;background-color: #f44336;color: white;">
      <v-spacer></v-spacer>
      <span v-if="!$root.isMobile">{{$t('OldVersionMessage')}}&nbsp;</span><v-btn class="ma-1" color="primary" x-small @click="$root.reloadNewVersion"><v-icon x-small color="white">mdi-refresh</v-icon>{{$t('Refresh')}}</v-btn>
      <v-spacer></v-spacer>
    </v-system-bar>

    <!--PRINT-->

    <template v-if="!$root.sendViaEmailModal && ($route.name === 'invoicePrint' || $route.name === 'reportPrint' || $route.name === 'RegistrationFormPrint')">
      <div style="position: absolute; top: 0px; left: 0px; z-index: 99999;" class="no-print">
        <!--<v-btn @click="$router.go(-1)" text>
          <v-icon left>mdi-arrow-left-thick</v-icon><template v-if="!$root.isMobile">{{$t('Back')}}</template>
        </v-btn>-->
        <v-btn @click="$root.print" text>
          <v-icon left>mdi-printer</v-icon><template v-if="!$root.isMobile">{{$t('Print')}}</template>
        </v-btn>
        <template v-if="$route.name === 'RegistrationFormPrint'">
          <v-btn @click="$root.onDownloadRegistration($route.params.id, true)" text :loading="$root.viewReportLoading">
            <v-icon left>mdi-pdf-box</v-icon><template v-if="!$root.isMobile">{{$t('View')}}</template>
          </v-btn>
          <v-btn @click="$root.onDownloadRegistration($route.params.id, false)" text :loading="$root.viewReportLoading">
            <v-icon left>mdi-download</v-icon><template v-if="!$root.isMobile">{{$t('Download')}}</template>
          </v-btn>
        </template>
        <template v-if="$route.name === 'reportPrint'">
          <v-btn @click="$root.onDownloadReport($route.params.type, $route.params.item, $route.params.from, $route.params.to, $route.params.selected, $route.params.report_number, $route.params.id, $route.params.isDOT || 0, true, false)" text :loading="$root.viewReportLoading">
            <v-icon left>mdi-pdf-box</v-icon><template v-if="!$root.isMobile">{{$t('View')}}</template>
          </v-btn>
          <!--<v-btn @click="$root.onDownloadReport($route.params.type, $route.params.item, $route.params.from, $route.params.to, $route.params.selected, $route.params.report_number, $route.params.id, $route.params.isDOT, true, true)" text :loading="$root.viewInNewWindowsReportLoading">
            <v-icon small>mdi-open-in-new</v-icon>
          </v-btn>-->
          <v-btn @click="$root.onDownloadReport($route.params.type, $route.params.item, $route.params.from, $route.params.to, $route.params.selected, $route.params.report_number, $route.params.id, $route.params.isDOT || 0, false)" text :loading="$root.downloadReportLoading">
            <v-icon left>mdi-download</v-icon><template v-if="!$root.isMobile">{{$t('Download')}}</template>
          </v-btn>
          <v-btn @click="$root.onSendReport($route.params.type, $route.params.item, $route.params.from, $route.params.to, $route.params.selected, $route.params.report_number, $route.params.id, $route.params.isDOT || 0)" text :loading="$root.sendReportLoading">
            <v-icon left>mdi-send</v-icon><template v-if="!$root.isMobile">{{$t('Send')}}</template>
          </v-btn>
        </template>
      </div>
    </template>

    <template v-if="$route.name !== 'invoicePrint' && $route.name !== 'reportPrint' && $route.name !== 'RegistrationFormPrint'">
      <!--<v-navigation-drawer v-if="drawerRight && $root.isChatPage" v-model="drawerRight" app clipped right width="400px">
        <ThreadsList @changed="drawerRight=false;"/>
      </v-navigation-drawer>-->

      <template v-if="($store.getters.role === 'Driver' || $store.getters.role === 'DriverOwnerOperator') && !$root.isFullUI">
        <v-system-bar height="60" color="warning" v-if="$cookies.get('ogt')">
          <v-spacer></v-spacer>
          <span class="font-weight-bold white--text">
          {{$t('ViewAsMode')}}:{{$root.getLocalStorageItem('viewAsName')}}
          </span><br/>
          <v-btn class="ma-1" x-small @click="$root.backFromViewAs">{{$t('BackToOwnUser')}}</v-btn>
          <v-spacer></v-spacer>
        </v-system-bar>
        <v-bottom-navigation
          :value="$root.bottomNavActiveBtn"
          :style="{backgroundColor: $root.currentTheme.appBar + ' !important'}"
          dark grow fixed app
        >
          <!--<v-btn @click="$root.route('/loads/driverPage/' + $root.lastActiveLoad)" :disabled="$root.activeLoadsCount === 0 || $root.lastActiveLoad === 0">
            <span>Active</span>
            <v-badge
              color="success"
              :content="$root.activeLoadsCount"
              overlap bordered light
            >
              <v-icon>mdi-dolly</v-icon>
            </v-badge>
          </v-btn>-->

          <template v-if="$store.getters.isUserRoleDriver">
            <v-btn text @click="$store.getters.activeShiftId > 0 ? $root.route('/loads/driverLoads/shifts/' + $store.getters.activeShiftId) : $root.route('/shifts/driverList')">
              <span>{{$t('LeftMenu.Shifts')}}</span>
              <v-icon>mdi-map-marker-path</v-icon>
            </v-btn>
          </template>
          <template v-if="$store.getters.isUserRoleDriverOO">
            <v-btn text @click="$root.route('/loads/feed')">
              <span>{{$t('LeftMenu.LoadsFeed')}}</span>
              <v-icon>mdi-rss-box</v-icon>
            </v-btn>
            <v-btn text @click="$root.route('/bids/list')">
              <span>{{$t('LeftMenu.Bids')}}</span>
              <v-icon>mdi-file-question</v-icon>
            </v-btn>
          </template>

          <v-btn text @click="$root.route('/driverLoads/list')">
            <span>{{$t('LeftMenu.Loads')}}</span>
            <v-icon>mdi-dolly</v-icon>
          </v-btn>

          <v-btn text @click="$root.route('/chats/list')" v-if="!$store.getters.isUserRoleDriverOO"
                 :disabled="!$root.isAccessRightAllowView($store.getters.menuRights['Chats'])">
            <span>{{$t('LeftMenu.Chat')}}</span>
            <template v-if="$store.getters.isHaveUnreadChatMessages">
              <v-badge overlap color="red">
                <template v-slot:badge>{{ parseInt($store.getters.unreadChatMessages) < 100 ? $store.getters.unreadChatMessages : '99+'}}</template>
                <v-icon>mdi-chat</v-icon>
              </v-badge>
            </template>
            <template v-else>
              <v-icon>mdi-chat</v-icon>
            </template>
          </v-btn>
          <v-btn text @click="$root.route('/user/settings')">
            <span v-if="$store.getters.appDriverVersion !== driverVersion">
              {{$t('User.Settings.Update')}}
            </span>
            <span v-else>{{$t('LeftMenu.Settings')}}</span>
            <v-icon :color="($store.getters.isUserRoleDriver || $store.getters.isUserRoleDriverOO) && $store.getters.appDriverVersion !== null && $store.getters.appDriverVersion !== driverVersion ? 'warning' : null"
                    v-html="$store.getters.appDriverVersion !== driverVersion ? 'mdi-cellphone-arrow-down' : 'mdi-account-circle'">
            </v-icon>
          </v-btn>
        </v-bottom-navigation>
      </template>

      <template v-if="($store.getters.role === 'Driver' || $store.getters.role === 'DriverOwnerOperator') && $root.isMobile && this.$route.path !== '/chats/list'">
        <!--<v-navigation-drawer v-model="driverPendingFileDrawer" right app clipped width="90%">
          <v-btn icon @click="driverPendingFileDrawer=false;"><v-icon>mdi-close</v-icon></v-btn>
          <PendingFilesList :key="'driverPendingFileDrawer' + $root.driverPendingFilesCount"/>
        </v-navigation-drawer>-->

        <v-btn
          v-if="$root.driverPendingFilesCount > 0"
          @click="() => { driverPendingFileDrawer = !driverPendingFileDrawer;}"
          style="margin-bottom: 6rem;"
          color="info"
          absolute
          small
          bottom
          fixed
          right
          fab>
          <v-badge overlap color="green">
            <template v-slot:badge>{{ parseInt($root.driverPendingFilesCount) < 100 ? $root.driverPendingFilesCount : '99+'}}</template>
            <v-icon color="white">mdi-file-sync</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-navigation-drawer v-model="driverNotificationsDrawer" v-if="$root.isMobile" right app clipped width="90%">
        <v-btn icon @click="driverNotificationsDrawer=false;"><v-icon>mdi-close</v-icon></v-btn>
        <NotificationsList @close="driverNotificationsDrawer = !driverNotificationsDrawer" :isDrawer="true"/>
      </v-navigation-drawer>

     <!-- <v-btn
        v-if="$root.isMobile && $store.getters.isHaveNewifications && $root.driverPendingFilesCount === 0"
        @click="driverNotificationsDrawer = !driverNotificationsDrawer"
        style="margin-bottom: 6rem; margin-left: 6rem"
        color="pink"
        absolute
        small
        bottom
        fixed
        right
        fab>
        <v-badge overlap color="green">
          <template v-slot:badge>{{ parseInt($store.getters.newNotificationsCount) < 100 ? $store.getters.newNotificationsCount : '99+'}}</template>
          <v-icon color="white">mdi-bell</v-icon>
        </v-badge>
      </v-btn>-->

      <v-btn
        v-if="($root.isMobile || $store.getters.isUserRoleDriver || $store.getters.isUserRoleDriverOO) && this.$route.path.indexOf('/chats/list') < 0"
        @click="$root.refresh()"
        style="margin-bottom: 6rem;"
        absolute
        small
        bottom
        :color="!$root.isOnline ? 'red' : null"
        fixed
        left
        fab>
        <v-icon small v-if="$root.isOnline">mdi-refresh</v-icon>
        <v-icon small v-else class="white--text">mdi-access-point-network-off</v-icon>
      </v-btn>

      <v-btn v-if="$root.isMobile && $root.isAccessRightAllowView($store.getters.menuRights['Notifications']) && $store.getters.isHaveNewifications"
             @click="$root.route('/notifications/list')"
             style="margin-bottom: 6rem;"
             absolute
             small
             bottom
             color="success"
             fixed
             right
             fab>
          <v-badge overlap>
            <template v-slot:badge>{{ parseInt($store.getters.newNotificationsCount) < 100 ? $store.getters.newNotificationsCount : '99+'}}</template>
            <v-icon>mdi-bell</v-icon>
          </v-badge>
      </v-btn>

      <template v-if="($store.getters.role !== 'Driver' && $store.getters.role !== 'DriverOwnerOperator') || $root.isFullUI">
        <v-app-bar app clipped-right dark :color="$root.currentTheme.appBar" :style="{backgroundColor: $root.currentTheme.appBar + ' !important'}">
          <v-app-bar-nav-icon v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          <v-btn v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm" @click.stop="onLeftDrawerToMini()" icon>
            <v-icon v-if="mini">fa-chevron-right</v-icon>
            <v-icon v-else>fa-chevron-left</v-icon>
          </v-btn>
          <!--<img v-if="$vuetify.breakpoint.sm" height="90%"
               :src="`${publicPath}img/hyperion_logo_icon.svg`"/>
          <img v-else-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm" height="90%" :style="!$vuetify.breakpoint.xs ? 'margin-left:10px;':'margin-left:48px;'"
               :src="`${publicPath}img/hyperion_full_logo_white.svg`"/>-->

          <v-tabs v-if="$root.$store.getters.isUserRoleSuperAdmin && !$root.isMobile" light :style="{backgroundColor: $root.currentTheme.appBar + ' !important'}" active-class="tabs-active-color">
            <v-tab
              :style="{
                backgroundColor: $root.currentTheme.appBar + ' !important',
                color: parseInt($root.companyId) === 0 ? '#fff' : 'rgba(255, 255, 255, 0.6)',
                textDecoration: parseInt($root.companyId) === 0 ? 'underline' : 'none',
                textDecorationThickness: parseInt($root.companyId) === 0 ? '3px' : '0px' }"
              key="0"
              @click="$root.showCRMDashboard()"
            >
              <v-list-item-title>CRM</v-list-item-title>
            </v-tab>
            <template v-for="(item) in $root.companiesList">
              <v-tab
                :style="{
                  backgroundColor: $root.currentTheme.appBar + ' !important',
                  color: parseInt($root.companyId) === parseInt(item.id) ? '#fff' : 'rgba(255, 255, 255, 0.6)',
                  textDecoration: parseInt($root.companyId) === parseInt(item.id) ? 'underline' : 'none',
                  textDecorationThickness: parseInt($root.companyId) === parseInt(item.id) ? '3px' : '0px' }"
                :key="item.id"
                @click="$root.setActiveCompany(item.id)"
              >
                {{ item.name }}
              </v-tab>
            </template>
          </v-tabs>
          <v-menu v-else-if="$root.$store.getters.isUserRoleSuperAdmin"
                  offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                <template v-if="parseInt($root.companyId) === 0">CRM</template>
                <template v-else>{{$root.companyName}}</template>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :key="'CRM_Company'"
                @click="$root.showCRMDashboard(0)"
              >
                <v-list-item-title>CRM</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-for="(item) in $root.companiesList"
                :key="item.id"
                @click="$root.setActiveCompany(item.id)"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>

          </v-menu>
          <template v-else>
            {{$root.companyName}}
          </template>
          <template v-if="$root.isMobile">
            <v-spacer></v-spacer>
            <v-btn icon @click="$root.searchDlg"><v-icon>mdi-magnify</v-icon></v-btn>
          </template>

          <v-text-field v-else
            v-model="searchStr"
            :label="$root.t('SearchString')"
            class="mt-7 ml-2 mr-4"
            style="min-width: 500px;"
            v-on:keyup.enter="doSearch"
            outlined dense>
            <!--<template v-slot:prepend-inner>
              <v-icon large>mdi-magnify</v-icon>
            </template>-->
            <template v-slot:append>
              <v-btn small light class="mb-2" @click="doSearch">{{$t('Search')}}</v-btn>
            </template>
          </v-text-field>

          <v-tooltip bottom v-if="$store.getters.isUserRoleDispatcher">
            <template v-slot:activator="{ on }">
              <v-switch
                class="mt-1"
                color="success"
                v-on="on"
                v-model="$root.isStatusActive"
                inset
                persistent-hint
                :hint="$root.isStatusActive ? $t('IsActive') : $t('IsAway')"
                @change="isAwayStatusChanged()"
              ></v-switch>
            </template>
            <span>{{$t('DispatcherAwaySwitch')}}</span>
          </v-tooltip>

          <!--<v-tooltip bottom v-if="$root.isAccessRightAllowView($store.getters.menuRights['Tasks']) && !$root.isMobile">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="$root.route('/tasks/list')">
                <template v-if="$store.getters.isHaveUnreadTasks">
                  <v-badge overlap color="red">
                    <template v-slot:badge>{{ parseInt($store.getters.unreadTasks) < 100 ? $store.getters.unreadTasks : '99+'}}</template>
                    <v-icon>mdi-clipboard-list</v-icon>
                  </v-badge>
                </template>
                <template v-else>
                  <v-icon>mdi-clipboard-list</v-icon>
                </template>
              </v-btn>
            </template>
            <span>{{$t('LeftMenu.Tasks')}}</span>
          </v-tooltip>-->

          <v-tooltip bottom v-if="$root.isAccessRightAllowView($store.getters.menuRights['Chats'])">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="$root.route('/chats/list')">
                <template v-if="$store.getters.isHaveUnreadChatMessages">
                  <v-badge overlap color="red">
                    <template v-slot:badge>{{ parseInt($store.getters.unreadChatMessages) < 100 ? $store.getters.unreadChatMessages : '99+'}}</template>
                    <v-icon>mdi-chat</v-icon>
                  </v-badge>
                </template>
                <template v-else>
                  <v-icon>mdi-chat</v-icon>
                </template>
              </v-btn>
            </template>
            <span>{{$t('LeftMenu.Chat')}}</span>
          </v-tooltip>

          <!--<v-menu offset-y v-if="$root.$store.getters.isUserRoleSuperAdmin">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-on="on">
                <v-icon>mdi-domain</v-icon><v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in $root.companiesList"
                :key="index"
                @click="$root.setActiveCompany(item.id)"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$root.route('/companies/list')">{{$t('Companies.ManageAll')}}</v-list-item>
            </v-list>
          </v-menu>-->

          <v-tooltip bottom v-if="!$root.isMobile && $root.isAccessRightAllowView($store.getters.menuRights['Notifications'])">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="$root.route('/notifications/list')">
                <template v-if="$store.getters.isHaveNewifications">
                  <v-badge overlap color="green">
                    <template v-slot:badge>{{ parseInt($store.getters.newNotificationsCount) < 100 ? $store.getters.newNotificationsCount : '99+'}}</template>
                    <v-icon>mdi-bell</v-icon>
                  </v-badge>
                </template>
                <template v-else>
                  <v-icon>mdi-bell</v-icon>
                </template>
              </v-btn>
            </template>
            <span>{{$t('LeftMenu.Notifications')}}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="$root.isAccessRightAllowView($store.getters.menuRights['Mails'])">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="$root.route('/mails/list')">
                <template v-if="parseInt($store.getters.currentUser['statusIsHaveNewEmails']) === 1 && parseInt($store.getters.newMailsCount) > 0">
                  <v-badge overlap color="green">
                    <template v-slot:badge>{{$store.getters.newMailsCount < 100 ? $store.getters.newMailsCount : '99+'}}</template>
                    <v-icon>mdi-email-multiple</v-icon>
                  </v-badge>
                </template>
                <template v-else>
                  <v-icon>mdi-email-multiple</v-icon>
                </template>
              </v-btn>
            </template>
            <span>{{$t('LeftMenu.Mails')}}</span>
          </v-tooltip>

          <v-btn icon v-if="($root.isContextCreate || $root.isShowTopCreateBtn) && !$root.isHideTopCreateBtn" @click="$root.contextCreate()"><v-icon>mdi-plus</v-icon></v-btn>
          <v-btn icon v-if="($root.isContextSave || $root.isShowTopSaveBtn) && !$root.isHideTopSaveBtn" @click="$root.contextSave()"><v-icon>fa-save</v-icon></v-btn>
          <v-btn icon v-if="($root.isContextEdit || $root.isShowTopEditBtn) && !$root.isHideTopEditBtn" @click="$root.contextEdit()"><v-icon>mdi-pen</v-icon></v-btn>
          <v-btn icon v-if="!$root.isMobile" @click="$root.refresh()"><v-icon>mdi-refresh</v-icon></v-btn>
          <!--<v-app-bar-nav-icon @click.stop="drawerRight = !drawerRight" v-if="$root.isChatPage && $root.isMobile">
            <v-icon>mdi-message-text</v-icon>
          </v-app-bar-nav-icon>-->
        </v-app-bar>

        <v-navigation-drawer v-if="$root.isMobile" v-model="drawer" app>
          <LeftMenu v-if="$store.getters.menuRights"/>
        </v-navigation-drawer>
        <v-navigation-drawer v-else app permanent :mini-variant="mini" :style="mini ? { width: '64px'} : null">
          <LeftMenu v-if="$store.getters.menuRights"/>
        </v-navigation-drawer>
      </template>
    </template>

    <v-main>
      <template v-if="($route.name !== 'invoicePrint' && $route.name !== 'reportPrint' && $route.name !== 'RegistrationFormPrint') && $store.getters.role !== 'Driver' && $store.getters.role !== 'DriverOwnerOperator'">
        <v-layout :style="{position:'fixed', width:'100%', zIndex:5}">
          <v-flex :shrink="!$root.isMobile" :style="$vuetify.theme.dark ? { backgroundColor: '#121e2d' } : { backgroundColor: '#ffffff' }">
            <v-btn-toggle>
              <v-btn v-if="$route.path !== '/'" @click="$router.go(-1)" small text>
                <v-icon left small>mdi-arrow-left-thick</v-icon>{{$t('Back')}}
              </v-btn>
              <v-btn @click="$root.goToTheLastListPath()" small text :disabled="!$root.isLastListPath">
                <v-icon left small>mdi-view-list</v-icon>{{$t('BackToList')}}
              </v-btn>
            </v-btn-toggle>
          </v-flex>
          <v-flex grow v-if="!$root.isMobile">
            <v-tabs v-model="$root.pageTabIndex[$root.companyId]" @change="tabChanged" show-arrows height="30px" active-class="tabs-active-color">
              <template v-if="$root.pagesTabs.hasOwnProperty($root.companyId)">
                <template v-for="(item,index) in $root.pagesTabs[$root.companyId]">
                  <v-tab :key="'pageTab' + index">
                    {{item.title}}
                    <v-btn @click.stop="$root.removePageTab(index)" small icon>
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </v-tab>
                </template>
              </template>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon small v-on="on" @click.stop="$root.addNewPageTab()" color="success">
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('OpenNewPageTab')}}</span>
              </v-tooltip>
            </v-tabs>
          </v-flex>
          <v-flex xs1 :style="$vuetify.theme.dark ? { backgroundColor: '#121e2d' } : { backgroundColor: '#ffffff' }">
            <v-btn target="_blank" :href="$route.fullPath" icon small>
              <v-icon small>mdi-open-in-new</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-system-bar height="40" color="warning" :class="$root.isMobile ? 'mt-15' : 'mt-8'"
                      v-if="(($store.getters.role !== 'Driver' && $store.getters.role !== 'DriverOwnerOperator') || $root.isFullUI) && $cookies.get('ogt')">
          <v-spacer></v-spacer>
          <span class="font-weight-bold">
          {{$t('ViewAsMode')}}: {{$root.getLocalStorageItem('viewAsName')}}
          </span>
          <v-btn class="ma-1" x-small @click="$root.backFromViewAs">{{$t('BackToOwnUser')}}</v-btn>
          <v-spacer></v-spacer>
        </v-system-bar>

      </template>
      <v-container fluid id="topNotificationsId"
                   :class="$route.name !== 'invoicePrint' && $store.getters.role !== 'Driver' && $store.getters.role !== 'DriverOwnerOperator' ? 'pt-10 pl-3 pr-1' : null">
        <v-carousel v-if="$store.getters.topNotifications && $store.getters.topNotifications.length > 0 && $route.name !== 'invoicePrint' && $route.name !== 'reportPrint' && $route.name !== 'RegistrationFormPrint'"
                    hide-delimiter-background
                    :hide-delimiters="$store.getters.topNotifications.length < 2 || $root.isMobile"
                    :show-arrows="true"
                    height="auto">
          <v-carousel-item v-for="(item, index) in $store.getters.topNotifications"
                           :key="'topNotification'+index"
                           :style="{ backgroundColor: $vuetify.theme.dark ? $vuetify.theme.themes.dark.background : $vuetify.theme.themes.light.background}">
            <v-alert :type="item.type || 'error'" height="auto" style="padding-left: 4rem; padding-right: 4rem;" :icon="$root.isMobile ? false : null">
              <v-row align="center">
                <v-col class="grow">
                  <h3>{{item.msg}}</h3>
                  <template v-if="item.notes">
                    <span v-html="item.notes.replace(/(?:\r\n|\r|\n)/g, '<br/>')"></span>
                  </template>
                </v-col>
                <v-col class="shrink">
                  <v-btn @click="$root.route(item.url)" light>{{item.action}}</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-carousel-item>
        </v-carousel>

        <v-carousel v-if="$store.getters.needApprovalLoads && $store.getters.needApprovalLoads.length > 0 && $route.name !== 'invoicePrint' && $route.name !== 'reportPrint' && $route.name !== 'RegistrationFormPrint'"
                    :show-arrows="false"
                    hide-delimiter-background
                    :hide-delimiters="$store.getters.needApprovalLoads.length < 2"
                    height="auto">
          <v-carousel-item v-for="(item, index) in $store.getters.needApprovalLoads" :key="'needApprovalLoads'+index">
            <v-alert prominent type="warning" height="auto">
              <v-row align="center">
                <v-col class="grow">
                  <h3>{{$t('Load')}} #{{item.loadId}}</h3>
                  {{$t('Loads.LoadWaitingDispatcherApproval')}}
                </v-col>
                <v-col class="shrink">
                  <v-btn @click="$root.onShowLoadPreview(item.loadId)">{{$t('Open')}}</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-carousel-item>
        </v-carousel>

        <v-carousel v-if="$store.getters.unfinishedLoads && $store.getters.unfinishedLoads.length > 0 && $route.name !== 'invoicePrint' && $route.name !== 'reportPrint' && $route.name !== 'RegistrationFormPrint'"
                    :show-arrows="false"
                    hide-delimiter-background
                    :hide-delimiters="$store.getters.unfinishedLoads.length < 2"
                    height="auto">
          <v-carousel-item v-for="(item, index) in $store.getters.unfinishedLoads" :key="'unfinishedLoads'+index">
            <v-alert prominent type="error" height="auto">
              <v-row align="center">
                <v-col class="grow">
                  <h3>{{$t('Load')}} #{{item.loadId}}</h3>
                  {{$t('Loads.LoadWaitingToBeFinished')}}
                </v-col>
                <v-col class="shrink">
                  <v-btn @click="$root.onShowLoadPreview(item.loadId)">{{$t('Open')}}</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-carousel-item>
        </v-carousel>

        <transition :name="!$root.isMobile ? transitionName : null" :mode="!$root.isMobile ? 'out-in' : null">
          <router-view :key="$route.fullPath" v-if="$root.isRouterAlive"></router-view>
        </transition>
      </v-container>
    </v-main>

    <!-- View As panel-->


    <!--<v-btn
      v-if="$cookies.get('ogt') && ($store.getters.role === 'Driver' || $store.getters.role === 'DriverOwnerOperator') && !$root.isFullUI && $route.path !== '/chats/list'"
      @click="$root.backFromViewAs"
      style="margin-bottom: 6rem;"
      absolute
      small
      bottom
      :color="!$root.isOnline ? 'red' : null"
      fixed
      left
      fab>
      <v-icon small color="error">mdi-cancel</v-icon>
    </v-btn>
    <div v-else-if="$cookies.get('ogt')"
         style="z-index:1;position: fixed;left: 0;bottom: 0; width:100%;background-color: dodgerblue;color: white;text-align: center;">
      {{$t('ViewAsMode')}}: {{$root.getLocalStorageItem('viewAsName')}}
      <v-btn class="ma-1" small @click="$root.backFromViewAs">{{$t('BackToOwnUser')}}</v-btn>
    </div>-->

    <div v-if="!$root.isOnline && !$root.isMobile" style="z-index:999; position: fixed;left: 0;bottom: 0;width: 100%;background-color: #c62828; color: white;text-align: center;">
      <v-icon class="white--text">mdi-access-point-network-off</v-icon><h3>{{$t('OfflineModeMsg')}}</h3>
    </div>

    <v-footer v-if="($route.name !== 'ChatsList' && $route.name !== 'invoicePrint' && $route.name !== 'reportPrint' && $route.name !== 'RegistrationFormPrint') && $store.getters.role !== 'Driver' && $store.getters.role !== 'DriverOwnerOperator'">
      <v-spacer></v-spacer>
      <v-btn small class="ma-1" :href="`${publicPath}nativeApps/HyperionCRM.dmg`">
        <v-icon small left>mdi-apple</v-icon> App
      </v-btn>
      <v-btn small class="ma-1" :href="`${publicPath}nativeApps/Hyperion UTC CRM-win32-x64.zip`">
        <v-icon small left>mdi-microsoft-windows</v-icon> App
      </v-btn>
      <div>&copy; {{ new Date().getFullYear() }}&nbsp;v{{version}}</div>
    </v-footer>

    <v-dialog v-model="$root.loadModal" hide-overlay scrollable fullscreen persistent transition="dialog-bottom-transition">
      <v-card tile>
          <v-toolbar dark :color="$root.currentTheme.primary" dense fixed style="flex:0;">
            <v-toolbar-items>
              <v-btn icon dark @click="() => { $root.loadModalKey = new Date().getTime(); $root.loadModal = false; }"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar-items>
            <v-toolbar-title class="text-no-wrap">
              {{$t('Load')}}&nbsp;#{{$root.selectedLoadId}}
            </v-toolbar-title>
            <v-toolbar-items>
              <v-btn icon @click="$root.route('/loads/page/'+$root.selectedLoadId + '/' + $root.selectedLoadTruckId)"><v-icon>mdi-open-in-new</v-icon></v-btn>
            </v-toolbar-items>
            <span style="color:rgb(1, 90, 170); text-overflow: ellipsis; overflow:hidden; max-width: 1px;">{{$root.loadModalKey}}</span>
            <v-spacer/>
            <v-toolbar-title>
              <v-btn icon @click="() => { $root.loadModalKey = new Date().getTime() }"><v-icon>mdi-reload</v-icon></v-btn>
            </v-toolbar-title>
          </v-toolbar>
        <v-card-content class="pa-0">
          <LoadPage :id="$root.selectedLoadId" :truckId="$root.selectedLoadTruckId" :key="'loadPreview' + $root.selectedLoadId + '_' + $root.loadModalKey"/>
        </v-card-content>
      </v-card>
    </v-dialog>

    <v-dialog v-model="$root.loadEditModal" scrollable fullscreen persistent hide-overlay transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar dark color="#015aaa" dense fixed style="flex:0;">
          <v-toolbar-items>
            <v-btn icon dark @click="() => { $root.loadEditModalKey = new Date().getTime(); $root.loadEditModal = false; }"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar-items>
          <v-toolbar-title class="text-no-wrap">
            {{$t('Load')}}&nbsp;{{$t('Edit')}}&nbsp;#{{$root.selectedLoadId}}
          </v-toolbar-title>
          <v-toolbar-items>
            <v-btn icon @click="$root.route('/loads/edit/'+$root.selectedLoadId)"><v-icon>mdi-open-in-new</v-icon></v-btn>
          </v-toolbar-items>
          <v-spacer/>
          <v-toolbar-title>
            <v-btn icon @click="() => { $root.loadEditModalKey = new Date().getTime() }"><v-icon>mdi-reload</v-icon></v-btn>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-0">
          <LoadEditPage :id="$root.selectedLoadId"
                        :key="'loadEditPreview' + $root.selectedLoadId + '_' + $root.loadEditModalKey"
                        :isOpenedInModal="true"
                        @on-close="() => {
                            $root.loadEditModalKey = new Date().getTime(); $root.loadEditModal = false;
                          }"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="$root.invoiceModal" scrollable fullscreen persistent hide-overlay transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar dark color="#015aaa" dense fixed style="flex:0;">
          <v-toolbar-items>
            <v-btn icon dark @click="$root.invoiceModal = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar-items>
          <v-toolbar-title class="text-no-wrap">
            {{$t('Invoice')}}&nbsp;#{{$root.selectedInvoiceNumber}}
          </v-toolbar-title>
          <v-toolbar-items>
            <v-btn icon @click="$root.route('/invoices/page/'+$root.selectedInvoiceId)"><v-icon>mdi-open-in-new</v-icon></v-btn>
          </v-toolbar-items>
          <v-spacer/>
          <v-toolbar-title>
            <v-btn icon @click="() => { $root.invoiceModalKey = new Date().getTime() }"><v-icon>mdi-reload</v-icon></v-btn>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-0 pt-2">
          <InvoicePage :id="$root.selectedInvoiceId" :key="'invoicePreview' + $root.selectedInvoiceId + '_' + $root.invoiceModalKey"/>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="$root.invoiceEditModal" scrollable fullscreen persistent hide-overlay transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar dark color="#015aaa" dense fixed style="flex:0;">
          <v-toolbar-items>
            <v-btn icon dark @click="$root.invoiceEditModal = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar-items>
          <v-toolbar-title class="text-no-wrap">
            {{$t('Invoice')}}&nbsp;{{$t('Edit')}}&nbsp;#{{$root.selectedInvoiceNumber}}
          </v-toolbar-title>
          <v-toolbar-items>
            <v-btn icon @click="$root.route('/invoices/edit/'+$root.selectedInvoiceId)"><v-icon>mdi-open-in-new</v-icon></v-btn>
          </v-toolbar-items>
          <v-spacer/>
          <v-toolbar-title>
            <v-btn icon @click="() => { $root.invoiceEditModalKey = new Date().getTime() }"><v-icon>mdi-reload</v-icon></v-btn>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-0 pt-2">
          <InvoiceEditPage :id="$root.selectedInvoiceId" :key="'invoiceEditPreview' + $root.selectedInvoiceId + '_' + $root.invoiceEditModalKey" :isOpenedInModal="true" @on-close="() => { $root.invoiceModalKey = new Date().getTime(); $root.invoiceEditModal = false; }"/>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="$root.truckModal" scrollable fullscreen persistent hide-overlay transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar dark color="#015aaa" dense fixed style="flex:0;">
          <v-toolbar-items>
            <v-btn icon dark @click="$root.truckModal = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar-items>
          <v-toolbar-title class="text-no-wrap">
            {{$root.selectedTruckIsTrailer ? $t('Trailer') : $t('Truck')}}:&nbsp;{{$root.selectedTruckName}}
          </v-toolbar-title>
          <v-toolbar-items>
            <v-btn icon @click="$root.route('/trucks/page/'+$root.selectedTruckId)"><v-icon>mdi-open-in-new</v-icon></v-btn>
          </v-toolbar-items>
          <v-spacer/>
          <v-toolbar-title>
            <v-btn icon @click="() => { $root.truckModalKey = new Date().getTime() }"><v-icon>mdi-reload</v-icon></v-btn>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-0 pt-2">
          <TruckPage :id="$root.selectedTruckId" :key="'truckPreview' + $root.selectedTruckId + '_' + $root.truckModalKey"/>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="$root.truckEditModal" scrollable fullscreen persistent hide-overlay transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar dark color="#015aaa" dense fixed style="flex:0;">
          <v-toolbar-items>
            <v-btn icon dark @click="$root.truckEditModal = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar-items>
          <v-toolbar-title class="text-no-wrap">
            {{$t('Truck')}}&nbsp;{{$t('Edit')}}:&nbsp;{{$root.selectedTruckName}}
          </v-toolbar-title>
          <v-toolbar-items>
            <v-btn icon @click="$root.route('/trucks/edit/'+$root.selectedTruckId)"><v-icon>mdi-open-in-new</v-icon></v-btn>
          </v-toolbar-items>
          <v-spacer/>
          <v-toolbar-title>
            <v-btn icon @click="() => { $root.truckEditModalKey = new Date().getTime() }"><v-icon>mdi-reload</v-icon></v-btn>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-0 pt-2">
          <TruckEditPage :id="$root.selectedTruckId" :key="'truckEditPreview' + $root.selectedTruckId + '_' + $root.truckEditModalKey" :isOpenedInModal="true" @on-close="() => { $root.truckModalKey = new Date().getTime(); $root.truckEditModal = false; }"/>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="$root.truckCardModal" max-width="500px">
      <v-card tile>
        <v-card-text class="pa-1">
          <TruckCard :loadsData="$root.selectedTruckFields.loadsData"
                     @close="$root.truckCardModal = false"
                     :key="$root.truckModalKey"
                     :edit_icons="true"
                     :showTrucksImages="false"
                     :showMap="true"
                     :showCloseBtn="true"
                     :truckData="$root.selectedTruckFields"
                     :notifications="$root.selectedTruckFields.notifications"
          ></TruckCard>
          <!--<TruckCard :id="$root.selectedTruckId" :key="'truckEditPreview' + $root.selectedTruckId + '_' + $root.truckEditModalKey" :isOpenedInModal="true" @on-close="() => { $root.truckModalKey = new Date().getTime(); $root.truckEditModal = false; }"/>-->
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="$root.userModal" scrollable fullscreen persistent hide-overlay transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar dark color="#015aaa" dense fixed style="flex:0;">
          <v-toolbar-items>
            <v-btn icon dark @click="$root.userModal = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar-items>
          <v-toolbar-title class="text-no-wrap">
            {{$t('User.Title')}}:&nbsp;{{$root.selectedUserName}}
          </v-toolbar-title>
          <v-toolbar-items>
            <v-btn icon @click="$root.route('/user/page/'+$root.selectedUserId)"><v-icon>mdi-open-in-new</v-icon></v-btn>
          </v-toolbar-items>
          <v-spacer/>
          <v-toolbar-title>
            <v-btn icon @click="() => { $root.userModalKey = new Date().getTime() }"><v-icon>mdi-reload</v-icon></v-btn>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-0 pt-2">
          <UserPage :id="$root.selectedUserId" :key="'userPreview' + $root.selectedUserId + '_' + $root.userModalKey"/>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="$root.userEditModal" scrollable fullscreen persistent hide-overlay transition="dialog-bottom-transition">
      <v-card tile>
        <v-toolbar dark color="#015aaa" dense fixed style="flex:0;">
          <v-toolbar-items>
            <v-btn icon dark @click="$root.userEditModal = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar-items>
          <v-toolbar-title class="text-no-wrap">
            {{$t('User.Title')}}&nbsp;{{$t('Edit')}}:&nbsp;{{$root.selectedUserName}}
          </v-toolbar-title>
          <v-toolbar-items>
            <v-btn icon @click="$root.route('/user/edit/'+$root.selectedUserId)"><v-icon>mdi-open-in-new</v-icon></v-btn>
          </v-toolbar-items>
          <v-spacer/>
          <v-toolbar-title>
            <v-btn icon @click="() => { $root.userEditModalKey = new Date().getTime() }"><v-icon>mdi-reload</v-icon></v-btn>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-0 pt-2">
          <UserEditPage :id="$root.selectedUserId"
                        :key="'truckEditPreview' + $root.selectedUserId + '_' + $root.userEditModalKey"
                        :isOpenedInModal="true"
                        @on-close="() => { $root.userModalKey = new Date().getTime(); $root.userEditModal = false; }"/>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Password confirm-->
    <div class="text-center">
      <v-dialog v-model="passwordDialog" width="500">
        <v-card>
          <v-card-title primary-title>
            {{$t('DecryptionModalTitle')}}
          </v-card-title>
          <v-card-text>
            <v-text-field type="password" v-model="tmpPassword" v-on:keyup.enter="$store.getters.passwordDialogCb(tmpPassword);tmpPassword = ''"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn text @click="$store.commit('setPasswordDialog',false);tmpPassword = ''">{{$t('Cancel')}}</v-btn>
            <v-btn color="primary" text @click="$store.getters.passwordDialogCb(tmpPassword);tmpPassword = ''">{{$t('Decrypt')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="$root.searchDialog"
                fullscreen
                persistent
                hide-overlay
                transition="dialog-bottom-transition">
        <v-card tile>
          <v-card-title class="pa-0 pl-3">
            {{$t("Search")}}
            <v-spacer></v-spacer>
            <v-btn icon class="mr-4" @click="$root.searchDialog=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <Search :key="searchKey"></Search>
        </v-card>
      </v-dialog>

      <v-dialog v-model="$root.selectBrokerDialog" width="350">
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-layout>
                  <h2>{{$t('Bids.SelectBroker')}}</h2><v-spacer/><v-btn icon @click="$root.selectBrokerDialog=false;"><v-icon>mdi-close</v-icon></v-btn>
                  </v-layout>
                  <v-radio-group v-model="$root.selectBrokerDialogData.selectType" row center class="ml-3">
                    <v-radio label="Create" :value="1"></v-radio>
                    <v-radio label="Select" :value="0" selected></v-radio>
                  </v-radio-group>
                  <SelectUser
                    v-if="parseInt($root.selectBrokerDialogData.selectType) === 0"
                    ref="brokerSelect"
                    :key="$root.selectBrokerDialogData.selectedBroker"
                    :label="$t('Bids.SelectBroker')"
                    role="Broker"
                    :value="$root.selectBrokerDialogData.selectedBroker"
                    @change="(value) => { $root.selectBrokerDialogData.selectedBroker = value;}">
                  </SelectUser>
                  <v-text-field
                    v-model="$root.selectBrokerDialogData.newBrokerName"
                    :label="$t('User.Fields.brokerName')"
                    v-if="parseInt($root.selectBrokerDialogData.selectType) === 1"
                    dense outlined
                  ></v-text-field>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span>
                    Email: {{$root.selectBrokerDialogData.email}}
                  </span>
                  <v-switch
                    v-if="parseInt($root.selectBrokerDialogData.selectType) === 0"
                    class="ml-2"
                    v-model="$root.selectBrokerDialogData.addEmailToSelectedBroker"
                    inset
                    :label="$t('Bids.AddEmailToSelectedBroker')"></v-switch>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-btn color="primary" width="100%" v-if="parseInt($root.selectBrokerDialogData.selectType) === 0" @click="$root.selectBrokerBeforeLoad()">{{$t("Select")}}</v-btn>
            <v-btn color="primary" width="100%" v-if="parseInt($root.selectBrokerDialogData.selectType) === 1" @click="$root.createBrokerBeforeLoad()">{{$t("Create")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Default HQ modal -->
      <v-dialog v-model="$root.defaultHQModal" width="350px">
        <v-card>
          <v-card-title>{{$t('SelectHQLocation')}}<v-spacer/><v-btn icon @click="$root.defaultHQModal=false;"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
          <v-card-text>
            <v-list dense>
              <v-list-item v-for="place in $root.defaultHQPlace" :key="place.name"
                           @click="() => { $root.setDefaultHQ(place); $root.defaultHQModal = false; }">
                <v-list-item-icon>
                  <v-icon>mdi-home-map-marker</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{place.name}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="$root.isEditColumnsModal" width="350px">
        <v-card>
          <v-card-title>{{$t('SelectColumns')}}<v-spacer/><v-btn icon @click="$root.isEditColumnsModal=false;"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
          <v-card-text>
            <v-list dense>
              <v-list-item-group
                v-model="$root.editColumnsSettings"
                multiple
                active-class=""
              >
                <template v-for="column in $root.columnsEditModalData">
                  <v-list-item :key="column.value">
                    <template v-slot:default="{ active }" >
                      <v-list-item-action>
                        <v-checkbox :input-value="active"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-title v-if="column.value === 'actions'">{{$t('Actions')}}</v-list-item-title>
                      <v-list-item-title v-else>{{column.text}}</v-list-item-title>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card-text>
          <v-card-actions class="pa-2">
            <v-btn class="primary" width="100%" @click="$root.onEditColumnsSave()"><v-icon left>mdi-content-save</v-icon>{{$t('Save')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="$root.sendViaEmailModal" scrollable fullscreen persistent hide-overlay transition="dialog-bottom-transition">
          <EmailSendForm :attFilesArray="$root.sendViaEmailAttArray"
                       :recipientsList="$root.sendViaEmailRecipients"
                       :title="$root.sendViaEmailTitle"
                       :key="$root.sendViaEmailKey"
                       @closed="()=>{$root.sendViaEmailModal=false;}"
        />
      </v-dialog>

      <v-dialog v-model="$root.overrideFieldDialog" width="350" v-if="$root.isOverrideSalaryAllowed">
        <v-card>
          <v-card-title>
            <h3>{{$t('OverrideField')}}</h3><v-spacer></v-spacer><v-btn icon @click="$root.overrideFieldDialog=false;"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-layout wrap class="pa-2">
            <v-flex xs12>
              <v-text-field v-if="$root.overrideFieldReportId === 0"
                            class="mt-2"
                            v-model="$root.overrideFieldValue"
                            :label="$root.overrideFieldIsTimeFormat ? $t('OverrideFieldTime') : $t('OverrideFieldAmount')"
                            :rules="$root.overrideFieldIsTimeFormat ? $root.durationHHMMRule : $root.numberRule"
                            :key="'overrideFieldValue' + $root.overrideFieldKey"
                            :prefix="$root.overrideFieldPrefix"
                            v-on:keyup.enter="$root.setOverrideFieldValue"
                            :type="$root.overrideFieldIsTimeFormat ? 'text' : 'number'"
                            @mousewheel.prevent="" outlined dense></v-text-field>

              <v-text-field class="mt-2"
                            v-if="$root.overrideFieldHaveDate"
                            v-model="$root.overrideFieldDate"
                            label="Date\Time"
                            :rules="$root.datetimeUSARule"
                            v-on:keyup.enter="$root.setOverrideFieldValue"
                            type="text" @mousewheel.prevent="" outlined dense/>


              <v-textarea :label="$t('Invoices.Notes')"
                          outlined dense
                          v-model="$root.overrideFieldNote">
              </v-textarea>
            </v-flex>
            <v-flex xs12>
              <v-btn v-if="$root.overrideFieldReportId > 0"
                     width="100%"
                     class="primary"
                     @click="() => markReportAsPaid($root.overrideFieldReportId, $root.overrideFieldUser, $root.overrideFieldName, $root.overrideFieldItemType, $root.overrideFieldDate, $root.overrideFieldNote)">
                <v-icon left>mdi-content-save</v-icon>&nbsp;{{$t('Save Report')}}
              </v-btn>
              <v-btn v-else width="100%" @click="$root.setOverrideFieldValue" class="primary">
                <v-icon left>mdi-content-save</v-icon>&nbsp;{{$t('Save')}}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>

      <template v-if="$root.isFullFinancialDataAllowedToView || $root.isDispatcherFinancialDataAllowedToView">
        <v-dialog v-model="$store.getters['reports/savedReportsModal']" persistent @keydown.esc="$root.onCloseReportsModal" :fullscreen="$root.isMobile">
          <v-card flat>
            <v-card-title>
              <h2>Saved Reports</h2><v-spacer/><v-btn icon @click="$root.onCloseReportsModal"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text style="padding-left: 10px; padding-right: 5px;">
              <ReportsGroupsList :type="reportGroupListType"
                                 :userId="reportGroupListItem"
                                 :itemId="4"
                                 :totalsCounts="$store.getters['reports/totalsCounts']"
                                 :totalsCountsNames="$store.getters['reports/totalsCountsNames']"
                                 :totalsCountsTypes="$store.getters['reports/totalsCountsTypes']"
                                 :key="$store.getters['reports/reportsGroupsListKey']"/>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>

      <v-snackbar :timeout="3000"
                  multi-line
                  :color="$root.snackbarColor"
                  v-model="$root.snackbar"
                  elevation="24">
        {{$root.snackbarText}}
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
import LeftMenu from './LeftMenu.vue';
import Search from './../components/Search.vue';
import SelectUser from './../components/SelectUser.vue';
import NotificationsList from './Notifications/List.vue';
import { version, driverVersion } from '../../package.json';
import LoadPage from '../views/Loads/Page';
import LoadEditPage from '../views/Loads/CreateEditForm';
import InvoicePage from '../views/Invoices/Page';
import InvoiceEditPage from '../views/Invoices/CreateEditForm';
import TruckPage from '../views/Trucks/Page';
import TruckEditPage from '../views/Trucks/CreateEditForm';
import TruckCard from '../components/TruckCard';
import UserPage from '../views/User/Page';
import UserEditPage from '../views/User/Settings';
import EmailSendForm from '../components/EmailSendForm';
import ReportsGroupsList from '../views/Reports/ReportsGroupsList.vue';
import W9Form from '../components/W9Form.vue';
import { Buffer } from 'buffer';

export default {
  components: {
    UserPage,
    UserEditPage,
    TruckCard,
    LeftMenu,
    SelectUser,
    Search,
    NotificationsList,
    LoadPage,
    LoadEditPage,
    InvoicePage,
    InvoiceEditPage,
    TruckPage,
    TruckEditPage,
    EmailSendForm,
    ReportsGroupsList,
    W9Form
  },
  props: {
    source: String
  },
  data: () => ({
    input: '',
    search: '',
    publicPath: process.env.BASE_URL,
    version: version,
    driverVersion: driverVersion,
    loading: true,
    error: '',
    polling: null,
    pollingSubscriptionCheck: null,
    drawer: null,
    drawerRight: false,
    driverNotificationsDrawer: false,
    driverPendingFileDrawer: false,
    right: false,
    left: false,
    mini: true,
    transitionName: 'slidel',
    tmpPassword: '',
    searchStr: '',
    searchKey: 'searchDlg'
  }),
  computed: {
    passwordDialog: {
      get () {
        return this.$store.getters.passwordDialog;
      },
      set (value) {
        this.$store.commit('setPasswordDialog', value);
      }
    },
    reportGroupListType() {
      if (!this.$root.isFullFinancialDataAllowedToView && this.$root.isDispatcherFinancialDataAllowedToView) {
        return 'dispatchers';
      } else {
        if (this.$store.getters['reports/reportsSelectedType']) {
          return this.$store.getters['reports/reportsSelectedType'];
        }
        return this.$route.params.type;
      }
    },
    reportGroupListItem() {
      if (!this.$root.isFullFinancialDataAllowedToView && this.$root.isDispatcherFinancialDataAllowedToView) {
        return this.$store.getters.currentUser.id;
      } else {
        if (this.$store.getters['reports/reportsSelectedItem']) {
          return this.$store.getters['reports/reportsSelectedItem'];
        }
        return this.$route.params.item;
      }
    }
  },
  directives: {
    focus: {
      inserted (el) {
        el.focus();
      }
    }
  },
  mounted: function () {
    let isDark = null;
    let isMini = null;
    let locale = null;
    let token = null;
    let companyId = 0;

    window.Buffer = Buffer;

    isDark = this.$root.getLocalStorageItem('isDark');
    if (isDark !== null) {
      this.$root.setDarkTheme(isDark === 'true');
    }
    isMini = this.$root.getLocalStorageItem('isMini');
    if (isMini !== null) {
      this.mini = isMini.toString() === 'true';
    }
    locale = this.$root.getLocalStorageItem('locale');
    if (locale !== null) {
      this.$root.setLanguageLocale(locale);
    }
    companyId = parseInt(this.$root.getLocalStorageItem('currentCompanyId'));
    if (companyId > 0) {
      this.$set(this.$root, 'companyId', companyId);
    }

    token = this.$cookies.get('t');
    if (!token) {
      this.$router.push('/login');
    } else {
      this.$store.commit('setToken', token);
    }

    this.$root.subscribeUser();
    if (token) {
      this.$root.isSubscribedToPushSubscription().then((response) => {
        if (response.hasOwnProperty('msg') && response.msg === 'Not subscribed to push service!') {
          this.$root.subscribeUser().then((response2) => {
            if (response2 && 'toJSON' in response2 && response2.toJSON().endpoint) {
              this.$root.updateUserSubscriptionData(response2.toJSON().endpoint, JSON.stringify(response2.toJSON()));
            }
          });
        } else {
          if (response && 'toJSON' in response && response.toJSON().endpoint) {
            this.$root.updateUserSubscriptionData(response.toJSON().endpoint, JSON.stringify(response.toJSON()));
            this.$store.commit('pushNotificationsEndpointUrl', response.toJSON().endpoint);
          }
        }
      });

      this.$root.loadTabsFromStorage();

      this.$root.getAccessRightsByField('settings', 'driverSwitchToFullUI').then((response) => {
        if (response.status === 'ok') {
          const rights = response.rights;
          let _r = this.$root.getAccessRightsArrayFromNumber(rights);
          if (_r[0] === '0' && _r[1] === '1' && _r[2] === '1') {
            let isFullUI = this.$root.getLocalStorageItem('isFullUI');
            if (isFullUI !== null) {
              this.$root.isFullUI = isFullUI === 'true';
            } else {
              // Preset full ui for non small screen devices
              if (this.$vuetify.breakpoint.xs) {
                this.$root.isFullUI = false;
                this.$root.setLocalStorageItem('isFullUI', this.$root.isFullUI);
              } else {
                this.$root.isFullUI = true;
                this.$root.setLocalStorageItem('isFullUI', this.$root.isFullUI);
              }
            }
          }
        }
      });
      this.$root.getAccessRightsByField('settings', 'driverOwnerOperatorSwitchToFullUI').then((response) => {
        if (response.status === 'ok') {
          const rights = response.rights;
          let _r = this.$root.getAccessRightsArrayFromNumber(rights);
          if (_r[0] === '0' && _r[1] === '1' && _r[2] === '1') {
            let isFullUI = this.$root.getLocalStorageItem('isFullUI');
            if (isFullUI !== null) {
              this.$root.isFullUI = isFullUI === 'true';
            } else {
              // Preset full ui for non small screen devices
              if (this.$vuetify.breakpoint.xs) {
                this.$root.isFullUI = false;
                this.$root.setLocalStorageItem('isFullUI', this.$root.isFullUI);
              } else {
                this.$root.isFullUI = true;
                this.$root.setLocalStorageItem('isFullUI', this.$root.isFullUI);
              }
            }
          }
        }
      });

      this.$root.get('/').then((response) => {
        if (response.hasOwnProperty('status') && response.status === 'ok') {
          this.$store.commit('setAuthenticated', true);
          this.$store.commit('setRole', response.role);
          this.$store.commit('setMenuAccessRights', response.menuRights);
          this.$store.commit('setLoadsAccessRights', response.loadsRights);
          this.$store.commit('setInvoicesAccessRights', response.invoicesRights);
          this.$store.commit('setUsersByRoles', response.data);
          this.$store.commit('setCurrentUser', response.currentUser);
          this.$store.commit('setNewMailsCount', response.newMailsCount);
          this.$store.commit('setNewNotificationsCount', response.newNotificationsCount);
          this.$store.commit('setTopNotifications', response.topNotifications);

          this.$root.isStatusActive = parseInt(response.currentUser.statusIsAway) === 0;

          //this.$root.wsConnect(this.$store.getters.currentUserId);

          /* if (this.$route.path === '/') {
            if (this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Dashboard'])) {
                          this.$root.route('/user/dashboard');
                      } else if (this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Loads'])) {
                          if (response.role === 'Driver' || response.role === 'DriverOwnerOperator') {
                              this.$root.route('/driverLoads/list');
                          } else {
                              this.$root.route('/loads/list');
                          }
                      } else if (this.$root.isAccessRightAllowView(this.$store.getters.menuRights['Invoices'])) {
                          this.$root.route('/ivoices/list');
                      }
          } */

          this.loading = false;
          this.$root.statusUpdate();

          this.polling = setInterval(() => {
            // Do not call interval methods if page not visible
            // if (!this.$store.getters.isPageVisible)return;
            this.$root.statusUpdate();
          }, this.$store.getters.pullIntervalStatus);

          this.pollingSubscriptionCheck = setInterval(() => {
            this.$root.isSubscribedToPushSubscription();
          }, this.$store.getters.checkPushNotificationsStatus);

          this.$root.getGlobalSettingsField('defaultHQPlace').then(response => {
            if (response.status === 'ok') {
              if (response.result && response.result.indexOf('[') === 0) {
                this.$root.defaultHQPlace = JSON.parse(response.result);
              }
            }
          });
        }
      }).catch((error) => {
        if (error.toString().search('401') >= 0) {
          this.$root.logout();
        } else {
          alert(error);
        }
      });
    }

    // this.$vuetify.customVariables = ['~/assets/variables.sass'];
    this.$vuetify.treeShake = true;
    this.$vuetify.theme.options.customProperties = true;

    this.$vuetify.theme.themes.dark.primary = '#015aaa';
    this.$vuetify.theme.themes.dark.secondary = '#748194';
    this.$vuetify.theme.themes.dark.accent = '#2c7be5';
    // this.$vuetify.theme.themes.dark.error = '#e63757';
    // this.$vuetify.theme.themes.dark.warning = '#f5803e';
    // this.$vuetify.theme.themes.dark.info = '#27bcfd';
    // this.$vuetify.theme.themes.dark.success = '#00d27a';
    this.$vuetify.theme.themes.dark.background = '#0b1727';
    this.$vuetify.theme.themes.dark.appBar = '#162E4F';

    this.$vuetify.theme.themes.light.primary = '#015aaa';
    this.$vuetify.theme.themes.light.secondary = '#748194';
    this.$vuetify.theme.themes.light.accent = '#2c7be5';
    // this.$vuetify.theme.themes.light.error = '#e63757';
    // this.$vuetify.theme.themes.light.warning = '#f5803e';
    // this.$vuetify.theme.themes.light.info = '#27bcfd';
    // this.$vuetify.theme.themes.light.success = '#00d27a';
    this.$vuetify.theme.themes.light.background = '#edf2f9';
    this.$vuetify.theme.themes.light.appBar = '#015aaa';
  },
  beforeDestroy () {
    clearInterval(this.polling);
    clearInterval(this.pollingSubscriptionCheck);
  },
  watch: {
    '$route' (to, from) {
      let toDepth = to.path.split('/').length;
      let fromDepth = from.path.split('/').length;
      this.transitionName = toDepth < fromDepth ? 'slider' : 'slidel';
    }
  },
  methods: {
    markReportAsPaid (reportId, userId, fieldName, type, dateTime, note) {
      this.$root.post('/api/reports/setReportsGroupListAsPaid', {
        userId: userId,
        reportId: reportId,
        itemType: type,
        fieldName: fieldName,
        dateTime: dateTime,
        note: note
      }).then(response => {
        if (response.status === 'ok') {
          this.$root.overrideFieldDialog = false;
          this.$root.overrideFieldReportId = 0;
          this.$root.overrideFieldUser = 0;
          this.$root.overrideFieldName = '';
          this.$root.overrideFieldItemType = 0;
          this.$root.overrideFieldItemId = 0;
          this.$root.overrideFieldValue = 0;
          this.$root.overrideFieldDate = '';
          this.$root.overrideFieldNote = '';
          this.$root.refresh();
        }
      });
    },
    isAwayStatusChanged (event) {
      this.$root.setAwayStatus(this.$root.isStatusActive);
    },
    doSearch () {
      this.searchKey = 'searchDlg' + new Date().getTime();
      this.$root.searchDlg(this.searchStr);
    },
    append (emoji) {
      this.input += emoji;
    },
    onLeftDrawerToMini () {
      this.mini = !this.mini;
      this.$root.setLocalStorageItem('isMini', this.mini);
    },
    tabChanged () {
      this.$root.route(this.$root.getPagePath());
    },
    reloadLocation () {
      window.location.reload();
    }
  }
};
</script>
